import React from 'react';

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
}

const ProjectsIcon: React.FC<IconTypes> = ({ height, width, stroke, fill }) => {
  return (
    <svg
      width={width ?? 20}
      height={height ?? 20}
      viewBox="0 0 20 20"
      fill={fill ?? 'none'}
      stroke={stroke ?? 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.472 12.1601C15.2618 13.2714 15.1585 14.8226 14.1621 15.8189L11.3369 18.6441L11.0047 18.312C9.9976 17.3049 9.57934 15.9318 9.74997 14.6208"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.54301 5.21476C6.42813 4.37862 4.83931 4.46757 3.82526 5.48158L1 8.30688L1.33214 8.63902C2.34063 9.64751 3.71603 10.0655 5.02865 9.89309"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2157 6.29956C17.033 9.69511 14.5734 12.5821 11.2448 14.2641L10.7138 14.5324L7.68914 14.9954L4.64941 11.9556L5.11241 8.931L5.20084 8.756C6.95245 5.28962 10.0052 2.77428 13.5681 1.65193C15.2803 1.11256 17.1103 0.894585 18.9705 1.04796L18.9876 1.04938C19.0656 2.86656 18.7925 4.64352 18.2157 6.29956Z"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6221 1.70544L18.1627 6.24605"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.958 9.58225C12.8818 9.58225 13.6308 8.83331 13.6308 7.90944C13.6308 6.98557 12.8818 6.23663 11.958 6.23663C11.0341 6.23663 10.2852 6.98557 10.2852 7.90944C10.2852 8.83331 11.0341 9.58225 11.958 9.58225Z"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6333 14.4522L5.19238 9.01123"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.64134 15.1573L2.83887 16.9599"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.03688 13.5529L1.23438 15.3553"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.24583 16.7619L4.44336 18.5644"
        stroke={stroke ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProjectsIcon;
