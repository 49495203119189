import { URL_ROOT } from '../../../axios/axiosRequest';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const profileApiSlice = createApi({
  reducerPath: 'apiProfile',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: [
    'ProfileDataStats',
    'ProfileStats',
    'ProfileImageStats',
    'ChangePasswordStats',
  ],
  endpoints: (builder) => ({
    getProfileData: builder.query({
      query: (id) => ({
        url: `api/users/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['ProfileStats'],
    }),
    editProfileImage: builder.mutation({
      query: ({ userId, body }: any) => ({
        url: `/api/users/${userId}/profile-image`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['ProfileStats'],
    }),
    deleteProfileImage: builder.mutation({
      query: ({ userId }: any) => ({
        url: `/api/users/${userId}/profile-image`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['ProfileStats'],
    }),
    editProfileData: builder.mutation({
      query: ({ userId, body }: any) => ({
        url: `/api/users/${userId}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['ProfileStats'],
    }),
    changePassword: builder.mutation({
      query: ({ id, body }: any) => ({
        url: `/api/users/${id}/change-password`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['ChangePasswordStats'],
    }),
  }),
});

export const {
  useGetProfileDataQuery,
  useEditProfileImageMutation,
  useEditProfileDataMutation,
  useChangePasswordMutation,
  useDeleteProfileImageMutation,
} = profileApiSlice;
