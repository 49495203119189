import { ComponentStyleConfig } from '@chakra-ui/react';

export const TextStyles: ComponentStyleConfig = {
  variants: {
    link: {
      _hover: { color: '#4B8FD6', transition: 'all 0.25s ease-in-out' },
      transition: 'all 0.25s ease-in-out',
      cursor: 'pointer',
    },
  },
};
