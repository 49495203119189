import React from 'react'

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
};

const WorklogIcon: React.FC<IconTypes> = ({height, width, stroke, fill}) => {
  return (
    <svg
    width={width ?? 20}
    height={height ?? 20}
    viewBox="0 0 20 20"
    fill={fill ?? "none"}
    stroke={stroke ?? 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
  <path
    d="M4.88983 4.85169C4.88983 2.72449 6.61428 1 8.74152 1C10.8688 1 12.5932 2.72449 12.5932 4.85169C12.5932 6.97889 10.8688 8.70338 8.74152 8.70338C6.61428 8.70338 4.88983 6.97889 4.88983 4.85169Z"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M8.47458 16.6737H2.33754C1.48624 16.6737 0.855361 15.8786 1.02884 15.042C1.77504 11.4444 4.95158 8.74151 8.75701 8.74151C9.76432 8.74151 10.7223 8.93634 11.6017 9.27541"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M11.2966 15.1483C11.2966 13.0211 13.0211 11.2966 15.1483 11.2966C17.2756 11.2966 19 13.0211 19 15.1483C19 17.2755 17.2756 19 15.1483 19C13.0211 19 11.2966 17.2755 11.2966 15.1483Z"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M15.1483 13.5847V15.1483H16.2542"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>




  

  )
}

export default WorklogIcon

