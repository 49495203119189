import { createSlice } from '@reduxjs/toolkit';

interface InitialUserType {
  id: null | number;
  role: null | string;
  archived: boolean;
  isRemote?: boolean | null;
  isRemoteTemp?: boolean | null;
}

const initialState: InitialUserType = {
  id: 0,
  role: '',
  archived: false,
  isRemote: false,
  isRemoteTemp: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state: any, action: any) => {
      state.id = action.payload;
    },
    setUserRole: (state: any, action: any) => {
      state.role = action.payload;
    },
    setUserArchived: (state: any) => {
      state.archived = !state.archived;
    },
    setUserRemote: (state: any, action: any) => {
      state.isRemote = action.payload;
    },
    setUserRemoteTemp: (state: any, action: any) => {
      state.isRemoteTemp = action.payload;
    },
  },
});

export const {
  setUserId,
  setUserRole,
  setUserArchived,
  setUserRemote,
  setUserRemoteTemp,
} = userSlice.actions;
export default userSlice.reducer;
