import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { FiCalendar } from 'react-icons/fi';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  id?: string;
  name?: string;
  allowClear?: boolean;
  showIcon?: boolean;
  placeholder?: string;
  customInput?: React.ReactNode;
  value?: Date | null;
  className?: string;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  disabled?: boolean;
  onChange: (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ) => void;
  autocomplete?: string;
};

const DatePicker = ({
  id,
  name,
  allowClear = true,
  showIcon = true,
  placeholder,
  customInput,
  value,
  className = 'datepicker-wrapper',
  dateFormat = 'dd.MM.yyyy',
  showMonthYearPicker = false,
  disabled = false,
  onChange,
  autocomplete = 'off',
}: Props) => {
  const [input, setInput] = React.useState(
    value ? moment(value).format(dateFormat.toUpperCase()) : undefined,
  );

  const onRawChange = (
    event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.SyntheticEvent<any, Event>
      | undefined,
  ) => {
    const { value } = event?.currentTarget;

    if (value) {
      const numericValue = value.replace(/[^0-9]/g, '');

      if (value.length < 11) {
        let formattedValue = '';
        // @ts-ignore
        if (value.length > input?.length) {
          for (let i = 0; i < numericValue.length; i++) {
            formattedValue += numericValue[i];

            if (i === 1 || i === 3) {
              formattedValue += '.';
            }
          }
        } else {
          formattedValue = value;
        }

        setInput(formattedValue);
      } else {
        setInput(value.slice(0, 10));
      }
    } else {
      setInput(value);
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    const { value: targetValue } = event.target;

    if (
      moment(targetValue, dateFormat.toUpperCase(), true).isValid() === false ||
      !value
    ) {
      setInput('');
      onChange(null, event);
    }
  };

  const handleChange = (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ) => {
    const { value } = event?.currentTarget;

    if (!value || (value?.length > 0 && value?.length < 11)) {
      if (
        moment(date, dateFormat.toUpperCase(), true).isValid() ||
        moment(date).format(dateFormat.toUpperCase()).length < 11
      ) {
        onChange(date, event);
        onRawChange(event);
      } else {
        onChange(null, event);
      }
    }
  };

  React.useEffect(() => {
    if (value) setInput(moment(value).format(dateFormat.toUpperCase()));
  }, []);

  return (
    <ErrorBoundary>
      <ReactDatePicker
        autoComplete={autocomplete}
        showYearDropdown
        clearButtonClassName="month-picker-clear-button"
        showMonthYearPicker={showMonthYearPicker}
        showIcon={showIcon}
        isClearable={allowClear}
        placeholderText={placeholder}
        wrapperClassName={className}
        dateFormat={dateFormat}
        id={id}
        name={name}
        selected={value}
        disabled={disabled}
        value={input}
        onBlur={onBlur}
        onChange={handleChange}
        onChangeRaw={onRawChange}
        icon={<FiCalendar />}
        {...(Boolean(customInput) && { customInput })}
      />
    </ErrorBoundary>
  );
};

export { DatePicker };
