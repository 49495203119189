import { useDispatch, useSelector } from 'react-redux';
import {
  setNewPostValues,
  clearAddNewPostForm,
  setSelectedType,
  clearPostImages,
  clearPostImagesUpload,
  clearImageIdToDelete,
  setUpdatedOptionName,
} from '../../../../common/lib/redux/slices/postsSlice';
import {
  useCreatePostImageMutation,
  useCreatePostMutation,
  useDeletePostImageMutation,
  useUpdatePostMutation,
} from '../../../../common/lib/redux/slices/apiSlices/postsApiSlice';
import jwt from 'jwt-decode';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Select,
  Textarea,
  useToast,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { setEditValues } from '../../../../common/lib/redux/slices/postsSlice';
import { capitalizeFirstLetterOnly } from '../../../../helpers/formattingHelperFunctions';
import { useGetPostTypesQuery } from '../../../../common/lib/redux/slices/apiSlices/postTypesApiSlice';
import { Post } from '../types';
import { PostType } from '../types';
import { PagesStyles } from '../../../../common/lib/chakraUI/baseStyles/PagesStyles';
import customNotification from '../../../../components/Notifications/fn/customNotification';
import AddPostImage from '../PostImage/AddPostImage';
import { FormattedMessage, useIntl } from 'react-intl';
import PostImage from '../PostImage/PostImage';
import { generateErrorInfo } from '../helpers/PostsHelpers';
import { RootState } from '../../../../common/lib/redux/store';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { FiCalendar } from 'react-icons/fi';
import { DatePickerInput } from '../../../../components/DatePickerInput';
import { DatePicker } from '../../../../components/DatePicker';

interface PostModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: number | null;
  posts: any;
  type?: string;
  refetchById?: any;
}

const AddNewPostModal = ({
  isOpen,
  onClose,
  id,
  posts,
  type,
}: PostModalProps) => {
  const intl = useIntl();
  const userId = jwt<any>(
    localStorage.getItem('hrc_access_token') as string,
  )?.userId;
  const { addNewPostForm, typePageable, postUploadImages, imageIdToDelete } =
    useSelector((state: RootState) => state.posts);
  const toast = useToast();
  const [deletePostImage, { isLoading: isLoadingDeletePostImage }] =
    useDeletePostImageMutation();
  const { data: postTypes } = useGetPostTypesQuery({
    params: typePageable,
  });

  const [createNewPost, { isLoading: isLoadingCreateNewPost }] =
    useCreatePostMutation();

  const [editPost, { isLoading: isLoadingEditPost }] = useUpdatePostMutation();

  const [createPostImage, { isLoading: isLoadingUploadPostImage }] =
    useCreatePostImageMutation();

  const dispatch = useDispatch();

  const handleInputChangePost = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    dispatch(setNewPostValues({ name, value }));
  };

  const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    const postId = postTypes?.find((type: PostType) => type?.type === value).id;

    dispatch(setSelectedType({ value, postId }));
  };

  const handleUpdateOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    dispatch(setUpdatedOptionName({ value, name }));
  };

  const handleNewPost = () => {
    const findPostId = posts?.content?.find((post: Post) => post.id === id)?.id;

    const errorInfo = generateErrorInfo(addNewPostForm);
    if (errorInfo) {
      const errorMessages = errorInfo.messages.map((messageId) =>
        intl.formatMessage({ id: messageId }),
      );

      const errorMessage = errorMessages.join(', ');

      customNotification(toast, errorInfo.type, errorMessage);

      return;
    }

    const postCreatePayload = {
      title: addNewPostForm.title,
      text: addNewPostForm.text,
      files: postUploadImages.length ? postUploadImages : [],
      userId,
      postType: addNewPostForm.selectType.value,
      startDate:
        addNewPostForm.startDate &&
        moment(addNewPostForm.startDate).format('YYYY-MM-DD'),
      endDate:
        addNewPostForm.startDate &&
        moment(addNewPostForm.endDate).format('YYYY-MM-DD'),
      pollOptions: [
        addNewPostForm.pollOptionOne.name,
        addNewPostForm.pollOptionTwo.name,
      ],
    };
    const postUpdatePayload = {
      title: addNewPostForm.title,
      text: addNewPostForm.text,
      userId,
      postType: addNewPostForm.selectType.value,
      postId: findPostId || posts?.id,
      startDate:
        addNewPostForm.startDate &&
        moment(addNewPostForm.startDate).format('YYYY-MM-DD'),
      endDate:
        addNewPostForm.startDate &&
        moment(addNewPostForm.endDate).format('YYYY-MM-DD'),
      pollOptions: addNewPostForm.pollOptionOne.name
        ? [addNewPostForm.pollOptionOne, addNewPostForm.pollOptionTwo]
        : [{}, {}],
    };

    id
      ? editPost(postUpdatePayload)
          .then((res: any) => {
            if (postUploadImages?.length) {
              createPostImage({ id, body: postUploadImages })
                .then((res: any) => {
                  if (res.error && res?.error?.status >= 400) {
                    const errorMessage = res?.error?.data?.message;
                    throw new Error(errorMessage);
                  }
                })
                .catch((err) => {
                  customNotification(toast, 'error', err.message);
                });
            }
          })
          .then((res) => {
            if (imageIdToDelete?.length) {
              deletePostImage({ id, assets: imageIdToDelete })
                .then(() => {})
                .catch(() => {
                  customNotification(
                    toast,
                    'error',
                    'Something went wrong, please try agian',
                  );
                });
            }
          })
          .then((res: any) => {
            if (res?.error && res?.error?.status >= 400) {
              const errMsg = res?.error?.data?.message;
              return new Error(errMsg);
            }

            customNotification(
              toast,
              'success',
              'You successfully updated post',
            );

            if (type !== 'calendar') {
              dispatch(clearAddNewPostForm());
              dispatch(clearPostImages());
              dispatch(clearPostImagesUpload());
            }
            onClose();
          })
          .catch((err) => {
            customNotification(
              toast,
              'error',
              'Something went wrong, Failed to create post, please try again!',
            );
            dispatch(clearAddNewPostForm());
            dispatch(clearPostImages());
            dispatch(clearPostImagesUpload());
            dispatch(clearImageIdToDelete());
          })
      : createNewPost(postCreatePayload)
          .then((res: any) => {
            if (res?.error && res?.error?.status >= 400) {
              const errMsg = res?.error?.data?.message;
              return new Error(errMsg);
            }
            customNotification(
              toast,
              'success',
              'You successfully created new post',
            );

            dispatch(clearAddNewPostForm());
            dispatch(clearPostImages());
            dispatch(clearPostImagesUpload());
            onClose();
          })
          .catch((err) => {
            customNotification(
              toast,
              'error',
              'Something went wrong, Failed to create post, please try again!',
            );
            dispatch(clearAddNewPostForm());
            dispatch(clearPostImages());
            dispatch(clearPostImagesUpload());
          });
  };

  useEffect(() => {
    if (type !== 'calendar') {
      dispatch(clearAddNewPostForm());
      dispatch(clearPostImages());
      dispatch(clearPostImagesUpload());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  useEffect(() => {
    if (id) {
      const postTypeForEditingDetailPost = posts?.postType;
      dispatch(
        setEditValues({
          title: posts?.title,
          text: posts?.text,
          selectType: {
            value: postTypeForEditingDetailPost?.type,
            id: posts?.postType?.id,
          },
          startDate: posts?.startDate ? new Date(posts?.startDate) : null,
          endDate: posts?.endDate ? new Date(posts?.endDate) : null,

          pollOptionOne:
            type === 'poll'
              ? {
                  optionId: posts?.pollOptions[0]?.id,
                  name: posts?.pollOptions[0]?.name,
                }
              : ('' as any),

          pollOptionTwo:
            type === 'poll'
              ? {
                  optionId: posts?.pollOptions[1]?.id,
                  name: posts?.pollOptions[1]?.name,
                }
              : ('' as any),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isLoading =
    isLoadingCreateNewPost ||
    isLoadingDeletePostImage ||
    isLoadingEditPost ||
    isLoadingUploadPostImage;

  const {
    formRow,
    addButton,
    cancelButton,
    formHeader,
    formLabelBox,
    formLabel,
    formInputBox,
    formInput,
    formSelect,
  } = PagesStyles;
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader {...formHeader}>
          {id
            ? intl.formatMessage({ id: 'POSTS.UPDATE_POST' })
            : intl.formatMessage({ id: 'POSTS.ADD_POST' })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="scroll" maxH="80vh">
          <FormControl>
            <Box {...formRow}>
              <Box {...formLabelBox}>
                <FormLabel htmlFor="title" {...formLabel}>
                  <FormattedMessage id={'TITLE'} />:
                  <span style={{ color: 'red' }}>*</span>
                </FormLabel>
              </Box>
              <Box {...formInputBox}>
                <Input
                  placeholder={intl.formatMessage({ id: 'ADD_TITLE' })}
                  type="text"
                  id="title"
                  name="title"
                  value={addNewPostForm.title}
                  maxLength={40}
                  onChange={handleInputChangePost}
                  {...formInput}
                />
              </Box>
            </Box>
            <Box {...formRow} h="90px !imporant">
              <Box {...formLabelBox}>
                <FormLabel htmlFor="text" {...formLabel}>
                  <FormattedMessage id={'TEXT'} />:
                  <span style={{ color: 'red' }}>*</span>
                </FormLabel>
              </Box>
              <Box {...formInputBox} p="20px !important">
                <Textarea
                  placeholder={intl.formatMessage({ id: 'ADD_TEXT' })}
                  id="text"
                  name="text"
                  value={addNewPostForm.text}
                  onChange={handleInputChangePost}
                  resize="vertical"
                  minH="150px"
                  maxH="300px"
                  overflow="auto"
                  {...formInput}
                />
              </Box>
            </Box>
            {!type && (
              <Box {...formRow}>
                <Box {...formLabelBox}>
                  <FormLabel htmlFor="typeSelect" {...formLabel}>
                    <FormattedMessage id={'POST_TYPE'} />:
                    <span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                </Box>
                <Box {...formInputBox}>
                  <Select
                    id="typeSelect"
                    onChange={handleChangeType}
                    value={addNewPostForm.selectType.value}
                    {...formSelect}
                  >
                    <option selected hidden disabled value="">
                      {intl.formatMessage({ id: 'SELECT_POST' })}
                    </option>
                    {postTypes?.map((type: PostType) => {
                      return (
                        <option key={type?.id} value={type?.type}>
                          {capitalizeFirstLetterOnly(type?.type || '')}
                        </option>
                      );
                    })}
                  </Select>
                </Box>
              </Box>
            )}
            {(addNewPostForm?.selectType?.value?.toLowerCase() === 'event' ||
              addNewPostForm?.selectType?.value?.toLowerCase() === 'poll') && (
              <>
                <Box {...formRow}>
                  <Box {...formLabelBox}>
                    <FormLabel htmlFor="startDate" {...formLabel}>
                      <FormattedMessage id={'DATE_START'} />:
                      <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                  </Box>
                  <Box {...formInputBox}>
                    <DatePicker
                      showIcon
                      allowClear
                      placeholder={intl.formatMessage({
                        id: 'DAYS_OFF.VALIDATION.STARTDATE',
                      })}
                      id="startDate"
                      name="startDate"
                      value={addNewPostForm.startDate || undefined}
                      onChange={(date) =>
                        dispatch(
                          setNewPostValues({ name: 'startDate', value: date }),
                        )
                      }
                    />
                  </Box>
                </Box>
                {addNewPostForm.startDate && (
                  <Box {...formRow}>
                    <Box {...formLabelBox}>
                      <FormLabel htmlFor="endDate" {...formLabel}>
                        <FormattedMessage id={'DATE_END'} />:
                        {addNewPostForm?.selectType?.value?.toLowerCase() ===
                          'poll' && <span style={{ color: 'red' }}>*</span>}
                      </FormLabel>
                    </Box>
                    <Box {...formInputBox}>
                      <DatePicker
                        showIcon
                        allowClear
                        placeholder={intl.formatMessage({
                          id: 'DAYS_OFF.VALIDATION.ENDDATE',
                        })}
                        id="endDate"
                        name="endDate"
                        value={addNewPostForm.endDate || undefined}
                        onChange={(date) =>
                          dispatch(
                            setNewPostValues({ name: 'endDate', value: date }),
                          )
                        }
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
            {addNewPostForm?.selectType?.value?.toLowerCase() === 'poll' && (
              <>
                <Box {...formRow}>
                  <Box {...formLabelBox}>
                    <FormLabel htmlFor="option_one" {...formLabel}>
                      <FormattedMessage id={'OPTION_ONE'} />:
                      <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                  </Box>
                  <Box {...formInputBox}>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'ADD.FIRST_OPTION',
                      })}
                      type="text"
                      id="option_one"
                      name="pollOptionOne"
                      value={addNewPostForm.pollOptionOne.name}
                      maxLength={40}
                      onChange={handleUpdateOptions}
                      {...formInput}
                    />
                  </Box>
                </Box>
                <Box {...formRow}>
                  <Box {...formLabelBox}>
                    <FormLabel htmlFor="option_two" {...formLabel}>
                      <FormattedMessage id={'OPTION_TWO'} />:
                      <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                  </Box>
                  <Box {...formInputBox}>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'ADD.SECOND_OPTION',
                      })}
                      type="text"
                      id="option_two"
                      name="pollOptionTwo"
                      value={addNewPostForm.pollOptionTwo.name}
                      maxLength={40}
                      onChange={handleUpdateOptions}
                      {...formInput}
                    />
                  </Box>
                </Box>
              </>
            )}
            <HStack justifyContent="center" alignItems="center" mt={5}>
              {id && (
                <Box>
                  <AddPostImage id={id} />
                </Box>
              )}
            </HStack>
            <Box display="flex" justifyContent="center" alignItems="center">
              <PostImage />
            </Box>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button {...cancelButton} mr={3} onClick={onClose}>
            <FormattedMessage id={'CLOSE'} />
          </Button>
          <Button
            {...addButton}
            _hover={{ bg: 'var(--chakra-colors-blue-400)' }}
            type="submit"
            onClick={handleNewPost}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            ) : (
              <FormattedMessage id={'SAVE'} />
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewPostModal;
