import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { FaHospitalAlt, FaHome } from 'react-icons/fa';

interface ICustomSwitch {
  onChange?: () => void | any | undefined;
  isRemote?: boolean;
  type?: string;
}

export const CustomSwitch = ({ onChange, isRemote, type }: ICustomSwitch) => {
  const onSwitchClick = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <FormControl
      alignItems="center"
      height="30px"
      width="100px"
      mt={type === 'navbar' ? '20px' : '10px'}
    >
      <Flex align="center" gap="2">
        <Tooltip label="Office" hasArrow bg="#fff" color="black">
          <FormLabel htmlFor="switch-layout" m="0 !important">
            <Icon
              as={FaHospitalAlt}
              fontWeight="150"
              fontSize="16px"
              color="#009DE2"
            />
          </FormLabel>
        </Tooltip>
        <Box
          position="relative"
          width="34px"
          mb={type === 'navbar' ? '3px' : '5px'}
          height="20px"
          backgroundColor={isRemote ? '#FFA464' : '#10A2E3'}
          transition="background-color 0.3s ease"
          cursor="pointer"
          onClick={onSwitchClick}
          borderRadius="100px"
        >
          <Box
            mb="1px"
            position="absolute"
            top="3px"
            left="0.5"
            width="15px"
            height="15px"
            borderRadius="100px"
            backgroundColor="white"
            transition="transform 0.3s ease-in-out"
            transform={`translateX(${isRemote ? 15 : 0}px)`}
          ></Box>
        </Box>
        <Tooltip label="Remote" hasArrow bg="#fff" color="black">
          <FormLabel htmlFor="switch-layout" m="0 !important">
            <Icon as={FaHome} fontSize="16px" color="#FFA464" />
          </FormLabel>
        </Tooltip>
      </Flex>
    </FormControl>
  );
};
