import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  ModalCloseButton,
  Spinner,
  ToastId,
} from '@chakra-ui/react';
import { PagesStyles } from '../../common/lib/chakraUI/baseStyles/PagesStyles';
import { FormattedMessage, useIntl } from 'react-intl';

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  // onSubmit: (...args: any[]) => Promise<ToastId>;
  onSubmit: () => void;
  buttonText: string;
  headerText: string;
  buttonStyle: 'delete' | 'submit';
  isLoading?: boolean;
};

const ConfirmationModal = ({
  isOpen,
  onClose,
  onSubmit,
  buttonText,
  headerText,
  buttonStyle,
  isLoading,
}: ConfirmationModalProps) => {
  const { modalHeader, cancelButton, deleteButton, addButton } = PagesStyles;

  const defaultButtonText = 'DEFAULT_BUTTON_TEXT';

  const intl = useIntl();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay
        css={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />
      <ModalContent>
        <ModalHeader {...modalHeader} p={'24px'}>
          <FormattedMessage id={headerText} />
        </ModalHeader>
        <ModalCloseButton />

        <ModalFooter>
          <Button {...cancelButton} mr={3} onClick={onClose}>
            <FormattedMessage id={'CLOSE'} />
          </Button>
          <Button
            {...(buttonStyle === 'delete' ? deleteButton : addButton)}
            onClick={async () => {
              if (!isLoading) {
                await onSubmit();
                onClose();
              }
            }}
            _hover={{ bg: 'var(--chakra-colors-red-400)' }}
            isDisabled={isLoading}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              intl.formatMessage({ id: buttonText || defaultButtonText })
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
