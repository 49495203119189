import { URL_ROOT } from '../../../axios/axiosRequest';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dashboardApiSlice = createApi({
  reducerPath: 'apiDashboard',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['DashboardStats'],
  endpoints: (builder) => ({
    getDashboardStats: builder.query({
      query: (params) => ({
        url: '/api/posts',
        method: 'POST',
        params: params,
      }),
      providesTags: ['DashboardStats'],
    }),
    getAllBasicStatistics: builder.query({
      query: () => ({
        url: '/api/basic-statistics',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetDashboardStatsQuery, useGetAllBasicStatisticsQuery } =
  dashboardApiSlice;
