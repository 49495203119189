import * as React from 'react';
import {
  Box,
  Text,
  Avatar,
  VStack,
  Flex,
  useToast,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import CommentInput from '../Comments/CommentInput';
import CommentList from '../Comments/CommentList';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';

import {
  useAddPostCommentMutation,
  useDeletePostCommentMutation,
  useEditPostCommentMutation,
  useGetPostCommentQuery,
} from '../../../../common/lib/redux/slices/apiSlices/postsApiSlice';

import { RootState } from '../../../../common/lib/redux/store';
import customNotification from '../../../../components/Notifications/fn/customNotification';
import ErrorPage from '../../../../components/Layout/components/ErrorPage/ErrorPage';
import { PagesStyles } from '../../../../common/lib/chakraUI/baseStyles/PagesStyles';

import { useIntl } from 'react-intl';
import { setCommentDeleteId } from '../../../../common/lib/redux/slices/postsSlice';
import { ErrorBoundary } from '../../../../components/ErrorBoundary/ErrorBoundary';
import TextWithLinksAndLineBreaks from '../../../../components/TextWithLinksAndLineBreaks';

type Props = React.HTMLProps<HTMLDivElement> & {
  postId: number;
  isLoading?: boolean;
  text?: string;
};

const CommentSection: React.FC<Props> = ({
  postId,
  isLoading = false,
  text,
}) => {
  const toast = useToast();
  const intl = useIntl();
  const {
    isOpen: isOpenDeleteConfirmationModal,
    onOpen: onOpenDeleteConfirmationModal,
    onClose: onCloseDeleteConfirmationModal,
  } = useDisclosure();

  const { id: loggedInUserId } = useSelector((store: RootState) => store.user);
  const { profileImage } = useSelector((state: RootState) => state.profile);
  const { commentDeleteId } = useSelector((state: RootState) => state.posts);
  const {
    data: commentsByPost,
    isLoading: isLoadingComments,
    isError: isErrorComments,
  } = useGetPostCommentQuery({ id: postId });

  const [addPostComment] = useAddPostCommentMutation();
  const [editPostComment] = useEditPostCommentMutation();
  const [deletePostComment, { isLoading: isLoadingDeleteCommentMutation }] =
    useDeletePostCommentMutation();
  const handleCommentSubmit = async (newComment: string) => {
    if (newComment.trim() === '') {
      return;
    }

    try {
      await addPostComment({
        body: {
          postId,
          text: newComment,
          userId: loggedInUserId,
        },
      });
      customNotification(
        toast,
        'success',
        intl.formatMessage({ id: 'COMMENTS.POST_SUCCESS' }),
      );
    } catch (error) {
      customNotification(
        toast,
        'error',
        intl.formatMessage({ id: 'COMMENTS.EDIT_ERROR' }),
      );
    }
  };

  const handleDeleteComment = async () => {
    try {
      const res: any = await deletePostComment({
        id: commentDeleteId,
      });

      if (res.error?.status >= 400) {
        return customNotification(
          toast,
          'error',
          intl.formatMessage({ id: 'COMMENTS.DELETE_ERROR' }),
        );
      }

      return customNotification(
        toast,
        'success',
        intl.formatMessage({ id: 'COMMENTS.DELETE_SUCCESS' }),
      );
    } catch (error) {
      return customNotification(
        toast,
        'error',
        intl.formatMessage({ id: 'COMMENTS.DELETE_ERROR' }),
      );
    } finally {
      setCommentDeleteId(null);
    }
  };

  const handleSaveComment = async (index: number, editedText: string) => {
    if (editedText.trim() === '') {
      return;
    }

    try {
      await editPostComment({
        body: {
          id: commentsByPost[index].id,
          text: editedText,
          userId: loggedInUserId,
        },
      });
      customNotification(
        toast,
        'success',
        intl.formatMessage({ id: 'COMMENTS.EDIT_SUCCESS' }),
      );
    } catch (error) {
      customNotification(
        toast,
        'error',
        intl.formatMessage({ id: 'COMMENTS.EDIT_ERROR' }),
      );
    }
  };

  const { errorPage } = PagesStyles;

  const isLoadingPost = isLoadingComments || isLoading;

  if (isErrorComments) {
    return (
      <Box {...errorPage}>
        <ErrorPage />
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <VStack w="full" h="calc(100% - 78px)" align="stretch" overflowY="auto">
        <VStack alignItems="inherit" h="auto">
          {!isLoading && text ? (
            <TextWithLinksAndLineBreaks mb="6" content={text} maxLength={175} />
          ) : (
            ''
          )}
          <Text
            w="full"
            borderBottom="1px solid lightgray"
            fontSize="20px"
            fontWeight="bold"
          >
            {intl.formatMessage({ id: 'COMMENTS' })}
          </Text>

          {isLoadingPost ? (
            <VStack marginTop="100px" justifyContent="center">
              <Spinner color="blue.500" size="xl" />
            </VStack>
          ) : (
            <CommentList
              comments={commentsByPost}
              onEditComment={handleSaveComment}
              onDeleteComment={onOpenDeleteConfirmationModal}
              intl={intl}
            />
          )}
        </VStack>
        <Flex
          zIndex="10"
          bgColor="white"
          h="100px"
          alignItems={'center'}
          borderTop="1px solid lightgray"
          pt="4"
        >
          <Avatar
            name={profileImage.name}
            src={`data:${profileImage.contentType};base64,${profileImage.encodedContent}`}
            size="md"
            mr="2"
          />
          <VStack justifyContent="center" align="stretch" flex="1">
            <CommentInput onSubmit={handleCommentSubmit} intl={intl} />
          </VStack>
        </Flex>
      </VStack>
      <ConfirmationModal
        onSubmit={handleDeleteComment}
        isOpen={isOpenDeleteConfirmationModal}
        onClose={() => {
          setCommentDeleteId(null);
          onCloseDeleteConfirmationModal();
        }}
        headerText={'MODAL.DELETE_ARE_YOU_SURE'}
        buttonText={'DELETE'}
        buttonStyle={'delete'}
        isLoading={isLoadingDeleteCommentMutation}
      />
    </ErrorBoundary>
  );
};

export default CommentSection;
