import { URL_ROOT } from '../../../axios/axiosRequest';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const mapContentTypeToExtension = (contentType: string): string | null => {
  switch (contentType) {
    case 'application/pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'text/csv':
      return 'csv';
    case 'application/vnd.ms-excel':
      return 'xls';
    default:
      return null; // Unsupported content type
  }
};

export const assetsApiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['GetAsset', 'DeleteAsset'],
  endpoints: (builder) => ({
    getAssetById: builder.query({
      queryFn: async ({ id, name }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/api/assets/${id}`,
          responseHandler: (response) => response.blob(),
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
          },
        });
        // @ts-ignore
        const contentType = result.data.type;

        const extension = mapContentTypeToExtension(contentType);

        if (extension === null) {
          return {
            error: {
              error: 'FILE.UNSUPPORTED_TYPE',
              originalStatus: 400,
              status: 400,
              data: 'FILE.UNSUPPORTED_TYPE',
            },
          };
        }

        const hiddenElement = document.createElement('a');
        const url = window.URL || window.webkitURL;
        const blobFile = url.createObjectURL(result.data as Blob);
        hiddenElement.href = blobFile;
        hiddenElement.target = '_blank';
        hiddenElement.download = `${name}.${extension}`;
        hiddenElement.click();
        return { data: null };
      },
    }),
  }),
  keepUnusedDataFor: 0,
});

export const { useLazyGetAssetByIdQuery } = assetsApiSlice;
