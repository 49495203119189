import { Box, Image } from '@chakra-ui/react';
import CakeIcon from '../../../../assets/icons/CakeIcon';
import EmployeeIcon from '../../../../assets/icons/EmployeeIcon';
import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import ClientsIcon from '../../../../assets/icons/ClientsIcon';
import SickDayIcon from '../../../../assets/icons/SickDayIcon';
import LeaveIcon from '../../../../assets/icons/LeaveIcon';
import PaidLeaveDayIcon from '../../../../assets/icons/PaidLeaveDayIcon';
import PostsIcon from '../../../../assets/icons/PostsIcon';

type Props = {
  data?: any;
  boxWidth?: any;
  boxHeight?: any;
  width?: string;
  height?: string;
  bg?: string;
  stroke?: string;
  postsData?: any;
  page?: string;
};

const ImageTypes = ({
  data,
  boxWidth,
  boxHeight,
  width,
  height,
  bg,
  stroke,
  postsData,
  page,
}: Props) => {
  const firstPostImage = postsData?.assets[0];
  let imageSrc;
  let iconComponent;

  switch (
    page === 'dashboard'
      ? data?.postType?.type
      : page === 'details'
      ? data?.type
      : postsData?.postType?.type
  ) {
    case 'BIRTHDAY':
      iconComponent = firstPostImage ? (
        <Image
          w={width || '6rem'}
          h={height || '6rem'}
          borderRadius="15px"
          src={`data:${firstPostImage?.contentType};base64,${firstPostImage?.content}`}
          objectFit="cover"
        />
      ) : (
        <CakeIcon
          width={width || '8rem'}
          height={height || '8rem'}
          stroke={stroke || 'var(--chakra-colors-blue-500)'}
        />
      );
      break;
    case 'NEW POSITION':
      iconComponent = firstPostImage ? (
        <Image
          w={width || '6rem'}
          h={height || '6rem'}
          borderRadius="15px"
          src={`data:${firstPostImage?.contentType};base64,${firstPostImage?.content}`}
          objectFit="cover"
        />
      ) : (
        <EmployeeIcon
          width={width || '8rem'}
          height={height || '8rem'}
          stroke={stroke || 'var(--chakra-colors-blue-500)'}
        />
      );
      break;
    case 'EVENT':
      iconComponent = firstPostImage ? (
        <Image
          w={width || '6rem'}
          h={height || '6rem'}
          borderRadius="15px"
          src={`data:${firstPostImage?.contentType};base64,${firstPostImage?.content}`}
          objectFit="cover"
        />
      ) : (
        <CalendarIcon
          width={width || '8rem'}
          height={height || '8rem'}
          stroke={stroke || 'var(--chakra-colors-blue-500)'}
        />
      );
      break;
    case 'NEW EMPLOYEE':
      iconComponent = firstPostImage ? (
        <Image
          w={width || '6rem'}
          h={height || '6rem'}
          borderRadius="15px"
          src={`data:${firstPostImage?.contentType};base64,${firstPostImage?.content}`}
          objectFit="cover"
        />
      ) : (
        <ClientsIcon
          width={width || '8rem'}
          height={height || '8rem'}
          stroke={stroke || 'var(--chakra-colors-blue-500)'}
        />
      );
      break;
    case 'SICK_DAY':
      iconComponent = (
        <SickDayIcon
          width={width || '8rem'}
          height={height || '8rem'}
          stroke={stroke || 'var(--chakra-colors-blue-500)'}
        />
      );
      break;
    case 'PAID_LEAVE_DAYS':
      iconComponent = (
        <PaidLeaveDayIcon
          width={width || '8rem'}
          height={height || '8rem'}
          stroke={stroke || 'var(--chakra-colors-blue-500)'}
        />
      );
      break;
    case 'LEAVE_DAY':
      iconComponent = (
        <LeaveIcon
          width={width || '8rem'}
          height={height || '8rem'}
          stroke={stroke || 'var(--chakra-colors-blue-500)'}
        />
      );
      break;
    default:
      iconComponent = firstPostImage ? (
        <Image
          w={width || '6rem'}
          h={height || '6rem'}
          borderRadius="15px"
          src={`data:${firstPostImage?.contentType};base64,${firstPostImage?.content}`}
          objectFit="cover"
        />
      ) : (
        <PostsIcon
          width={width || '8rem'}
          height={height || '8rem'}
          stroke={stroke || 'var(--chakra-colors-blue-500)'}
        />
      );
  }

  imageSrc = (
    <Box
      fontSize={'48px'}
      borderRadius={'15px'}
      bg={bg || 'white'}
      w={boxWidth || '10rem'}
      h={boxHeight || '10rem'}
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      {iconComponent}
    </Box>
  );

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" objectFit="none" borderTopRadius="15px">
        {imageSrc}
      </Box>
    </Box>
  );
};

export default ImageTypes;
