type Project = {
  client: {
    id: number;
    name: string;
    content: string;
    contentType: string;
  };
  content: string;
  contentType: string;
  description: string;
  id: number;
  name: string;
  numberOfEmplyeesByProject: number;
};

export const allProjectsMapper = (data: Project) => {
  const client = data?.client?.name;
  return { ...data, client };
};
