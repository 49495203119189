import { Flex, FlexProps, Icon, Box } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
  color?: string;
}

interface IProps {
  icon: React.ReactElement<IconTypes>;
  children: any;
  path: string;
}

const styleProps = {
  flex: {
    align: 'center',
    fontSize: { base: '12px', md: '14px', '2xl': '14px' },
    p: { base: '2.5', md: '2', xl: '1', '2xl': '2.5' },
    m: { base: '3', md: '2', xl: '1', '2xl': '3' },
    height: { base: '3rem', md: '2.75rem', xl: '2.75rem', '2xl': '3rem' },
    mx: '2rem',
    borderRadius: '15px',
    role: 'group',
    cursor: 'pointer',
    color: 'var(--chakra-colors-grey-600)',
    transition: 'all 0.1s ease',
    fontWeight: '400',
    _hover: {
      bg: 'var(--chakra-colors-grey-200)',
      transition: 'all 0.1s ease',
      //fontWeight: 'bold',
      color: 'var(--chakra-color-grey-900)',
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.15)',
    },
    _active: {
      bg: 'var(--chakra-colors-grey-200)',
      transition: 'all 0.1s ease',
      //fontWeight: 'bold',
      color: 'var(--chakra-color-grey-900)',
    },
  },
  icon: {
    ml: '2',
    mr: '3',
    height: '32px',
    width: '32px',
    // fontSize: '16',
    //_groupHover: { bg: 'var(--chakra-colors-blue-500)' },
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.15)',
  },
  iconBox: {
    width: '18px',
    height: '18px',
    borderRadius: '12px',
  },
};

const NavItem = ({ icon, path, children, ...rest }: IProps) => {
  const location = useLocation();
  return (
    <Link
      to={path}
      style={{
        textDecoration: 'none',
        pointerEvents: `${location.pathname === path ? 'none' : 'auto'}`,
      }}
    >
      <Flex
        {...styleProps.flex}
        bg={
          location.pathname === path ? 'var(--chakra-colors-grey-100)' : 'none'
        }
        transition={location.pathname === path ? 'all 0.1s ease' : 'none'}
        fontWeight={location.pathname === path ? 'bold' : 'none'}
        color={
          location.pathname === path
            ? 'var(--chakra-color-grey-900)'
            : 'var(--chakra-colors-grey-600)'
        }
        boxShadow={
          location.pathname === path
            ? '0px 1px 3px 0px rgba(0, 0, 0, 0.15)'
            : 'none'
        }
        {...rest}
      >
        {/* <Box {...styleProps.iconBox}> */}
        <Flex
          {...styleProps.icon}
          bg={
            location.pathname === path
              ? 'var(--chakra-colors-blue-500)'
              : 'var(--chakra-colors-grey-200)'
          }
        >
          {React.cloneElement(icon, {
            stroke:
              icon.props.color ||
              (location.pathname === path
                ? 'white'
                : 'var(--chakra-colors-blue-500)'),
          })}
        </Flex>
        {/* </Box> */}
        {children}
      </Flex>
    </Link>
  );
};

export { NavItem };
