import React from 'react';

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
}

const EmployeeIcon: React.FC<IconTypes> = ({ height, width, stroke, fill }) => {
  return (
    <svg
      width={width ?? 20}
      height={height ?? 20}
      viewBox="0 0 20 20"
      fill={fill ?? 'none'}
      stroke={stroke ?? 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.69678 9.12288C5.81382 4.40737 7.59807 0.999993 10 0.999993C12.4083 0.999993 14.2316 4.42575 14.3421 9.16101"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.861 4.38558C15.2102 5.04407 12.749 5.46188 10 5.46188C7.20314 5.46188 4.70427 5.02939 3.05353 4.35096"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 1.00001V7.48306"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9407 10H19"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 10H2.86864"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.06769 11.1093C1.023 10.7458 1 10.3756 1 10C1 5.02942 5.02944 1.00002 10 1.00002"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9323 11.1093C18.977 10.7458 19 10.3756 19 10C19 5.02942 14.9706 1.00002 10 1.00002"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.2966 14.3856C2.2966 13.4799 3.03079 12.7458 3.93643 12.7458C4.84208 12.7458 5.57626 13.4799 5.57626 14.3856C5.57626 15.2912 4.84208 16.0254 3.93643 16.0254C3.03079 16.0254 2.2966 15.2912 2.2966 14.3856Z"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98242 16.8263C5.45203 16.3295 4.73962 16.0254 3.95639 16.0254H3.91647C2.4857 16.0254 1.29136 17.0401 1.01084 18.3906C0.945627 18.7047 1.18283 19 1.50286 19H6.37"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4237 14.3856C14.4237 13.4799 15.1579 12.7458 16.0636 12.7458C16.9692 12.7458 17.7034 13.4799 17.7034 14.3856C17.7034 15.2912 16.9692 16.0254 16.0636 16.0254C15.1579 16.0254 14.4237 15.2912 14.4237 14.3856Z"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.63 19H18.4971C18.8172 19 19.0544 18.7047 18.9892 18.3906C18.7086 17.0401 17.5143 16.0254 16.0835 16.0254H16.0436C15.2725 16.0254 14.5701 16.3201 14.0424 16.8033"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.90253 12.4788C7.90253 11.3204 8.84158 10.3813 9.99998 10.3813C11.1584 10.3813 12.0974 11.3204 12.0974 12.4788C12.0974 13.6372 11.1584 14.5763 9.99998 14.5763C8.84158 14.5763 7.90253 13.6372 7.90253 12.4788Z"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.5763C12.1264 14.5763 13.9013 16.0853 14.3182 18.0937V18.084C14.4152 18.551 14.0386 19 13.563 19H6.41298C5.93731 19 5.58483 18.5608 5.68177 18.0937C6.09867 16.0853 7.87361 14.5763 10 14.5763Z"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmployeeIcon;
