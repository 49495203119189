import { ILoggedUser } from "./../data/layoutInterfaces";

export const loggedUserMapper = ({
  id,
  firstName,
  lastName,
  email,
  phoneNumber,
  role,
  createdAt,
  lastUpdatedAt,
  exp,
  iat,
}: ILoggedUser) => {
  id = typeof id === "string" ? parseInt(id) : id;
  //Mockery
  role = role ?? "admin";
  firstName = firstName ?? "Stefan";
  lastName = lastName ?? "Matić";

  role = role.charAt(0).toUpperCase() + role.slice(1);
  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    role,
    createdAt,
    lastUpdatedAt,
  };
};
