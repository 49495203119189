import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_ROOT } from '../../../axios/axiosRequest';

export const obligationsApiSlice = createApi({
  reducerPath: 'obligationsSlice',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['AddObligation', 'UpdateObligation', 'DeleteObligation'],
  endpoints: (builder) => ({
    postObligation: builder.mutation({
      query: (body) => ({
        url: `/api/obligations`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AddObligation'],
    }),
    updateObligation: builder.mutation({
      query: ({ body }) => ({
        url: `api/obligations`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
    }),
    deleteObligation: builder.mutation({
      query: (id) => ({
        url: `api/obligations/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
    }),
  }),
});

export const {
  usePostObligationMutation,
  useUpdateObligationMutation,
  useDeleteObligationMutation,
} = obligationsApiSlice;
