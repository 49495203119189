import React from 'react'

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
};

const PostsIcon: React.FC<IconTypes> = ({height, width, stroke, fill}) => {
  return (
    <svg
    width={width ?? 20}
    height={height ?? 20}
    viewBox="0 0 20 20"
    fill={fill ?? "none"}
    stroke={stroke ?? 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >

  <path
    d="M1.1416 6.35652H18.8584"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M7.05384 3.63589H3.8699"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M19 12.3129V3.5212C19 2.12878 17.9756 1 16.7119 1H3.28814C2.02444 1 1 2.12878 1 3.5212V16.4788C1 17.8712 2.02444 19 3.28814 19H16.7119C17.9646 19 18.9822 17.8907 18.9998 16.5149"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M8.29361 14.9431H5.39207C4.86911 14.9431 4.44516 14.5191 4.44516 13.9962V11.45C4.44516 10.9271 4.86911 10.5031 5.39207 10.5031H8.29361C8.81657 10.5031 9.24052 10.9271 9.24052 11.45V13.9962C9.24052 14.5191 8.81657 14.9431 8.29361 14.9431Z"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.022 14.9431H15.5548"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.022 11.9052H15.5548"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>


  

  )
}

export default PostsIcon

