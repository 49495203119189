import { convertDate } from './daysOffApiSlice';
import { IHolidayContent } from './holidaysApiInterfaces';

export const allHolidaysMapper = (
  holiday: IHolidayContent,
): IHolidayContent => {
  // const formattedDate = convertDate(holiday?.date);

  return {
    id: holiday?.id,
    name: holiday?.name,
    date: holiday?.date,
    workingDay: holiday?.workingDay,
  };
};

export const holidaySortMapper = (
  holidays: IHolidayContent[],
): IHolidayContent[] => {
  const content = holidays?.map((holiday) => allHolidaysMapper(holiday));

  const sortedContent = content?.sort((a, b) => {
    const dateA = new Date(a?.date);
    const dateB = new Date(b?.date);
    return dateA.getTime() - dateB.getTime();
  });

  return sortedContent;
};

const month: Record<number, string> = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
