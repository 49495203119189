import {
  AlertStatus,
  Box,
  CreateToastFnReturn,
  HStack,
  Text,
  ToastId,
  ToastPosition,
} from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import WarningIcon from '../../../assets/icons/WarningIcons';
import InfoIcon from '../../../assets/icons/InfoIcon';
import SuccessIcon from '../../../assets/icons/SuccessIcon';
import { PagesStyles } from '../../../common/lib/chakraUI/baseStyles/PagesStyles';
import { capitalizeFirstLetterOfWords } from '../../../helpers/formattingHelperFunctions';

const customNotification = (
  toast: CreateToastFnReturn,
  status: AlertStatus,
  description: string,
  position: ToastPosition | undefined = 'top-right',
): ToastId => {
  let bgColor = `var(--chakra-colors-notification-${
    status === 'error' ? 'danger' : status
  })`;

  const mapStatusToIcon = () => {
    switch (status) {
      case 'error':
        return <WarningIcon />;
      case 'info':
        return <InfoIcon />;
      case 'success':
        return <SuccessIcon />;
      case 'warning':
        return <WarningIcon />;
      default:
        return <InfoIcon />;
    }
  };

  const { tostifyText, tostifyHeader } = PagesStyles;

  return toast({
    position,
    status,
    render: ({ onClose }) => {
      return (
        <HStack
          w="400px"
          bg={bgColor}
          borderRadius="15px"
          color="#fff"
          justify="space-between"
          position="relative"
        >
          <Box p="22px 14px 22px 18px">{mapStatusToIcon()}</Box>
          <Box flexGrow={2}>
            <Text {...tostifyHeader}>
              {capitalizeFirstLetterOfWords(status)}
            </Text>
            <Text {...tostifyText}>{description}</Text>
          </Box>
          <Box
            position="absolute"
            top={2}
            right={3.5}
            onClick={() => onClose()}
            cursor="pointer"
            _hover={{ bg: 'rgba(0, 0, 0, 0.08)', borderRadius: '4px' }}
          >
            <MdClose fontSize="20px" />
          </Box>
        </HStack>
      );
    },
    duration: 2500,
  });
};

export default customNotification;
