import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_ROOT } from '../../../axios/axiosRequest';
import { IApiResponse } from '../../../../../types/universalTypes';
import { IWorklogReportApiContent } from '../../../../../types/worklogsTypes';

export const worklogApiSlice = createApi({
  reducerPath: 'worklogApi',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['worklogs', 'reports'],
  endpoints: (builder) => ({
    getAllWorklogsUser: builder.query({
      query: ({ params }) => ({
        url: '/api/worklogs/user',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['worklogs'],
    }),
    getAllWorklogs: builder.query({
      query: ({ params, body }) => ({
        url: '/api/all-worklogs',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
      providesTags: ['worklogs'],
    }),
    getAllWorklogsByMonth: builder.query({
      query: (params) => ({
        url: '/api/worklogs/worklogUsers',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
    }),
    createWorklog: builder.mutation({
      query: (body) => ({
        url: '/api/worklogs',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['worklogs'],
    }),
    cloneWorklog: builder.mutation({
      query: (body) => ({
        url: '/api/worklogs/clone',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['worklogs'],
    }),
    updateWorklog: builder.mutation({
      query: ({ data, id }) => ({
        url: `/api/worklogs/${id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body: data,
      }),
      invalidatesTags: ['worklogs'],
    }),
    deleteWorklog: builder.mutation({
      query: (id) => ({
        url: `/api/worklogs/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['worklogs'],
    }),
    getAllReports: builder.query({
      query: ({ params, body }) => ({
        url: '/api/report',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
      providesTags: ['reports'],
      transformResponse: (response: IApiResponse<IWorklogReportApiContent[]>) =>
        response,
    }),
    uploadReport: builder.mutation({
      query: (body) => ({
        url: '/api/report',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['reports'],
    }),
    deleteReport: builder.mutation({
      query: (id) => ({
        url: `/api/report/asset/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['reports'],
    }),
    getReportsByUserId: builder.query({
      query: ({ params, body, userId }) => ({
        url: '/api/report',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params: { ...params, 'user-id': userId },
        body,
      }),
      providesTags: ['reports'],
      transformResponse: (response: IApiResponse<IWorklogReportApiContent[]>) =>
        response,
    }),
    getWorkingDays: builder.query({
      query: (params) => ({
        url: '/api/worklogs/workingDays',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
    }),
    getWorkingDaysManualTable: builder.query({
      query: (params) => ({
        url: '/api/worklogs/days',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
    }),
    reminderWorklog: builder.mutation({
      query: (params) => ({
        url: '/api/worklogs/alert',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
    }),
  }),
});

export const {
  useGetAllWorklogsUserQuery,
  useGetAllWorklogsQuery,
  useGetAllWorklogsByMonthQuery,
  useCreateWorklogMutation,
  useCloneWorklogMutation,
  useUpdateWorklogMutation,
  useDeleteWorklogMutation,
  useUploadReportMutation,
  useGetWorkingDaysQuery,
  useGetAllReportsQuery,
  useReminderWorklogMutation,
  useGetReportsByUserIdQuery,
  useDeleteReportMutation,
  useGetWorkingDaysManualTableQuery,
} = worklogApiSlice;
