import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { Content } from './components/Content/Content';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Sidebar } from './components/Sidebar/Sidebar';
import { MoneyRainingApp } from '../../common/lib/annimations/MoneyItem/MoneyItem';

class Layout extends React.Component {
  static Header = ({ children, ...props }: any) => (
    <Header {...props}>{children}</Header>
  );
  static Sidebar = ({ children, ...props }: any) => (
    <Sidebar {...props}>{children}</Sidebar>
  );
  static Content = ({ children, ...props }: any) => (
    <Content {...props}>{children}</Content>
  );
  static Footer = ({ children, ...props }: any) => (
    <Footer {...props}>{children}</Footer>
  );
  render() {
    const { children }: any = this.props;
    return (
      <Box minH="100vh" bg="white">
        <MoneyRainingApp />
        {typeof children === 'function' ? children() : children}
      </Box>
    );
  }
}
export { Layout };
