import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ILoggedUser } from '../../data/layoutInterfaces';
import jwt_decode from 'jwt-decode';
import { useGetProfileDataQuery } from '../../../../common/lib/redux/slices/apiSlices/profileApiSlice';
import AvatarIcon from '../../../../assets/icons/AvatarIcon';
import NotificationBell from '../Notification/NotificationBell';
import NotificationList from '../Notification/NotificationList';
import { useGetNotificationCountQuery } from '../../../../common/lib/redux/slices/apiSlices/notificationsApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../common/lib/redux/store';
import { useIntl } from 'react-intl';
import { useEffect, useId } from 'react';
import { setGlobalProfileImage } from '../../../../common/lib/redux/slices/profileSlice';
import { useMediaQuery } from '@chakra-ui/react';
import logo from '../../../../assets/brand/ates-logo-new.png';
import { usePostEmployeeWorkEnvironmentStatusMutation } from '../../../../common/lib/redux/slices/apiSlices/employeesApiSlice';
import { CustomSwitch } from '../../../CustomSwitch/CustomSwitch';

interface IProps extends FlexProps {
  onOpen: () => void;
  loggedUser: ILoggedUser;
  logo?: string;
  avatarLogo?: any;
}

interface MyToken {
  userId: number;
}

const MobileNav = ({ onOpen, ...rest }: IProps) => {
  const { loggedUser } = rest;
  const { id: userId }: any = useSelector((store: RootState) => store.user);

  const navigate = useNavigate();

  const intl = useIntl();
  const dispatch = useDispatch();

  const [postEmployeeWorkEnvironmentStatus] =
    usePostEmployeeWorkEnvironmentStatusMutation();

  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');

  const token: any = localStorage.getItem('hrc_access_token');
  const id = jwt_decode<MyToken>(token)?.userId;
  const { data, refetch: refetchProfileData }: any = useGetProfileDataQuery(id);

  const {
    data: notificationCount,
    isLoading: isLoadingNotificationCount,
    refetch: refetchNotificationCount,
  } = useGetNotificationCountQuery(userId, {
    pollingInterval: 60 * 1000,
  });

  const firstName = data?.firstName ? data?.firstName : '';
  const lastName = data?.lastName ? data?.lastName : '';

  const formatedRole =
    loggedUser?.role[0] + loggedUser?.role.slice(1).toLowerCase();

  useEffect(() => {
    dispatch(
      setGlobalProfileImage({
        name: `${firstName} ${lastName}`,
        encodedContent: data?.content,
        type: data?.contentType,
      }),
    );
  }, [data, id]);

  const handleChangeUserEnvironmentStatus = () => {
    postEmployeeWorkEnvironmentStatus({
      userId,
      params: {
        isRemoteTemp: !data?.isRemoteTemp,
        isRemote: data?.isRemote,
      },
    });

    setTimeout(() => {
      refetchProfileData();
    }, 200);
  };

  const onChangeSwitch = () => {
    handleChangeUserEnvironmentStatus();
  };

  return (
    <Flex
      justifyContent={isSmallerThan768 ? 'space-between' : 'flex-end'}
      position="fixed"
      right="0"
      left={isSmallerThan768 ? 0 : '272px'}
      bg="white"
      padding="2"
      borderBottomRadius={4}
      boxShadow="15px 2px 10px #EDEDED"
      zIndex="1000"
    >
      {isSmallerThan768 && (
        <Image
          ml="2.5"
          w="138px"
          objectFit="contain"
          src={logo}
          onClick={() => navigate('/dashboard')}
          cursor="pointer"
          alt="atessoft"
        />
      )}
      <Flex>
        <Menu>
          <Box>
            <CustomSwitch
              isRemote={data?.isRemoteTemp}
              onChange={onChangeSwitch}
              type="navbar"
            />
          </Box>
          <MenuButton
            mr="2"
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: 'none' }}
          >
            <HStack justify={isSmallerThan768 ? 'center' : ''}>
              {data?.content ? (
                <Image
                  w="42px"
                  h="42px"
                  borderRadius="15px"
                  src={`data:${data?.contentType};base64,${data?.content}`}
                  objectFit={'cover'}
                />
              ) : (
                <AvatarIcon name={data?.firstName} surname={data?.lastName} />
              )}
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems="flex-start"
                spacing="-4px"
                mx="3 !important"
              >
                <Text fontSize="sm">{`${firstName}  ${lastName}`}</Text>
                <Text fontSize="12px" color="var(--chakra-colors-grey-500)">
                  {formatedRole.toUpperCase()}
                </Text>
              </VStack>
              <Box display={{ base: 'none', md: 'flex' }}>
                <FiChevronDown
                  style={{
                    color: 'var(--chakra-colors-grey-500)',
                    marginLeft: '-19px',
                    marginBottom: '10px',
                  }}
                />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList
            zIndex={'1000'}
            bg={useColorModeValue('white', 'gray.900')}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
          >
            <MenuItem onClick={() => navigate(`/employees/${userId}`)}>
              {intl.formatMessage({ id: 'MENU.MY_PROFILE' })}
            </MenuItem>
            <MenuItem onClick={() => navigate(`profile-settings`)}>
              {intl.formatMessage({ id: 'MENU.PROFILE_SETTINGS' })}
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => {
                localStorage.removeItem('hrc_access_token');
                navigate('/auth');
              }}
            >
              {intl.formatMessage({ id: 'MENU.SIGN_OUT' })}
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu>
          {({ isOpen, onClose }) => (
            <>
              <MenuButton>
                <NotificationBell
                  isOpen={isOpen}
                  notificationCount={notificationCount?.newNotificationCount}
                  refetchNotificationCount={refetchNotificationCount}
                  isLoadingNotificationCount={isLoadingNotificationCount}
                />
              </MenuButton>
              {isOpen && (
                <MenuList w="350px">
                  <NotificationList
                    isOpen={isOpen}
                    onClose={onClose}
                    notificationCount={notificationCount?.newNotificationCount}
                    refetchNotificationCount={refetchNotificationCount}
                  />
                </MenuList>
              )}
            </>
          )}
        </Menu>
      </Flex>
    </Flex>
  );
};

export { MobileNav };
