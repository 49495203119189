import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  MenuItem,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  useClearNotificationsMutation,
  useGetNotificationsQuery,
  useUpdateNotificationsMutation,
} from '../../../../common/lib/redux/slices/apiSlices/notificationsApiSlice';
import { RootState } from '../../../../common/lib/redux/store';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import DashboardViewPostModal from '../../../../features/Dashboard/DashboardViewPostModal';
import { useIntl } from 'react-intl';

interface INotification {
  content: string;
  createdTime: string;
  id: number;
  objectId: string;
  seenTime: string | null;
  title: string;
  type: string;
  postId?: number;
}

interface INotificationList {
  isOpen: boolean;
  onClose: () => void;
  notificationCount: number | undefined;
  refetchNotificationCount: () => void;
}

const NotificationList = ({
  isOpen,
  onClose,
  notificationCount,
  refetchNotificationCount,
}: INotificationList) => {
  const { id: userId } = useSelector((store: RootState) => store.user);
  const [toOpenPostId, setToOpenPostId] = useState<number>();

  const navigate = useNavigate();
  const intl = useIntl();

  const params = { size: 3, page: 0, sort: ['desc'], userId };

  const {
    data: notifications,
    isLoading: isLoadingNotificationList,
    refetch: refetchNotificationsList,
  } = useGetNotificationsQuery(params, { skip: !isOpen });

  const [clearNotifications] = useClearNotificationsMutation();
  const [clearSingleNotification] = useUpdateNotificationsMutation();

  const handleClearAll = () => {
    clearNotifications(userId)
      .then()
      .catch((err) => console.error(err));
  };

  const {
    isOpen: isOpenPost,
    onOpen: onOpenPost,
    onClose: onClosePost,
  } = useDisclosure();

  const handleNotificationClick = (notification: any) => {
    const params = {
      userId,
      notificationIds: notification?.id,
    };
    clearSingleNotification(params).then(() => {
      if (notification?.postId) {
        onOpenPost();
      } else {
        navigate('/notifications');
      }
      refetchNotificationCount();
      refetchNotificationsList();
    });
  };

  const handleNotificationSeenClick = (id: number) => {
    const params = {
      userId,
      notificationIds: id,
    };
    clearSingleNotification(params).then(() => {
      refetchNotificationCount();
      refetchNotificationsList();
    });
  };

  const handleSeeAll = () => {
    navigate('/notifications');
    onClose();
  };

  if (isLoadingNotificationList) {
    return (
      <Flex justify="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      {isOpenPost && (
        <DashboardViewPostModal
          isOpen={isOpenPost}
          onClose={onClosePost}
          id={toOpenPostId}
          postId={toOpenPostId}
        />
      )}
      {notificationCount && notifications ? (
        notifications?.content?.map((notification: INotification) => (
          <MenuItem
            key={notification?.id}
            display="flex"
            _hover={{ bg: 'transparent' }}
            justifyContent="space-between"
          >
            <Box
              fontSize="xs"
              p="2"
              w="92%"
              _hover={{ bg: '#dcdcdc' }}
              bg={`${notification?.seenTime ? 'transparent' : '#ededed'}`}
              borderRadius="10px"
            >
              <Text
                onClick={() => {
                  notification?.postId && setToOpenPostId(notification?.postId);
                  handleNotificationClick(notification);
                }}
                size="sm"
              >
                {notification?.content.length < 130
                  ? notification?.content
                  : `${notification?.content.slice(0, 130)}...`}
              </Text>
            </Box>
            {!notification?.seenTime && (
              <Tooltip label="Mark as seen" bg="gray.300" color="black">
                <Badge
                  bg="var(--chakra-colors-blue-400)"
                  w="10px"
                  h="10px"
                  borderRadius="50px"
                  onClick={() => handleNotificationSeenClick(notification?.id)}
                />
              </Tooltip>
            )}
          </MenuItem>
        ))
      ) : (
        <Text fontSize="sm" mb="2" textAlign="center">
          <FormattedMessage id={'NO_NOTIFICATION'} />
        </Text>
      )}
      <Divider w="95%" m="auto" />
      <Flex
        mt="2"
        justify={`${notificationCount ? 'space-between' : 'center'}`}
        px="8px"
      >
        <Button
          size="xs"
          fontWeight="400"
          bg="var(--chakra-colors-blue-500)"
          color="#fff"
          _hover={{ bg: 'var(--chakra-colors-blue-400)' }}
          onClick={handleSeeAll}
        >
          {intl.formatMessage({ id: 'SEE_ALL' })}
        </Button>
        {!!notificationCount && (
          <Button size="xs" onClick={handleClearAll}>
            {intl.formatMessage({ id: 'MARK_ALL_AS_SEEN' })}
          </Button>
        )}
      </Flex>
    </>
  );
};

export default NotificationList;
