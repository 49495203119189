import { createSlice } from '@reduxjs/toolkit';

interface ProjectsState {
  projectForm: {
    name: string;
    description: string;
    client: {
      id: null | number;
      value: string;
    };
    isArchived: boolean;
  };
  projectParams: {
    name: string;
  };
  projectsPageable: {
    page: number;
    size: string | number;
    sort: string[];
  };
  projectsAssignUnassign: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
    userId: number | null;
  };
  projectLogo: {
    name: string;
    encodedContent: string;
    contentType: string;
  };
  inputSearch: string;
  projectStatus: string;
  isTableLook: boolean;
  currentKeyLocation: string;
  projectUploadForm: {
    files: File[] | [];
  };
}

// const storedState = localStorage.getItem('pageableState');

const initialState: ProjectsState = {
  projectForm: {
    name: '',
    description: '',
    client: {
      id: null,
      value: '',
    },
    isArchived: false,
  },
  projectParams: {
    name: '',
  },
  projectsPageable: {
    page: 0,
    size: '',
    sort: ['name', 'asc'],
  },
  projectsAssignUnassign: {
    startDate: null,
    endDate: null,
    userId: null,
  },
  projectLogo: {
    name: '',
    encodedContent: '',
    contentType: '',
  },
  inputSearch: '',
  projectStatus: 'active',
  isTableLook: false,
  currentKeyLocation: '',
  projectUploadForm: {
    files: [],
  },
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setLayout: (state) => {
      state.isTableLook = !state.isTableLook;
    },
    setInputSearch: (state, action) => {
      state.inputSearch = action.payload;
    },
    clearInputSearch: (state) => {
      state.inputSearch = '';
    },
    setCurrentKeyLocation: (state, action) => {
      state.currentKeyLocation = action.payload;
    },
    setProjectStatus: (state, action) => {
      state.projectStatus = action.payload;
    },
    setProjectForm: (state, action: any) => {
      const { name, value } = action.payload;

      state.projectForm = {
        ...state.projectForm,
        [name]: value,
      };
    },
    setProjectLogo: (state: any, action: any) => {
      const { name, encodedContent, contentType } = action.payload;

      state.projectLogo = {
        name,
        encodedContent,
        contentType,
      };
    },
    clearProjectLogo: (state: any) => {
      return {
        ...state,
        projectLogo: {
          name: '',
          encodedContent: '',
          contentType: '',
        },
      };
    },
    setArchiveProject: (state) => {
      state.projectForm = {
        ...state.projectForm,
        isArchived: !state.projectForm.isArchived,
      };
    },
    setSelectedClient: (state, action: any) => {
      const { id, value } = action.payload;
      state.projectForm = {
        ...state.projectForm,
        client: {
          id,
          value,
        },
      };
    },
    clearProjectForm: (state) => {
      state.projectForm = {
        name: '',
        description: '',
        client: {
          id: null,
          value: '',
        },
        isArchived: false,
      };
    },
    setEditProject: (state, action: any) => {
      const {
        name,
        description,
        client: { id, value },
        isArchived,
      } = action.payload;
      state.projectForm = {
        name,
        description,
        client: {
          id,
          value,
        },
        isArchived,
      };
    },
    setProjectArchived: (state: any) => {
      state.archived = !state.archived;
    },
    setProjectsPageable: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.projectsPageable = {
        ...state.projectsPageable,
        [key]: value,
      };
    },
    clearProjectsPageable: (state) => {
      state.projectsPageable = {
        page: 0,
        size: '',
        sort: ['name', 'asc'],
      };
    },
    setProjectsParams: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      if (key === 'name' && typeof value === 'string') {
        return {
          ...state,
          projectParams: {
            name: value,
          },
        };
      }

      return {
        ...state,
        projectParams: {
          ...state.projectParams,
          [key]: value,
        },
      };
    },
    setAddEditProject: (state: any, action: { payload: any; type: string }) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];
      return {
        ...state,
        setAddEditProject: {
          ...state.setAddEditProject,
          [key]: value,
        },
      };
    },
    setProjectAssignUnassign: (state, action: any) => {
      const { name, value } = action.payload;
      state.projectsAssignUnassign = {
        ...state.projectsAssignUnassign,
        [name]: value,
      };
    },
    clearProjectAssignUnassign: (state) => {
      state.projectsAssignUnassign = {
        userId: null,
        startDate: undefined,
        endDate: undefined,
      };
    },
    setProjectUploadForm: (state, action) => {
      state.projectUploadForm.files = action.payload;
    },
  },
});

export const {
  setAddEditProject,
  setCurrentKeyLocation,
  setInputSearch,
  clearInputSearch,
  setProjectForm,
  setArchiveProject,
  setProjectsPageable,
  setSelectedClient,
  clearProjectsPageable,
  setLayout,
  clearProjectForm,
  setEditProject,
  setProjectsParams,
  setProjectArchived,
  setProjectAssignUnassign,
  clearProjectAssignUnassign,
  setProjectStatus,
  setProjectLogo,
  clearProjectLogo,
  setProjectUploadForm,
} = projectsSlice.actions;

export default projectsSlice.reducer;
