import { createSlice } from '@reduxjs/toolkit';

export interface IClientParams {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  vacationDays: number;
  birthDate: string;
  company: string;
  seniority: string;
  position: string;
  linkedin: string;
  totalEmployees: number | null;
  dateOfJoining: string;
  shortName: any;
  address: any;
  invoiceCounter: any;
  vat: number | null;
  workingHoursPerDay: number | null;
  paysForHolidays: boolean;
}

export interface IClientPageable {
  page: number;
  size: string | number;
  sort: string[];
}

export const initialState = {
  clientsParams: {
    name: '',
    country: '',
    dateOfJoining: '',
    website: '',
    linkedin: '',
    totalEmployees: null,
    shortName: '',
    address: '',
    invoiceCounter: '',
    number: null,
  },
  clientsPageable: {
    page: 0,
    size: '',
    sort: ['name', 'asc'],
  },
  addClientForm: {
    name: '',
    country: '',
    about: '',
    website: '',
    // email: '',
    linkedin: '',
    totalEmployees: null,
    dateOfJoining: '',
    shortName: '',
    address: '',
    invoiceCounter: '',
    vat: null,
    workingHoursPerDay: null,
    paysForHolidays: false,
  },
  addEditClient: {
    name: '',
    about: '',
    country: '',
    content: '',
    dateOfJoining: '',
    isActive: true,
    website: '',
    linkedin: '',
    totalEmployees: null,
    shortName: '',
    address: '',
    invoiceCounter: '',
    vat: null,
    workingHoursPerDay: null,
    paysForHolidays: false,
  },
  clientLogo: {
    name: '',
    encodedContent: '',
    contentType: '',
  },
  editClient: {
    about: '',
    active: '',
    content: '',
    country: '',
    dateOfJoining: '',
    id: 0,
    message: '',
    name: '',
    website: '',
    linkedin: '',
    totalEmployees: null,
    shortName: '',
    address: '',
    invoiceCounter: '',
    vat: null,
    workingHoursPerDay: null,
    paysForHolidays: false,
  },
  inputSearch: '',
  clientStatus: 'active',
  isTableLook: false,
  currentKeyLocation: '',
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setInputSearch: (state, action) => {
      state.inputSearch = action.payload;
    },
    clearInputSearch: (state) => {
      state.inputSearch = '';
    },
    setCurrentKeyLocation: (state, action) => {
      state.currentKeyLocation = action.payload;
    },
    setAddEditClient: (state: any, action: { payload: any; type: string }) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];
      return {
        ...state,
        addEditClient: {
          ...state.addEditClient,
          [key]: value,
        },
      };
    },
    setLayout: (state) => {
      state.isTableLook = !state.isTableLook;
    },
    setClientStatus: (state, action) => {
      state.clientStatus = action.payload;
    },
    setClientLogo: (state: any, action: any) => {
      const { name, encodedContent, contentType } = action.payload;

      state.clientLogo = {
        name,
        encodedContent,
        contentType,
      };
    },
    setClientForm: (state: any, action: any) => {
      const { name, value } = action.payload;

      state.addClientForm = {
        ...state.addClientForm,
        [name]: value,
      };
    },
    clearClientForm: (state: any) => {
      state.addClientForm = {
        name: '',
        country: '',
        about: '',
        website: '',
        linkedin: '',
        totalEmployees: null,
        dateOfJoining: '',
        vat: null,
        workingHoursPerDay: null,
        paysForHolidays: false,
      };
    },
    setEditClient: (state: any, action: any) => {
      const {
        name,
        country,
        about,
        website,
        linkedin,
        totalEmployees,
        dateOfJoining,
        shortName,
        address,
        invoiceCounter,
        vat,
        workingHoursPerDay,
        paysForHolidays,
      } = action.payload;
      state.addClientForm = {
        name,
        country,
        about,
        website,
        linkedin,
        totalEmployees,
        dateOfJoining,
        shortName,
        address,
        invoiceCounter,
        vat,
        workingHoursPerDay,
        paysForHolidays,
      };
    },
    setClientArchived: (state: any) => {
      state.isActive = !state.isActive;
    },
    clearAddClient: (state: any) => {
      return {
        ...state,
        addEditClient: {
          name: '',
          about: '',
          country: '',
          content: '',
          dateOfJoining: '',
          isActive: true,
          website: '',
          linkedin: '',
          totalEmployees: null,
          shortName: '',
          address: '',
          invoiceCounter: '',
          vat: null,
          workingHoursPerDay: null,
          paysForHolidays: false,
        },
      };
    },
    setClientsPageable: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      state.clientsPageable = {
        ...state.clientsPageable,
        [key]: value,
      };
    },
    clearClientsPageable: (state) => {
      state.clientsPageable = {
        page: 0,
        size: '',
        sort: ['name', 'asc'],
      };
    },
    clearClientLogo: (state: any) => {
      return {
        ...state,
        clientLogo: {
          name: '',
          encodedContent: '',
          contentType: '',
        },
      };
    },
  },
});

export const {
  setInputSearch,
  clearInputSearch,
  setCurrentKeyLocation,
  setAddEditClient,
  setClientLogo,
  setClientsPageable,
  clearClientsPageable,
  clearClientLogo,
  clearAddClient,
  setEditClient,
  setClientForm,
  clearClientForm,
  setClientArchived,
  setClientStatus,
  setLayout,
} = clientsSlice.actions;

export default clientsSlice.reducer;
