import { Badge, Box, IconButton } from '@chakra-ui/react';
import BellIcon from '../../../../assets/icons/BellIcon';
import { useEffect } from 'react';

const NotificationBell = ({
  isOpen,
  notificationCount,
  isLoadingNotificationCount,
  refetchNotificationCount,
}: {
  isOpen: boolean;
  notificationCount: any;
  isLoadingNotificationCount: boolean;
  refetchNotificationCount: any;
}) => {
  useEffect(() => {
    refetchNotificationCount();
  }, [isOpen]);

  if (isLoadingNotificationCount) {
    return (
      <IconButton
        as="div"
        size="lg"
        variant="ghost"
        aria-label="open menu"
        icon={<BellIcon stroke="var(--chakra-colors-blue-500)" />}
      />
    );
  }

  return (
    <Box position="relative">
      <IconButton
        as="div"
        size="lg"
        variant="ghost"
        aria-label="open menu"
        icon={<BellIcon stroke="var(--chakra-colors-blue-500)" />}
      />
      {!!notificationCount && !isOpen && (
        <Badge
          colorScheme="red"
          borderRadius="full"
          size="sm"
          position="absolute"
          top="-1px"
          right="10px"
        >
          {notificationCount}
        </Badge>
      )}
    </Box>
  );
};

export default NotificationBell;
