import React from 'react'

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
};

const HolidaysIcon: React.FC<IconTypes> = ({height, width, stroke, fill}) => {
  return (
    <svg
    width={width ?? 20}
    height={height ?? 20}
    viewBox="0 0 20 20"
    fill={fill ?? "none"}
    stroke={stroke ?? 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
    
  <path
    d="M1 8.20764C1 8.20764 2.14407 2.4873 10 2.4873C13.8466 2.4873 16.0841 3.53988 17.3659 5.04239"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
  />
  <path
    d="M10 8.93219V16.7119"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M10 1.00001V8.20764"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M10 2.4873C10 2.4873 5.88136 4.12713 5.5 8.20764"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M10 2.4873C10 2.4873 14.1186 4.12713 14.5 8.20764"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M5.5 8.13135C5.5 9.374 4.49265 10.3813 3.25 10.3813C2.00735 10.3813 1 9.374 1 8.13135"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M10 8.13135C10 9.374 8.99265 10.3813 7.75 10.3813C6.50735 10.3813 5.5 9.374 5.5 8.13135"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M14.5 8.13135C14.5 9.374 13.4926 10.3813 12.25 10.3813C11.0074 10.3813 10 9.374 10 8.13135"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M19 8.13135C19 9.374 17.9926 10.3813 16.75 10.3813C15.5074 10.3813 14.5 9.374 14.5 8.13135"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M1 16.8263H12.1536C12.9106 16.8263 13.6485 16.5887 14.2635 16.1471L19 12.7458"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M3.23997 17.6978L3.21711 17.6868L2.5854 18.9945C2.58434 18.9967 2.5838 18.9982 2.58353 18.999L2.58323 19.0001C2.58337 19.0009 2.58396 19.0034 2.58608 19.0068C2.58821 19.0102 2.59022 19.0118 2.59089 19.0123L2.59198 19.0125C2.59283 19.0126 2.59442 19.0127 2.59685 19.0127C2.6017 19.0127 2.60616 19.01 2.6083 19.0055L3.23997 17.6978ZM3.23997 17.6978L2.6083 19.0055L3.23997 17.6978Z"
    strokeWidth="1.5"
  />
  <path
    d="M13.3253 17.6868L13.3024 17.6978L13.9341 19.0055C13.9341 19.0055 13.9341 19.0055 13.9341 19.0055C13.9363 19.01 13.9407 19.0127 13.9456 19.0127C13.948 19.0127 13.9496 19.0126 13.9504 19.0125L13.9515 19.0123C13.9522 19.0118 13.9542 19.0102 13.9563 19.0068C13.9585 19.0034 13.9591 19.0009 13.9592 19L13.9589 18.999C13.9586 18.9982 13.9581 18.9967 13.957 18.9945C13.957 18.9945 13.957 18.9945 13.957 18.9945L13.3253 17.6868Z"
    strokeWidth="1.5"
  />
</svg>

  

  )
}

export default HolidaysIcon