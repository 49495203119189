import React, { FC, createContext, useContext } from 'react';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

type Props = {
  selectedLang: 'en';
};

const initialState: Props = {
  selectedLang: 'en',
};

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls) as Props;
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, 'en');
  window.location.reload();
}

const I18nContext = createContext<Props>(initialState);

const useLang = () => {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  return ls;
};

const i18nProvider: FC = ({ children }: any) => {
  const lang = getConfig();
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export { i18nProvider, useLang };
