import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { allHolidaysMapper } from '../../../../../helpers/holidaysApiHelpers';
import { IHolidayContent } from '../../../../../helpers/holidaysApiInterfaces';
import { URL_ROOT } from '../../../axios/axiosRequest';

export const holidayApiSlice = createApi({
  reducerPath: 'holidaySlice',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['AllHolidays', 'AddHoliday', 'AllBirthdays'],
  endpoints: (builder) => ({
    getAllHolidays: builder.query({
      query: ({ params }) => ({
        url: '/api/holiday',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      transformResponse: (response: any) => {
        const content = response?.content?.map((user: any) =>
          allHolidaysMapper(user),
        );

        const totalPages = response?.totalPages;
        const totalElements = response?.totalElements;
        return { content, totalPages, totalElements };
      },
      providesTags: ['AllHolidays'],
    }),
    getAllHoliday: builder.query({
      query: ({ params, body }) => ({
        url: '/api/holidays',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
    }),
    addHoliday: builder.mutation({
      query: (body: Partial<IHolidayContent>) => ({
        url: '/api/holiday',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AddHoliday'],
    }),
    updateHoliday: builder.mutation({
      query: (body) => ({
        url: '/api/holiday',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AddHoliday'],
    }),
    deleteHoliday: builder.mutation({
      query: (id) => ({
        url: `/api/holiday/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
    }),
    getAllBirthdays: builder.query({
      query: ({ params }) => ({
        url: '/api/holiday-birthday',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['AllBirthdays'],
    }),
  }),
});

export const {
  useGetAllHolidayQuery,
  useGetAllHolidaysQuery,
  useAddHolidayMutation,
  useUpdateHolidayMutation,
  useDeleteHolidayMutation,
  useGetAllBirthdaysQuery,
} = holidayApiSlice;
