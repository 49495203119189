import React from 'react'

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
};

const DashboardIcon: React.FC<IconTypes> = ({height, width, stroke, fill}) => {
  return (
    <svg
  width={width ?? 20}
  height={height ?? 20}
  viewBox="0 0 20 20"
  fill={fill ?? "none"}
  stroke={stroke ?? 'white'}
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    x={1}
    y={1}
    width={8}
    height={8}
    rx={2}
    strokeWidth="1.5"
  />
  <rect
    x={1}
    y={11}
    width={8}
    height={8}
    rx={2}
    strokeWidth="1.5"
  />
  <rect
    x={11}
    y={1}
    width={8}
    height={8}
    rx={2}
    strokeWidth="1.5"
  />
  <path
    d="M19 16.5V17C19 18.1046 18.1046 19 17 19H13C11.8954 19 11 18.1046 11 17V13C11 11.8954 11.8954 11 13 11H17C18.1046 11 19 11.8954 19 13V13"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>

  )
}

export default DashboardIcon