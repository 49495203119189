export const notificationsMapper = (notifications: any) => {
  const { totalPages } = notifications;
  let content = notifications?.content?.map((notification: any) => {
    return {
      content: notification?.content,
      type: notification?.type,
      seenTime: notification?.seenTime,
      id: notification?.id,
      createdTime: notification?.createdTime,
      user: {
        firstName: notification?.user?.firstName,
        lastName: notification?.user?.lastName,
        content: notification?.user?.content,
        contentType: notification?.user?.contentType,
        id: notification?.user?.id,
      },
      postId: notification?.postId,
    };
  });

  return { content, totalPages };
};
