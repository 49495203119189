import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const ButtonStyles: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    primary: (props) => ({
      bg: 'primaryLight',
      color: '#fff',
      _hover: { bg: mode('primaryDark', 'primaryLight')(props) },
      boxShadow: 'md',
      _active: { bg: mode('primaryDarker', 'primaryLighter')(props) },
      borderRadius: 'xl',
    }),
    rounded: (props) => ({
      border: '1px solid #fff',
      bg: 'primaryLight',
      color: '#fff',
      _hover: { bg: mode('primaryDark', 'primaryLight')(props) },
      boxShadow: 'md',
      _active: { bg: mode('primaryDarker', 'primaryLighter')(props) },
      borderRadius: 'xl',
    }),
  },
};
