import { StyleProps } from '@chakra-ui/react';
interface IPagesStyles {
  wrapper: StyleProps;
  pageHeader: StyleProps;
  pageTitle: StyleProps;
  formHeader: StyleProps;
  modalHeader: StyleProps;
  formRow: StyleProps;
  formLabelBox: StyleProps;
  formLabel: StyleProps;
  formInputBox: StyleProps;
  formInput: StyleProps;
  formSelect: StyleProps;
  splitedInputField: StyleProps;
  splitedFormInputBox: StyleProps;
  splitedFormInput: StyleProps;
  addButton: StyleProps;
  deleteButton: StyleProps;
  cancelButton: StyleProps;
  selecetOnPage: StyleProps;
  options: StyleProps;
  notificationText: StyleProps;
  tostifyHeader: StyleProps;
  tostifyText: StyleProps;
  textAreaInputBox: StyleProps;
  formRowAutoComplete: StyleProps;
  select: StyleProps;
  headerSelect: StyleProps;
  headerInput: StyleProps;
  errorPage: StyleProps;
  inputBox: StyleProps;
  input: StyleProps;
  avatarBadge: StyleProps;
  avatar: {
    size: string;
  };
}

export const PagesStyles: IPagesStyles = {
  wrapper: {
    minH: 'calc(100vh - var(--chakra-sizes-20) - var(--chakra-space-10))',
  },
  pageHeader: {
    w: 'full',
    alignItems: 'center',
    mt: '6px',
    mb: '6px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  pageTitle: {
    fontWeight: '500',
    color: 'var(--chakra-colors-grey-900)',
    fontSize: '24',
    lineHeight: '31.2px',
  },
  formHeader: {
    fontSize: '18px',
    lineHeight: '25.2px',
    fontWeight: '500',
  },
  modalHeader: {
    fontFamily: 'Kanit, sans-serif',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '41.6px',
    color: 'grey.900',
  },
  formRow: {
    display: 'flex',
    alignItems: 'center',
    w: '100%',
    borderBottom: '1px solid var(--chakra-colors-borderFormColor)',
    height: '80px',
    overflow: 'hidden',
  },
  formRowAutoComplete: {
    display: 'flex',
    alignItems: 'center',
    w: '100%',
    borderBottom: '1px solid var(--chakra-colors-borderFormColor)',
    height: 'auto',
  },
  formLabelBox: {
    w: '200px',
    display: 'grid',
    placeItems: 'center left',
    h: '80px',
    // bg: 'var(--chakra-colors-grey-100)',
  },
  formLabel: {
    color: 'var(--chakra-colors-grey-900)',
    fontSize: '14px',
    lineHeight: '19.6px',
    fontWeight: '500',
    m: '0 !important',
  },
  formInputBox: {
    m: '0px !important',
    // bg: 'var(--chakra-colors-grey-200)',
    p: '30px 20px',
    w: '100%',
  },
  splitedFormInputBox: {
    m: '0px !important',
    // bg: 'var(--chakra-colors-grey-200)',
    p: '30px 0px 30px 5px',
    w: '90%',
  },
  splitedFormInput: {
    width: '90%',
    color: 'var(--chakra-colors-grey-900)',
    borderRadius: '25px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    lineHeight: '19.6px',
    fontWeight: '200',
    backgroundColor: '#fff',
    padding: '15px 20px !important',
    border: '0.5px solid var(--chakra-colors-grey-400) !important',
  },
  splitedInputField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    w: '90%',
  },
  textAreaInputBox: {
    mt: '20px',
    p: '30px 20px',
    w: '100%',
  },
  formInput: {
    width: '100%',
    color: 'var(--chakra-colors-grey-900)',
    borderRadius: '25px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    lineHeight: '19.6px',
    fontWeight: '400',
    backgroundColor: '#fff',
    padding: '15px 20px !important',
    border: '0.5px solid var(--chakra-colors-grey-400) !important',
  },
  avatar: {
    size: '2xl',
  },
  inputBox: {
    bottom: '-4px',
    right: '-9px',
    w: '50px',
    cursor: 'pointer',
    zIndex: '999',
    h: '40px',
  },
  input: {
    opacity: 0,
    bottom: '0px',
    right: '0px',
    cursor: 'pointer',
  },
  avatarBadge: {
    borderColor: 'var(--chakra-colors-blue-400)',
    bg: 'var(--chakra-colors-blue-400)',
    boxSize: '1em',
  },
  formSelect: {
    w: '100%',
    background: '#fff',
    borderRadius: '25px',
    fontSize: '14px',
    cursor: 'pointer',
    // lineHeight: '19.6px',
    fontWeight: '400',
    border: '0.5px solid var(--chakra-colors-grey-400) !important',
  },
  addButton: {
    bg: 'var(--chakra-colors-blue-500)',
    py: '5.5px !important',
    px: '20px !important',
    color: '#fff',
    fontSize: '13px !important',
    lineHeight: '19.5px !important',
    fontWeight: '500',
    textAlign: 'center',
    textTransform: 'uppercase',
    minWidth: 'min-content',
    height: '31px !imprortant',
    borderRadius: '12px',
  },
  deleteButton: {
    bg: 'notification.danger',
    py: '5.5px',
    px: '20px',
    color: '#fff',
    fontSize: '13px',
    lineHeight: '19.5px',
    fontWeight: '500',
    textAlign: 'center',
    textTransform: 'uppercase',
    height: '31px !imprortant',
    borderRadius: '12px',
  },
  cancelButton: {
    bg: 'var(--chakra-colors-grey-300)',
    py: '5.5px',
    px: '20px',
    color: 'var(--chakra-colors-grey-600)',
    fontSize: '13px',
    lineHeight: '19.5px',
    textTransform: 'uppercase',
    fontWeight: '500',
    height: '31px !important',
    borderRadius: '12px',
  },
  selecetOnPage: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '19.6',
    color: 'var(--chakra-colors-gray-900)',
  },
  options: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '19.6',
    color: 'var(--chakra-colors-gray-900)',
  },
  notificationText: {
    backgroundColor: 'var(--chakra-colors-notification-danger)',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    fontFamily: 'Robot, sans-serif',
    color: '#fff',
    borderRadius: '25px',
    padding: '15px 20px',
  },
  tostifyHeader: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    fontFamily: 'Kanit, sans-serif',
  },
  tostifyText: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    fontFamily: 'Robot, sans-serif',
  },
  select: {
    w: '180px',
    background: '#fff',
    borderRadius: '25px',
    fontSize: '14px',
    fontWeight: '400',
    border: '0.5px solid var(--chakra-colors-grey-400) !important',
  },
  headerSelect: {
    h: '31px',
    minWidth: '160px',
    fontSize: '13px',
    fontFamily: 'Kanit, sans-serif',
    bg: '#fff',
    border: '1px solid #D3D3D3 !important',
    borderRadius: '12px',
  },
  headerInput: {
    h: '31px',
    w: {
      base: '100px',
      sm: '140px',
      md: '180px',
      xl: '200px',
      lg: '250px',
      '2xl': '300px',
    },
    fontSize: '13px',
    fontFamily: 'Kanit, sans-serif',
    bg: '#fff',
    border: '1px solid #D3D3D3',
    borderRadius: '12px',
  },
  errorPage: {
    w: '100%',
    marginTop: '140px !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
