import { createSlice } from '@reduxjs/toolkit';

// export interface IValues {
//   login: any;
//   forgot: any;
//   create: any;
// }

// export interface IAuth {
//   type: "login" | "forgot" | "create";
//   showPassword: boolean;
//   formValues: IValues;
// }

export const initialState: any = {
  dateOfReview: '',
  clientName: '',
  average: 0,
  openReview: true,
};

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setReview: (state: any, action) => {
      state.type = action.payload;
    },
  },
});

export const { setReview } = reviewsSlice.actions;
export default reviewsSlice.reducer;
