import jwtDecode from 'jwt-decode';

export const useUserId = () => {
  const token = localStorage.getItem('hrc_access_token');
  if (token) {
    const decodedToken: any = jwtDecode(token);
    const userId = decodedToken?.userId;
    return userId;
  }
  return null;
};
