import {
  IFetchedRole,
  IFetchedTechnology,
  IFetchedUser,
  IMappedUser,
} from '../../../../../helpers/employeesApiInterfaces';
import {
  allRolesMapper,
  allTechnologiesMapper,
  allUsersContentMapper,
  allUsersMapper,
} from '../../../../../helpers/employeesApiHelpers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { URL_ROOT } from '../../../axios/axiosRequest';

export const employeesApiSlice = createApi({
  reducerPath: 'apiEmployee',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: [
    'Employees',
    'AllPositions',
    'AllSeniorities',
    'AllTechnologies',
    'AllRoles',
    'AllAssignProject',
    'AllUnassignProject',
  ],
  endpoints: (builder) => ({
    getAllEmployees: builder.query({
      query: ({ params, body }: any) => ({
        url: '/api/all-users',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
      transformResponse: (response: IFetchedUser) => {
        const content = response?.content?.map((user: IMappedUser) =>
          allUsersContentMapper(user),
        );
        return allUsersMapper(content, response?.totalPages, response?.number);
      },
      providesTags: ['Employees'],
    }),
    getEmployee: builder.query({
      query: (id) => ({
        url: `/api/users/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Employees'],
    }),
    getAllActiveUsers: builder.query({
      query: () => ({
        url: '/api/all-active-users',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getAllPositions: builder.query({
      query: () => ({
        url: '/api/users/positions',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['AllPositions'],
    }),
    getAllSeniorities: builder.query({
      query: () => ({
        url: '/api/users/seniority',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['AllSeniorities'],
    }),
    getAllTechnologies: builder.query({
      query: () => ({
        url: '/api/technologies',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      transformResponse: (response: IFetchedTechnology[]) => {
        const content = response?.map((technology: IFetchedTechnology) =>
          allTechnologiesMapper(technology),
        );
        return content;
      },
      providesTags: ['AllTechnologies'],
    }),
    getAllRoles: builder.query({
      query: () => ({
        url: '/api/roles',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      transformResponse: (response: IFetchedRole[]) => {
        const content = response?.map((role: IFetchedRole) =>
          allRolesMapper(role),
        );
        return content;
      },
      providesTags: ['AllRoles'],
    }),
    updateEmployeeRole: builder.mutation({
      query: ({ userId, roleId }) => ({
        url: `/api/users/${userId}/${roleId}/change-role`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['Employees'],
    }),
    postNewEmployee: builder.mutation({
      query: (body) => ({
        url: '/api/users',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['Employees'],
    }),
    updateEmployee: builder.mutation({
      query: ({ body, id }) => ({
        url: `/api/users/${id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['Employees'],
    }),
    updateArchivedEmployee: builder.mutation({
      query: ({ body, id }) => ({
        url: `/api/users/${id}/archived`,
        method: `PATCH`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['Employees'],
    }),
    resetEmployeePassword: builder.mutation({
      query: ({ id }) => ({
        url: `/api/users/${id}/reset-password`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['Employees'],
    }),
    postAssignProjectToEmployee: builder.mutation({
      query: ({ params, projectId }) => ({
        url: `/api/project/${projectId}/assign`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      invalidatesTags: ['Employees'],
    }),
    postUnassignProjectToEmployee: builder.mutation({
      query: ({ params, projectId }) => ({
        url: `/api/project/${projectId}/unassign`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      invalidatesTags: ['Employees'],
    }),
    deleteEmployeeInactiveProject: builder.mutation({
      query: ({ projectId, userId }) => ({
        url: `/api/project/${projectId}/user/${userId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['Employees'],
    }),
    postEmployeeWorkEnvironmentStatus: builder.mutation({
      query: ({ userId, params }) => ({
        url: `/api/work-environment/${userId}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      invalidatesTags: ['Employees'],
    }),
  }),
});

export const {
  useGetAllEmployeesQuery,
  useGetEmployeeQuery,
  useGetAllActiveUsersQuery,
  useGetAllPositionsQuery,
  useGetAllSenioritiesQuery,
  useGetAllTechnologiesQuery,
  usePostNewEmployeeMutation,
  useUpdateEmployeeMutation,
  useGetAllRolesQuery,
  useUpdateArchivedEmployeeMutation,
  useResetEmployeePasswordMutation,
  usePostAssignProjectToEmployeeMutation,
  usePostUnassignProjectToEmployeeMutation,
  useUpdateEmployeeRoleMutation,
  useDeleteEmployeeInactiveProjectMutation,
  usePostEmployeeWorkEnvironmentStatusMutation,
} = employeesApiSlice;
