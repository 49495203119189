import { Box } from '@chakra-ui/react';
import backgroundAuth2 from '../../../../assets/images/backgroundAuth2.svg';

type Props = {};

const Content = ({ children }: any) => {
  return (
    <Box
      ml={{ base: 0, md: 60 }}
      p="5"
      pl="40px"
      // backgroundImage={backgroundAuth2}
    >
      {children}
    </Box>
  );
};

export { Content };
