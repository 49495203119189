import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_ROOT } from '../../../axios/axiosRequest';
import { allProjectsMapper } from '../../../../../helpers/projectApiHelpers';

export const projectsApiSlice = createApi({
  reducerPath: 'projectsApi',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: [
    'Projects',
    'ProjectsByUserId',
    'ProjectById',
    'ProjectsByProjectId',
    'AllActiveProjects',
  ],
  endpoints: (builder) => ({
    getAllProjects: builder.query({
      query: ({ params, body }) => ({
        url: '/api/project/filter',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
      transformResponse: (response: any) => {
        const content = response?.content?.map((project: any) =>
          allProjectsMapper(project),
        );
        return { content, totalPages: response?.totalPages };
      },
      providesTags: ['Projects'],
    }),
    createProject: builder.mutation({
      query: (body) => ({
        url: '/api/project',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['Projects'],
    }),
    updateProject: builder.mutation({
      query: (body) => ({
        url: '/api/project',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: [
        'Projects',
        'ProjectById',
        'ProjectsByProjectId',
        'ProjectsByUserId',
      ],
    }),
    updateArchivedProject: builder.mutation({
      query: ({ body, id }) => ({
        url: `/api/project/${id}`,
        method: `PATCH`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['ProjectsByUserId', 'ProjectsByProjectId', 'Projects'],
    }),
    getProjectById: builder.query({
      query: ({ id }) => ({
        url: `/api/project/${id}`,
        method: `GET`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['ProjectById'],
    }),
    getAllActiveProjects: builder.query({
      query: () => ({
        url: `/api/all-active-projects`,
        method: `GET`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['AllActiveProjects'],
    }),
    deleteProject: builder.mutation({
      query: (id) => ({
        url: `/api/project/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['Projects', 'ProjectById', 'AllActiveProjects'],
    }),
    getProjectsByProjectId: builder.query({
      query: ({ params, projectId }) => ({
        url: `api/project/${projectId}/details`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['ProjectsByProjectId'],
    }),
    getProjectsByUserId: builder.query({
      query: (userId) => ({
        url: `/api/project/user/${userId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['ProjectsByUserId'],
    }),
    patchProjectImage: builder.mutation({
      query: ({ projectId, body }: any) => ({
        url: `/api/project/${projectId}/logo`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['Projects', 'ProjectById', 'ProjectsByProjectId'],
    }),
    deleteProjectImage: builder.mutation({
      query: ({ projectId }: any) => ({
        url: `/api/project/${projectId}/logo`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: [
        'Projects',
        'ProjectById',
        'ProjectsByProjectId',
        'ProjectsByUserId',
      ],
    }),
    saveProjectFile: builder.mutation({
      query: ({ body, projectId }) => ({
        url: `api/project/files/${projectId}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['ProjectById', 'ProjectsByProjectId'],
    }),
    deleteProjectFile: builder.mutation({
      query: (id) => ({
        url: `api/project/files/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['ProjectById', 'ProjectsByProjectId'],
    }),
    updateEmployeeActiveProject: builder.mutation({
      query: (params) => ({
        url: 'api/project/date',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetProjectsByProjectIdQuery,
  useGetAllActiveProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectByIdQuery,
  usePatchProjectImageMutation,
  useDeleteProjectImageMutation,
  useUpdateArchivedProjectMutation,
  useGetProjectsByUserIdQuery,
  useSaveProjectFileMutation,
  useDeleteProjectFileMutation,
  useUpdateEmployeeActiveProjectMutation,
} = projectsApiSlice;
