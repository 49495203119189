import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  daysOffMapper,
  unresolvedDaysOffMapper,
} from '../../../../../helpers/daysOffApiSlice';
import { URL_ROOT } from '../../../axios/axiosRequest';

export const daysOffApiSlice = createApi({
  reducerPath: 'apiDaysOff',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: [
    'AllDaysOff',
    'FilteredDaysOff',
    'Export',
    'Delete',
    'PostExportDays',
    'AllDaysOffByDate',
    'DaysOffCalendar',
    'UnresolvedDaysOff',
    'AllDaysOffEmployee',
    'ExportVacationDaysDecision',
  ],
  endpoints: (builder) => ({
    getAllDaysOff: builder.query({
      query: ({ params }) => ({
        url: '/api/days-off',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      transformResponse: (response: any) => {
        const content = response?.content?.map((daysOff: any) =>
          daysOffMapper(daysOff),
        );
        return content;
      },
      providesTags: ['AllDaysOff'],
    }),
    getAllDaysOffForSingleUser: builder.query({
      query: ({ params }) => ({
        url: '/api/days-off/user',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['AllDaysOff'],
    }),
    getAllDaysOffEmployee: builder.query({
      query: ({ params }) => ({
        url: '/api/days-off/filter/dates/employee',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      transformResponse: (response: any) => {
        console.log('response', response);
        const content = response?.content?.map((daysOff: any) =>
          daysOffMapper(daysOff),
        );
        console.log('content', content);
        return content;
      },
      providesTags: ['AllDaysOffEmployee'],
      keepUnusedDataFor: 0,
    }),
    getAllDaysOffTypes: builder.query({
      query: () => ({
        url: '/api/days-off/type',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
    }),
    getAllUnresolvedDaysOff: builder.query({
      query: ({ params }) => ({
        url: `/api/days-off/pending/filter/dates-user`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['UnresolvedDaysOff'],
      transformResponse: (data) => {
        return unresolvedDaysOffMapper(data);
      },
    }),
    getAllDaysOffByDate: builder.query({
      query: ({ params }) => ({
        url: `/api/days-off/filter/dates`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      transformResponse: (response: any) => {
        const content = response?.content?.map((daysOff: any) =>
          daysOffMapper(daysOff),
        );
        return content;
      },
      providesTags: ['AllDaysOffByDate'],
    }),
    getFilteredDaysOffUserByType: builder.query({
      query: ({ params, selectedUser, type }) => ({
        url: `/api/days-off/filter/dates-user/${selectedUser}/${type}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      transformResponse: (response: any) => {
        const content = response?.content?.map((daysOff: any) =>
          daysOffMapper(daysOff),
        );
        const totalPages = response?.totalPages;
        return { content, totalPages };
      },
      providesTags: ['AllDaysOff'],
    }),
    getFilteredDaysOff: builder.query({
      query: ({ params, selectedUser }) => ({
        url: `/api/days-off/filter/dates-user/${selectedUser}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['FilteredDaysOff'],
    }),
    postNewDaysOff: builder.mutation({
      query: ({ body }) => ({
        url: '/api/days-off/user',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllDaysOff', 'FilteredDaysOff', 'AllDaysOffEmployee'],
    }),
    updateDayOff: builder.mutation({
      query: (body) => ({
        url: 'api/days-off/user',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllDaysOff', 'FilteredDaysOff', 'AllDaysOffEmployee'],
    }),
    deleteDaysOff: builder.mutation({
      query: (id) => ({
        url: `/api/day-off/delete/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['AllDaysOff', 'FilteredDaysOff', 'AllDaysOffEmployee'],
    }),
    changeDayOffApproval: builder.mutation({
      query: (params) => ({
        url: '/api/days-off/change-approval',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      invalidatesTags: ['UnresolvedDaysOff', 'AllDaysOffEmployee'],
    }),
    createAndChangeDayOffApproval: builder.mutation({
      query: ({ params, body }) => ({
        url: '/api/days-off/create-and-change-approval',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
      invalidatesTags: [
        'AllDaysOff',
        'FilteredDaysOff',
        'UnresolvedDaysOff',
        'AllDaysOffEmployee',
      ],
    }),
    exportDays: builder.mutation({
      query: ({ body }) => ({
        url: `/api/days-off/export`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['PostExportDays'],
    }),
    getDaysOffCalendar: builder?.query({
      query: ({ params }) => ({
        url: `/api/days-off/calendar/dates`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['DaysOffCalendar'],
    }),
    exportVacationDecision: builder.mutation({
      queryFn: async ({ dayOffId }, api, extraOptions, baseQuery) => {
        const result: any = await baseQuery({
          url: `/api/days-off/vacationpdf`,
          responseHandler: (response) => response.blob(),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
          },
          params: {
            dayOffId: dayOffId,
          },
        });

        const extension = 'pdf';

        const hiddenElement = document.createElement('a');
        const url = window.URL || window.webkitURL;
        const blobFile = url.createObjectURL(result.data as Blob);
        hiddenElement.href = blobFile;
        hiddenElement.target = '_blank';
        hiddenElement.download = `decision/${dayOffId}.${extension}`;
        hiddenElement.click();
        return { data: null };
      },
    }),
  }),
});

export const {
  useGetAllDaysOffQuery,
  useGetAllDaysOffTypesQuery,
  useGetAllUnresolvedDaysOffQuery,
  useGetFilteredDaysOffQuery,
  useGetAllDaysOffForSingleUserQuery,
  useGetFilteredDaysOffUserByTypeQuery,
  usePostNewDaysOffMutation,
  useUpdateDayOffMutation,
  useDeleteDaysOffMutation,
  useExportDaysMutation,
  useChangeDayOffApprovalMutation,
  useCreateAndChangeDayOffApprovalMutation,
  useGetAllDaysOffByDateQuery,
  useGetDaysOffCalendarQuery,
  useGetAllDaysOffEmployeeQuery,
  useExportVacationDecisionMutation,
} = daysOffApiSlice;
