import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_ROOT } from '../../../axios/axiosRequest';

export const rateApiSlice = createApi({
  reducerPath: 'rateApi',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['ratePerHour'],
  endpoints: (builder) => ({
    getRatePerHour: builder.query({
      query: ({ params }) => ({
        url: '/api/project/with-rate',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['ratePerHour'],
    }),
    updateRatePerHour: builder.mutation({
      query: ({ params }) => ({
        url: '/api/project/with-rate',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      invalidatesTags: ['ratePerHour'],
    }),
  }),
});

export const { useGetRatePerHourQuery, useUpdateRatePerHourMutation } =
  rateApiSlice;
