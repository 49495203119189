import { Box, useDisclosure } from '@chakra-ui/react';
import { ILoggedUser } from '../../data/layoutInterfaces';
import { MobileNav } from '../MobileNav/MobileNav';

type Props = {
  logo?: string;
  loggedUser: ILoggedUser;
  children: any;
  avatarLogo?: any;
};

const Header = (props: Props) => {
  const { logo, loggedUser, avatarLogo } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      display={{ base: 'none', md: 'block' }}
      h="60px"
      // zIndex="1000"
    >
      <MobileNav
        onOpen={onOpen}
        logo={logo}
        loggedUser={loggedUser}
        avatarLogo={avatarLogo}
      />
    </Box>
  );
};

export { Header };
