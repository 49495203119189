import { createSlice } from '@reduxjs/toolkit';

export interface IDashboardData {
  dashboardStats: IDashboardStats;
}

export interface IDashboardStats {
  totalEmployees: string;
  resignedEmployees: string;
  totalCandidates: string;
  totalApplicants: string;
}

export const initialState: IDashboardData = {
  dashboardStats: {
    totalEmployees: '',
    resignedEmployees: '',
    totalCandidates: '',
    totalApplicants: '',
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardStats: (state: IDashboardData, action) => {
      const { dashboardStats } = action.payload;
      state.dashboardStats = dashboardStats;
    },
  },
});

export const { setDashboardStats } = dashboardSlice.actions;

export default dashboardSlice.reducer;
