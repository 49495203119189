import { createSlice } from '@reduxjs/toolkit';
import { IEquipment } from '../../../../helpers/equipmentApiHelpers';

interface ObligationState {
  obligationForm: {
    equipmentId?: number;
    userId?: number;
    defaultUserId: number;
  };
  obligationParams: {
    name?: string;
  };
  obligationsPageable: {
    page?: number;
    size?: string | number;
    sort?: string[];
  };
}

const initialState: any = {
  obligationForm: {
    equipmentId: null,
    userId: null,
    defaultUserId: null,
  },
  obligationParams: {
    name: '',
  },
  obligationsPageable: {
    page: 0,
    size: '',
    sort: ['id', 'asc'],
  },
};

export const obligationsSlice: any = createSlice({
  name: 'obligations',
  initialState,
  reducers: {
    prefilledObligationData: (state, action) => {
      state.obligationForm = action.payload;
    },
    setAddEditObligation: (
      state: any,
      action: { payload: any; type: string },
    ) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];

      return {
        ...state,
        obligationForm: {
          ...state.obligationForm,
          [key]: value,
        },
      };
    },
    clearObligationData: (state) => {
      state.obligationForm = {
        equipmentId: null,
        userId: null,
        defaultUserId: null,
      };
    },
    setObligationPageable: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.obligationsPageable = {
        ...state.obligationsPageable,
        [key]: value,
      };
    },
    clearObligationsPageable: (state) => {
      state.obligationsPageable = {
        page: 0,
        size: '',
        sort: ['name', 'asc'],
      };
    },
    setObligationParams: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      return {
        ...state,
        obligationParams: {
          ...state.obligationParams,
          [key]: value,
        },
      };
    },
  },
});

export const {
  prefilledObligationData,
  setAddEditObligation,
  clearObligationData,
  setObligationPageable,
  clearObligationsPageable,
  setObligationParams,
} = obligationsSlice.actions;

export default obligationsSlice.reducer;
