import '@fontsource/montserrat';
import '@fontsource/roboto';
import '@fontsource/kanit';

import { ButtonStyles as Button } from './componentsStyles/ButtonStyles';
import { InputStyles as Input } from './componentsStyles/InputStyles';
import { TextStyles as Text } from './componentsStyles/TextStyles';
import { theme as chakraTheme } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: '#292e47',
    primaryLight: '#FFFFFF',
    primaryLighter: '#ECEFF1',
    borderFormColor: '#DEDEDE',

    blue: {
      400: '#44B8FF',
      500: '#009DE2',
    },
    grey: {
      100: '#F9F9F9',
      200: '#F6F6F6',
      300: '#EDEDED',
      400: '#B0B1B2',
      500: '#AAAAAA',
      600: '#54595F',
      900: '#1A1A18',
    },
    notification: {
      info: '#009DE2',
      success: '#38A169',
      warning: '#DD6B20',
      danger: '#E53E3E',
      error: '#E53E3E',
    },

    // primary: "#3A8891",
    // primaryLight: "#75acb2",
    // primaryLighter: "#ccd4fc",
    // primaryDark: "#5e8a8e",
    // primaryDarker: "#46676b",
    // secondary: "#BCEAD5",
    // tertiary: "#F7F7F7",
    // primaryWhiten: "#98A8F8",
    // secondaryDarken: "#333d52",
    // secondaryHeavyDarken: "#262e3d",
    // secondaryWhiten: "#667085",
    // secondaryHeavyWhiten: "#8c94a3",

    primaryDark: '#1A1A18',
    primaryDarker: '#46676b',
    secondary: '#B0B1B2',
    tertiary: '#F7F7F7',
    primaryWhiten: '#98A8F8',
    secondaryDarken: '#3B4851',
    secondaryHeavyDarken: '#262e3d',
    secondaryWhiten: '#292e47',
    secondaryHeavyWhiten: '#8c94a3',

    darkGrey: {
      600: '#3A3B3E',
    },

    atesBlueColor: '#009DE2',
    atesWhiteColor: '#F6F6F6',
    atesWhiteClearColor: '#FFFFFF',
  },
  fonts: {
    ...chakraTheme.fonts,
    heading: `Kanit, Montserrat, Roboto, sans-serif, Segoe UI`,
    body: `Kanit, Montserrat, Roboto, sans-serif, Segoe UI`,
  },
  components: {
    Input,
    Button,
    Text,
  },
});

export { theme };
