export interface IEquipment {
  id?: number;
  code?: string;
  description?: string;
  type?: string;
  message?: string;
  startDate?: string;
  endDate?: string;
  user?: any;
}

export interface IObligation {
  equipmentId?: number;
  userId?: string;
  dateFrom?: string;
  dateTo?: string;
  comment?: string;
}

export const equipmentContentMapper = (equipment: IEquipment) => {
  const userName = equipment?.user
    ? equipment?.user?.firstName + ' ' + equipment?.user?.lastName
    : '';
  return {
    id: equipment?.id,
    code: equipment?.code,
    description: equipment?.description,
    type: equipment?.type,
    message: equipment?.message,
    startDate: equipment?.startDate,
    endDate: equipment?.endDate,
    user: equipment?.user || {},
    userName,
  };
};

export const allEquipmentMapper = (
  content?: IEquipment[],
  totalPages?: number,
  number?: number,
) => {
  return { content, totalPages, number };
};
