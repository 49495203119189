import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { Provider } from 'react-redux';
import { store } from './common/lib/redux/store';
import { AppProvider } from './common/lib/AppProvider';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { I18nProvider } from './common/lib/i18n/i18nProvider';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './common/lib/chakraUI/theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AppProvider components={[BrowserRouter, ErrorBoundary, I18nProvider]}>
      <ChakraProvider resetCSS theme={theme}>
        <Provider store={store}>
          <React.Suspense>
            <App />
          </React.Suspense>
        </Provider>
      </ChakraProvider>
    </AppProvider>
  </React.StrictMode>,
);
