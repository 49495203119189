import { useEffect, useState } from 'react';
import {
  Box,
  Input,
  Avatar,
  Text,
  Flex,
  useToast,
  Spinner,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { setPostImagesUpload } from '../../../../common/lib/redux/slices/postsSlice';
import {
  convertToBase64,
  getBase64FileEncodedContent,
} from '../../../../helpers/formattingHelperFunctions';
import { styleProps } from './styleProps';
import { useSelector, useDispatch } from 'react-redux';
import { BiAddToQueue } from 'react-icons/bi';
import { PagesStyles } from '../../../../common/lib/chakraUI/baseStyles/PagesStyles';
import { FormattedMessage } from 'react-intl';
import customNotification from '../../../../components/Notifications/fn/customNotification';

const PostImage = () => {
  const { postImages, postUploadImages } = useSelector(
    (state: any) => state.posts,
  );

  const [imagesPreview, setImagesPreview] = useState<any>(null);
  const [selectedImages, setSelectedImages] = useState<any>([]);

  const toast = useToast();

  const dispatch = useDispatch();

  const supportedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

  const handleChangePhoto = async (e: any) => {
    const images = e.target.files;
    if (
      Object.values(images).find((image: any) => image.size > 5 * 1024 * 1024)
    ) {
      customNotification(
        toast,
        'error',
        'File size too big. Please upload a file that is 5MB or smaller.',
      );
      return;
    }

    const imageArray = Object.values(images);
    const hasUnsupportedImage = imageArray?.some(
      (image: any) => !supportedImageTypes?.includes(image.type),
    );

    if (hasUnsupportedImage) {
      customNotification(toast, 'error', 'Unsupported file type.');
      return;
    }
    setSelectedImages((prevSelectedImages: any) => [
      ...prevSelectedImages,
      ...images,
    ]);

    let imagesForUpload: any = [];
    if (images?.length) {
      for (let i = 0; i < images.length; i++) {
        const base64 = await convertToBase64(images[i]);
        const encodedContent = getBase64FileEncodedContent(base64);

        const encodedNewImage = {
          encodedContent,
          contentType: images[i].type,
          name: images[i].name,
        };

        imagesForUpload.push(encodedNewImage);
        dispatch(
          setPostImagesUpload([...postUploadImages, ...imagesForUpload]),
        );
      }
    }
  };

  const removeImage = (indexToRemove: number) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages.splice(indexToRemove, 1);
    setSelectedImages(newSelectedImages);

    if (postImages.length && indexToRemove <= postImages.length) {
      const newPostImagesUpload = [...postUploadImages];
      newPostImagesUpload.splice(indexToRemove, 1);
      dispatch(setPostImagesUpload(newPostImagesUpload));
    } else if (!postImages.length && indexToRemove <= postUploadImages.length) {
      const newPostImagesUpload = [...postUploadImages];
      newPostImagesUpload.splice(indexToRemove, 1);
      dispatch(setPostImagesUpload(newPostImagesUpload));
    }

    const newImagesPreview = imagesPreview.filter(
      (_: any, index: number) => index !== indexToRemove,
    );
    setImagesPreview(newImagesPreview);
  };

  useEffect(() => {
    let objectUrl: string;

    if (selectedImages && selectedImages.length) {
      const arrOfImages = Object.values(selectedImages).map((image: any) => {
        return (objectUrl = URL.createObjectURL(image));
      });

      setImagesPreview(arrOfImages);
    }

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImages]);

  useEffect(() => {
    if (postImages.length === 0) {
      setImagesPreview(null);
    }
  }, [postImages]);

  const { formRow, formLabelBox, formLabel } = PagesStyles;

  return (
    <Box {...styleProps.pageContent}>
      <Box
        {...formRow}
        h="auto !important"
        pt="20px"
        borderBottom="none !important"
      >
        <Box {...formLabelBox}>
          <Text {...formLabel}>
            <FormattedMessage id={'SELECT_IMAGES'} />
          </Text>
        </Box>
        <Box cursor="pointer">
          <Box
            display="flex"
            alignItems="center"
            justifyContent={imagesPreview?.length ? 'space-between' : 'center'}
            w="100%"
          >
            <Flex wrap="wrap" gap="12px 12px" maxW="312px">
              {imagesPreview?.length &&
                imagesPreview?.map((image: any, index: number) => (
                  <div key={index} style={{ position: 'relative' }}>
                    <Avatar
                      {...styleProps.avatar}
                      name="Image"
                      backgroundColor="white"
                      src={image}
                      cursor="pointer"
                      position="relative"
                    />
                    <IconButton
                      icon={<CloseIcon />}
                      size="sm"
                      aria-label="Remove Image"
                      position="absolute"
                      top="-5"
                      right="-5"
                      bg="transparent"
                      _hover={{ bg: 'transparent', color: 'red' }}
                      onClick={() => removeImage(index)}
                    />
                  </div>
                ))}
            </Flex>

            <Box position="relative" maxW="50px">
              <Input
                multiple
                type="file"
                onChange={handleChangePhoto}
                {...styleProps.input}
              />
              <Box position="absolute" {...styleProps.iconWrapper}>
                <BiAddToQueue size={42} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PostImage;
