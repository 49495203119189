import { Image, Flex, Box, IconButton, useToast } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPostByIdQuery } from '../../../../common/lib/redux/slices/apiSlices/postsApiSlice';
import {
  clearImageIdToDelete,
  clearPostImages,
  clearPostImagesUpload,
  setImageIdToDelete,
  setPostImages,
} from '../../../../common/lib/redux/slices/postsSlice';

interface ImagesModalProps {
  id?: number | null;
}

const AddPostImage = ({ id }: ImagesModalProps) => {
  const { postImages, imageIdToDelete } = useSelector(
    (state: any) => state.posts,
  );

  const { data: post, isFetching } = useGetPostByIdQuery({
    id: id,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const findPostImages = post?.assets;
      const imagesToAdd = [];

      for (let i = 0; i < findPostImages?.length; i++) {
        const encodedImage = {
          encodedContent: findPostImages[i].content,
          contentType: findPostImages[i].contentType,
          name: findPostImages[i].fileName,
          assetId: findPostImages[i].assetId,
        };
        imagesToAdd.push(encodedImage);
      }

      if (imagesToAdd.length > 0) {
        dispatch(setPostImages(imagesToAdd));
      }
    }

    return () => {
      dispatch(clearPostImages());
      dispatch(clearPostImagesUpload());
      dispatch(clearImageIdToDelete());
    };
  }, [id, isFetching]);

  const handleRemoveImage = (assetId: number) => {
    const updatedPostImages = postImages.filter(
      (image: any) => image.assetId !== assetId,
    );

    dispatch(setPostImages(updatedPostImages));
    dispatch(setImageIdToDelete([...imageIdToDelete, assetId]));
  };
  return (
    <>
      <Box>
        <Flex wrap="wrap" gap="12px 12px">
          {postImages?.map((image: any, index: any) => (
            <Box key={index} position="relative">
              <Image
                src={`data:image/png;base64,${image?.encodedContent}`}
                alt="image"
                w="75px"
                h="75px"
              />
              <IconButton
                icon={<CloseIcon />}
                size="sm"
                aria-label="Remove Image"
                position="absolute"
                top="-5"
                right="-5"
                bg="transparent"
                _hover={{ bg: 'transparent', color: 'red' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleRemoveImage(image?.assetId);
                }}
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </>
  );
};

export default AddPostImage;
