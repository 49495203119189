import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_ROOT } from '../../../axios/axiosRequest';
import { postsDataMapper } from '../../../../../helpers/postsApiHelpers';
import { PostThumbnailType } from '../../../../../types/postsType';

export const postsApiSlice = createApi({
  reducerPath: 'apiPosts',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['Posts', 'Comments', 'PostById', 'PollPosts'],
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: ({ params, body, thumbnail }) => ({
        url: `/api/posts/${thumbnail}`,
        method: 'POST',
        headers: {
          Authorizaton: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
      transformResponse: (data: any) => {
        const mappedPostsData = data?.content?.map((post: PostThumbnailType) =>
          postsDataMapper(post),
        );
        return { content: mappedPostsData, totalPages: data?.totalPages };
      },
      providesTags: ['Posts'],
    }),
    getPostById: builder.query({
      query: ({ id }) => ({
        url: `/api/posts/${id}`,
        method: 'GET',
        headers: {
          Authorizaton: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['PostById'],
    }),
    createPost: builder.mutation({
      query: (data) => ({
        url: '/api/post',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body: data,
      }),
      invalidatesTags: ['Posts', 'PostById'],
    }),
    updatePost: builder.mutation({
      query: (data) => ({
        url: 'api/post',
        method: 'PUT',
        headers: {
          Authorizaiton: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body: data,
      }),
      invalidatesTags: ['Posts', 'PostById', 'PollPosts'],
    }),
    deletePost: builder.mutation({
      query: (id) => ({
        url: `api/posts/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['Posts'],
    }),
    archivePost: builder.mutation({
      query: ({ id }) => ({
        url: `/api/posts/${id}/archive`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['Posts', 'PostById'],
    }),
    createPostImage: builder.mutation({
      query: ({ body, id }) => ({
        url: `/api/posts/${id}/images`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['PostById'],
    }),
    deletePostImage: builder.mutation({
      query: ({ id, assets }) => ({
        url: `/api/posts/${id}/images/${assets}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['PostById'],
    }),
    getPostComment: builder.query({
      query: ({ id }) => ({
        url: `/api/post/comments/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['Comments', 'PostById'],
    }),
    addPostComment: builder.mutation({
      query: ({ body }) => ({
        url: `/api/post/comment`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['Comments', 'PostById'],
    }),
    editPostComment: builder.mutation({
      query: ({ body }) => ({
        url: `/api/post/comment`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['Comments', 'PostById'],
    }),
    deletePostComment: builder.mutation({
      query: ({ id }) => ({
        url: `/api/post/comment/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['Comments', 'PostById'],
    }),
    getListOfUsersVotedByOptionId: builder.query({
      query: ({ optionId }) => ({
        url: `/api/option/${optionId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
    }),
    getPollData: builder.query({
      query: ({ id, userId }) => ({
        url: `/api/posts/${id}/${userId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['PollPosts'],
    }),
    votePoll: builder.mutation({
      query: ({ userId, optionId }) => ({
        url: `/api/vote/${userId}/${optionId}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['PostById', 'PollPosts'],
    }),
    editPoll: builder.mutation({
      query: ({ userId, oldOptionId, newOptionId }) => ({
        url: `/api/vote/${userId}/${oldOptionId}/${newOptionId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['PostById', 'PollPosts'],
    }),
    deletePollOption: builder.mutation({
      query: ({ optionId }) => ({
        url: `/api/option/${optionId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['PostById', 'PollPosts'],
    }),
  }),
});

export const {
  useGetPostsQuery,
  useGetPostByIdQuery,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
  useCreatePostImageMutation,
  useArchivePostMutation,
  useDeletePostImageMutation,
  useGetPostCommentQuery,
  useGetListOfUsersVotedByOptionIdQuery,
  useGetPollDataQuery,
  useAddPostCommentMutation,
  useDeletePostCommentMutation,
  useEditPostCommentMutation,
  useVotePollMutation,
  useEditPollMutation,
  useDeletePollOptionMutation,
} = postsApiSlice;
