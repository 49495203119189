import * as React from 'react';
import { Layout } from '../components/Layout/Layout';
import logo from '../assets/brand/ates-logo-new.png';
import { loggedUserMapper } from '../components/Layout/helpers/layoutHelpers';
import { useLoggedUser } from '../common/hooks/useLoggedUser';
import { useSelector } from 'react-redux';
import { RootState } from '../common/lib/redux/store';

const ProtectedPages = ({ element }: any) => {
  const loggedUser = loggedUserMapper(useLoggedUser());
  const { profileImage } = useSelector((state: RootState) => state.profile);

  const { Header, Sidebar, Content, Footer } = Layout;
  return (
    <Layout>
      <Sidebar logo={logo} loggedUser={loggedUser}></Sidebar>
      <Header loggedUser={loggedUser} logo={logo} avatarLogo={profileImage} />
      <Content>{element}</Content>
      <Footer />
    </Layout>
  );
};

export { ProtectedPages };
