import React from 'react'

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
};

const DaysOffIcon: React.FC<IconTypes> = ({height, width, stroke, fill}) => {
  return (
    <svg
    width={width ?? 20}
    height={height ?? 20}
    viewBox="0 0 20 20"
    fill={fill ?? "none"}
    stroke={stroke ?? 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >

  <path
    d="M7.41601 12.7893C7.07381 13.1384 6.51902 13.1384 6.17679 12.7893"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M11.6117 12.7893C11.2695 13.1384 10.7147 13.1384 10.3724 12.7893"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M9.59128 15.5883H10.1216"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M13.1866 3.75481H14.6207L13.1478 5.93353H14.6827"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M17.1013 1H18.9213L17.0521 3.76498H19"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M16.4125 10.982C15.8422 9.73159 14.5992 8.86446 13.1572 8.86446C12.6147 8.86446 12.1007 8.98789 11.6397 9.20754C10.8103 7.76007 9.27112 6.78687 7.50795 6.78687C4.86653 6.78687 2.72525 8.96949 2.72525 11.6619C2.72525 12.027 2.76591 12.3823 2.84058 12.7246C1.752 13.2558 1 14.389 1 15.7014C1 17.5232 2.44889 19 4.23615 19H15.5903C17.3775 19 18.8264 17.5232 18.8264 15.7014C18.8264 15.2035 18.7177 14.7318 18.5239 14.3086"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>


  

  )
}

export default DaysOffIcon