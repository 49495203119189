import * as React from 'react';
import {
  Textarea,
  HStack,
  InputRightElement,
  InputGroup,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { IntlShape } from 'react-intl';
import { PagesStyles } from '../../../../common/lib/chakraUI/baseStyles/PagesStyles';
import { FaArrowUp, FaRegSmile } from 'react-icons/fa';
import { useKeyPress } from '../../../../common/hooks/useKeyPress';
import EmojiPicker from 'emoji-picker-react';

interface CommentInputProps {
  onSubmit: (comment: string) => void;
  intl: IntlShape;
}

const CommentInput: React.FC<CommentInputProps> = ({ onSubmit, intl }) => {
  const [newComment, setNewComment] = React.useState('');
  const [isOpenEmojiWindow, setIsOpenEmojiWindow] = React.useState(false);

  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleCommentSubmit = () => {
    if (newComment.trim() !== '') {
      onSubmit(newComment);
      setNewComment('');
    }
    setIsOpenEmojiWindow(false);
  };

  const autoGrow = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '5px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleCommentChange =
    () => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setNewComment(value);
    };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      console.error('Enter pressed without Shift');
    }
  };

  useKeyPress('Enter', handleCommentSubmit);

  const handleEmojiClick = (emoji: any) => {
    let newValue = newComment + emoji.emoji;
    setNewComment(newValue);
  };

  const toggleEmojiPicker = () => {
    setIsOpenEmojiWindow(!isOpenEmojiWindow);
  };

  return (
    <HStack>
      <InputGroup>
        <Textarea
          {...PagesStyles.formInput}
          ref={textareaRef}
          onKeyDown={handleKeyDown}
          placeholder={intl.formatMessage({ id: 'COMMENT.ADD' })}
          value={newComment}
          onInput={autoGrow}
          resize="none"
          overflow="auto"
          minHeight="56px"
          maxHeight="100px"
          pr="50px !important"
          onChange={handleCommentChange()}
        />
        <InputRightElement mr="25px" h="100%">
          <Box width="10px" height="10px" position="relative">
            {isOpenEmojiWindow && (
              <EmojiPicker
                onEmojiClick={handleEmojiClick}
                open={isOpenEmojiWindow}
                style={{ position: 'absolute', bottom: '10px', right: '0' }}
                lazyLoadEmojis
              />
            )}
          </Box>

          <IconButton
            isRound={true}
            aria-label="comment"
            colorScheme="blue"
            size="sm"
            marginRight="5px"
          >
            <FaRegSmile onClick={toggleEmojiPicker} />
          </IconButton>
          <IconButton
            isRound={true}
            aria-label="comment"
            onClick={handleCommentSubmit}
            colorScheme="blue"
            size="sm"
          >
            <FaArrowUp />
          </IconButton>
        </InputRightElement>
      </InputGroup>
    </HStack>
  );
};

export default CommentInput;
