import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_ROOT } from '../../../axios/axiosRequest';

export const postTypesApiSlice = createApi({
  reducerPath: 'apiPostType',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['PostType'],
  endpoints: (builder) => ({
    getPostTypes: builder.query({
      query: ({ params }) => ({
        url: `/api/post-types`,
        method: 'GET',
        headers: {
          Authorizaton: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      transformResponse: (response: any) => {
        return response?.content?.sort((a: any, b: any) =>
          a.type.localeCompare(b.type),
        );
      },
      providesTags: ['PostType'],
    }),
    createPostType: builder.mutation({
      query: (data) => ({
        url: '/api/post-types',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body: data,
      }),
      invalidatesTags: ['PostType'],
    }),
    updatePostType: builder.mutation({
      query: ({ data, id }) => ({
        url: `api/post-types/${id}`,
        method: 'PATCH',
        headers: {
          Authorizaiton: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body: data,
      }),
      invalidatesTags: ['PostType'],
    }),
    deletePostType: builder.mutation({
      query: ({ id }) => ({
        url: `api/post-types/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['PostType'],
    }),
  }),
});

export const {
  useGetPostTypesQuery,
  useCreatePostTypeMutation,
  useUpdatePostTypeMutation,
  useDeletePostTypeMutation,
} = postTypesApiSlice;
