import { createSlice } from '@reduxjs/toolkit';

export interface INotifications {
  notificationsPageable: {
    page: number;
    size: number;
    sort: string[];
  };
  selectedUser: {
    name: string;
    id: number | null;
  };
}

const initialState: INotifications = {
  notificationsPageable: {
    page: 0,
    size: 10,
    sort: ['asc'],
  },
  selectedUser: {
    name: '',
    id: null,
  },
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationsPageable: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      state.notificationsPageable = {
        ...state.notificationsPageable,
        [key]: value,
      };
    },
    setSelectedUser: (state: any, action: any) => {
      const { name, id } = action.payload;
      state.selectedUser = {
        name,
        id,
      };
    },
    clearNotificationPageable: (state) => {
      state.notificationsPageable = {
        page: 0,
        size: 10,
        sort: ['desc'],
      };
    },
  },
});

export const {
  setNotificationsPageable,
  setSelectedUser,
  clearNotificationPageable,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
