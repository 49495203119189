export const styleProps = {
  pageContent: {
    alignItems: "start",
    justifyContent: "center",
  },
  avatarWrapper: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    size: "xl",
  },
  input: {
    opacity: 0,
    padding: 0,
    zIndex: 2,
  },
  iconWrapper: {
    top: 0,
    left: 0,
    zIndex: 1,
  },
};
