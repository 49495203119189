import { useEffect, useState } from 'react';
import { ImageGalleryStyles } from './ImageGalleryStyles';
import {
  Image,
  Box,
  Button,
  HStack,
  VStack,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import ArrowRight from '../../assets/icons/TriangleRight.svg';
import ArrowLeft from '../../assets/icons/TriangleLeft.svg';
import { useKeyPress } from '../../common/hooks/useKeyPress';

interface IImageGallery {
  slides: any[];
  isLoading?: boolean;
  withSmallImages?: boolean;
}

interface ImageProps {
  assetId: number;
  content: string;
  contentType: string;
  fileName: string;
  message: null | string;
}

const ImageGallery = ({
  slides,
  isLoading,
  withSmallImages = false,
}: IImageGallery) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [slides]);

  const handlePreviousBtn = () => {
    const isFirstOne = currentIndex === 0;
    const prevIndex = isFirstOne ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(prevIndex);
  };

  const handleNextBtn = () => {
    const isLastOne = currentIndex === slides.length - 1;
    const nextIndex = isLastOne ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  // custom hook of event handler for keydown event
  useKeyPress('ArrowRight', handleNextBtn);
  useKeyPress('ArrowLeft', handlePreviousBtn);

  const handleChangeImage = (id: number) => {
    setCurrentIndex(id);
  };

  const {
    imageGalleryWrapper,
    displayedImageContainer,
    smallImageContainer,
    arrowButton,
    displayedImage,
    leftArrowButton,
    rightArrowButton,
    smallImage,
  } = ImageGalleryStyles;

  if (!slides.length) {
    return null;
  }

  return isLoading ? (
    <VStack marginTop="20px" justifyContent="center">
      <Spinner color="blue.500" size="xl" />
    </VStack>
  ) : (
    <Box {...imageGalleryWrapper}>
      {slides.length > 1 ? (
        <>
          <Button
            {...arrowButton}
            {...leftArrowButton}
            onClick={handlePreviousBtn}
          >
            <Image src={ArrowLeft} />
          </Button>
          <Button
            {...arrowButton}
            {...rightArrowButton}
            onClick={handleNextBtn}
          >
            <Image src={ArrowRight} />
          </Button>
        </>
      ) : (
        ''
      )}
      <VStack justifyContent="center" alignItems="center" boxSize="100%">
        <Flex {...displayedImageContainer}>
          <Image
            src={`data:${slides[currentIndex]?.contentType};base64,${slides[currentIndex]?.content}`}
            {...displayedImage}
          />
        </Flex>
        {withSmallImages ? (
          <HStack>
            {slides?.map((image: ImageProps, index: number) => (
              <Box
                {...smallImageContainer}
                onClick={() => handleChangeImage(index)}
                opacity={`${currentIndex === index ? '1' : '0.4'}`}
              >
                <Image
                  src={`data:${image?.contentType};base64,${image?.content}`}
                  alt="little image"
                  {...smallImage}
                  boxSize={`${currentIndex === index ? '100%' : '95%'}`}
                />
              </Box>
            ))}
          </HStack>
        ) : (
          ''
        )}
      </VStack>
    </Box>
  );
};

export default ImageGallery;
