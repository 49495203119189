import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IPostsState {
  addNewPost: boolean;
  addNewPostForm: {
    title: string;
    text: string;
    selectType: {
      value: string;
      id: null | number;
    };
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
    pollOptionOne: {
      optionId: number | null;
      name: string;
    };
    pollOptionTwo: {
      optionId: number | null;
      name: string;
    };
  };

  addNewPostType: {
    type: string;
    color: string;
  };
  postsPageable: {
    page: number;
    size: string | number;
    sort: string[];
  };
  postsParams: {
    title: string;
  };
  postImages:
    | [{ encodedContent: string; contentType: string; type: string }]
    | [];
  postUploadImages:
    | [{ encodedContent: string; contentType: string; type: string }]
    | [];
  imageIdToDelete: number[] | [];
  isTableLook: boolean;
  typePageable: {
    page: number | null;
    size: string | number;
    sort: string[];
  };
  postId: number | null;
  commentDeleteId: number | null;
  selectedOption: number | null;
}
const storedState = localStorage.getItem('pageableState');
const initialState: IPostsState = {
  addNewPost: false,
  addNewPostForm: {
    title: '',
    text: '',
    selectType: {
      value: '',
      id: null,
    },
    startDate: undefined,
    endDate: undefined,
    pollOptionOne: {
      optionId: null,
      name: '',
    },
    pollOptionTwo: {
      optionId: null,
      name: '',
    },
  },
  addNewPostType: {
    type: '',
    color: '#ffffff',
  },
  postsParams: {
    title: '',
  },
  postsPageable: {
    page: 0,
    size: '',
    sort: ['id', 'desc'],
  },
  postImages: [],
  postUploadImages: [],
  imageIdToDelete: [],
  isTableLook: false,
  typePageable: {
    page: 0,
    size: '',
    sort: ['', ''],
  },
  postId: null,
  commentDeleteId: null,
  selectedOption: null,
};

export const postsSlice = createSlice({
  name: 'postsApi',
  initialState,
  reducers: {
    setLayout: (state) => {
      state.isTableLook = !state.isTableLook;
    },
    setPostsPageable: (state: any, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      return {
        ...state,
        postsPageable: {
          ...state.postsPageable,
          [key]: value,
        },
      };
    },
    resetPostsPageable: (state: any) => {
      state.postsPageable = {
        page: 0,
        size: '',
        sort: ['id', 'desc'],
      };
    },
    setNewPostValues: (state: any, action: any) => {
      const { name, value } = action.payload;

      state.addNewPostForm = {
        ...state.addNewPostForm,
        [name]: value,
      };
    },
    setUpdatedOptionName: (
      state,
      action: PayloadAction<{ value: string; name: string }>,
    ) => {
      const { value, name } = action.payload;
      if (name === 'pollOptionOne') {
        state.addNewPostForm.pollOptionOne.name = value;
      } else if (name === 'pollOptionTwo') {
        state.addNewPostForm.pollOptionTwo.name = value;
      }
    },
    setPostId: (state, action) => {
      state.postId = action.payload;
    },
    setEditValues: (
      state: any,
      action: PayloadAction<IPostsState['addNewPostForm']>,
    ) => {
      const {
        endDate,
        selectType: { id: typeId, value: typeValue },
        startDate,
        text,
        title,
        pollOptionOne,
        pollOptionTwo,
      } = action.payload;
      state.addNewPostForm = {
        title: title,
        text: text,
        selectType: {
          id: typeId,
          value: typeValue,
        },
        startDate: startDate,
        endDate: endDate,
        pollOptionOne: pollOptionOne,
        pollOptionTwo: pollOptionTwo,
      };
    },
    setNewTypeValue: (state: any, action: any) => {
      state.addNewPostType = {
        ...state.addNewPostType,
        type: action.payload,
      };
    },
    setNewColorValue: (state: any, action: any) => {
      state.addNewPostType = {
        ...state.addNewPostType,
        color: action.payload,
      };
    },
    clearAddNewPostForm: (state) => {
      state.addNewPostForm = {
        title: '',
        text: '',
        selectType: {
          value: '',
          id: null,
        },
        startDate: undefined,
        endDate: undefined,
        pollOptionOne: {
          optionId: null,
          name: '',
        },
        pollOptionTwo: {
          optionId: null,
          name: '',
        },
      };
    },
    clearNewPostTypeForm: (state: any) => {
      state.addNewPostType = {
        type: '',
        color: '#ffffff',
      };
    },
    setSelectedType: (state: any, action: any) => {
      state.addNewPostForm.selectType = {
        value: action.payload.value,
        id: action.payload.postId,
      };
    },
    setPostImages: (state: any, action: any) => {
      state.postImages = action.payload;
    },
    setPostImagesUpload: (state: any, action: any) => {
      state.postUploadImages = action.payload;
    },
    setImageIdToDelete: (state, action) => {
      state.imageIdToDelete = action.payload;
    },
    clearImageIdToDelete: (state) => {
      state.imageIdToDelete = [];
    },
    clearPostImages: (state) => {
      state.postImages = [];
    },
    clearPostImagesUpload: (state) => {
      state.postUploadImages = [];
    },
    setPostsParams: (
      state: any,
      action: {
        payload: any;
        type: string;
      },
    ) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      if (key === 'title' && typeof value === 'string') {
        return {
          ...state,
          postsParams: {
            title: value,
          },
        };
      }

      return {
        ...state,
        postsParamas: {
          ...state.postsParams,
          [key]: value,
        },
      };
    },
    setCommentDeleteId: (state, action) => {
      state.commentDeleteId = action.payload;
    },
    selectOption: (state, action: PayloadAction<number>) => {
      state.selectedOption = action.payload;
    },
    clearSelectedOption: (state) => {
      state.selectedOption = null;
    },
  },
});

export const {
  setPostsPageable,
  resetPostsPageable,
  setPostImages,
  clearPostImages,
  setImageIdToDelete,
  clearImageIdToDelete,
  clearPostImagesUpload,
  setPostId,
  setNewPostValues,
  setUpdatedOptionName,
  setNewTypeValue,
  setNewColorValue,
  setPostImagesUpload,
  clearAddNewPostForm,
  clearNewPostTypeForm,
  setPostsParams,
  setSelectedType,
  setEditValues,
  setCommentDeleteId,
  selectOption,
  clearSelectedOption,
  setLayout,
} = postsSlice.actions;
export default postsSlice.reducer;
