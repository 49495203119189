import React from 'react';
import { HStack, Link } from '@chakra-ui/react';
import { IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../common/lib/redux/store';
import { useWhatRole } from '../../../../common/hooks/useWhatRole';
import { ICommentData } from './CommentItem';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

interface CommentActionsProps {
  userId: number;
  onEdit: () => void;
  onDelete: () => void;
  intl: IntlShape;
  comment: ICommentData;
}

const CommentActions: React.FC<CommentActionsProps> = ({
  userId,
  onEdit,
  onDelete,
  intl,
  comment,
}) => {
  const { id: loggedInUserId } = useSelector((store: RootState) => store.user);
  const role = useWhatRole();
  return (
    <HStack>
      {loggedInUserId === userId && (
        <Link
          fontSize="14px"
          onClick={onEdit}
          color="gray.500"
          _hover={{ color: 'gray.700' }}
        >
          <FaRegEdit />
        </Link>
      )}
      {(loggedInUserId === userId ||
        role.toLowerCase() === 'admin' ||
        (role.toLowerCase() === 'hr' &&
          comment.role.name.toLowerCase() === 'employee')) && (
        <Link
          fontSize="14px"
          onClick={onDelete}
          color="red.500"
          _hover={{ color: 'red.700' }}
        >
          <FaRegTrashAlt />
        </Link>
      )}
    </HStack>
  );
};

export default CommentActions;
