import React from 'react'

interface IconTypes {
    height?: number | string;
    width?: number | string;
    stroke?: string;
    fill?: string;
};

const CalendarIcon: React.FC<IconTypes> = ({height, width, stroke, fill}) => {
  return (
    <svg
    width={width ?? 20}
    height={height ?? 20}
    viewBox="0 0 20 20"
    fill={fill ?? "none"}
    stroke={stroke ?? 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
    
  <path
    d="M19 16.7119C19 17.9756 17.9756 19 16.7119 19H3.28814C2.02444 19 1 17.9756 1 16.7119V4.81356C1 3.54986 2.02444 2.52542 3.28814 2.52542H16.7119C17.9756 2.52542 19 3.54986 19 4.81356V12.8983"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M5.00423 8.14973C4.95117 8.14973 4.90818 8.19272 4.90818 8.24577C4.90818 8.29883 4.95117 8.34182 5.00423 8.34182C5.05728 8.34182 5.10027 8.29883 5.10027 8.24577C5.10027 8.19272 5.05728 8.14973 5.00423 8.14973Z"
    strokeWidth="1.33333"
  />
  <path
    d="M8.32204 8.14973C8.26898 8.14973 8.22599 8.19272 8.22599 8.24577C8.22599 8.29883 8.26898 8.34182 8.32204 8.34182C8.37509 8.34182 8.41808 8.29883 8.41808 8.24577C8.41808 8.19272 8.37509 8.14973 8.32204 8.14973Z"
    strokeWidth="1.33333"
  />
  <path
    d="M11.6398 8.14973C11.5868 8.14973 11.5438 8.19272 11.5438 8.24577C11.5438 8.29883 11.5868 8.34182 11.6398 8.34182C11.6929 8.34182 11.7359 8.29883 11.7359 8.24577C11.7359 8.19272 11.6929 8.14973 11.6398 8.14973Z"
    strokeWidth="1.33333"
  />
  <path
    d="M14.9576 8.14973C14.9046 8.14973 14.8616 8.19272 14.8616 8.24577C14.8616 8.29883 14.9046 8.34182 14.9576 8.34182C15.0107 8.34182 15.0537 8.29883 15.0537 8.24577C15.0537 8.19272 15.0107 8.14973 14.9576 8.14973Z"
    strokeWidth="1.33333"
  />
  <path
    d="M5.10027 11.5636C5.10027 11.5105 5.05728 11.4675 5.00423 11.4675C4.95117 11.4675 4.90818 11.5105 4.90818 11.5636C4.90818 11.6166 4.95117 11.6596 5.00423 11.6596C5.05728 11.6596 5.10027 11.6166 5.10027 11.5636Z"
    strokeWidth="1.33333"
  />
  <path
    d="M5.10027 14.8814C5.10027 14.8283 5.05728 14.7853 5.00423 14.7853C4.95117 14.7853 4.90818 14.8283 4.90818 14.8814C4.90818 14.9344 4.95117 14.9774 5.00423 14.9774C5.05728 14.9774 5.10027 14.9344 5.10027 14.8814Z"
    strokeWidth="1.33333"
  />
  <path
    d="M8.41808 11.5636C8.41808 11.5105 8.37509 11.4675 8.32204 11.4675C8.26898 11.4675 8.22599 11.5105 8.22599 11.5636C8.22599 11.6166 8.26898 11.6596 8.32204 11.6596C8.37509 11.6596 8.41808 11.6166 8.41808 11.5636Z"
    strokeWidth="1.33333"
  />
  <path
    d="M8.41808 14.8814C8.41808 14.8283 8.37509 14.7853 8.32204 14.7853C8.26898 14.7853 8.22599 14.8283 8.22599 14.8814C8.22599 14.9344 8.26898 14.9774 8.32204 14.9774C8.37509 14.9774 8.41808 14.9344 8.41808 14.8814Z"
    strokeWidth="1.33333"
  />
  <path
    d="M8.32204 8.14973C8.26898 8.14973 8.22599 8.19272 8.22599 8.24577C8.22599 8.29883 8.26898 8.34182 8.32204 8.34182C8.37509 8.34182 8.41808 8.29883 8.41808 8.24577C8.41808 8.19272 8.37509 8.14973 8.32204 8.14973Z"
    strokeWidth="1.33333"
  />
  <path
    d="M8.41808 11.5636C8.41808 11.5105 8.37509 11.4675 8.32204 11.4675C8.26898 11.4675 8.22599 11.5105 8.22599 11.5636C8.22599 11.6166 8.26898 11.6596 8.32204 11.6596C8.37509 11.6596 8.41808 11.6166 8.41808 11.5636Z"
    strokeWidth="1.33333"
  />
  <path
    d="M8.41808 14.8814C8.41808 14.8283 8.37509 14.7853 8.32204 14.7853C8.26898 14.7853 8.22599 14.8283 8.22599 14.8814C8.22599 14.9344 8.26898 14.9774 8.32204 14.9774C8.37509 14.9774 8.41808 14.9344 8.41808 14.8814Z"
    strokeWidth="1.33333"
  />
  <path
    d="M5.00424 0.999995V4.05084"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M14.9576 4.05084V0.999995"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M9.96185 0.999995V4.05084"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M13.3062 12.3146L13.3058 12.3133L13.2415 12.115L13.1772 12.3133L13.1769 12.3144C13.0004 12.8554 12.4978 12.9929 12.1842 12.9929H11.931L12.1467 13.1523C12.5103 13.4176 12.6636 13.8871 12.5234 14.3169C12.5234 14.3169 12.5233 14.3169 12.5233 14.3169L12.4607 14.509L12.6289 14.3873L12.6291 14.3872C12.8119 14.2549 13.0272 14.189 13.2415 14.189C13.4557 14.189 13.6711 14.2549 13.854 14.3872L13.8542 14.3873L14.0224 14.509L13.9597 14.3169C13.9597 14.3169 13.9597 14.3169 13.9597 14.3169C13.8194 13.887 13.9728 13.4173 14.3367 13.1521L14.5525 12.9929H14.2989C13.9851 12.9929 13.4828 12.8554 13.3062 12.3146ZM14.2992 15.3574L14.2992 15.3574L14.2992 15.3574Z"
    strokeWidth="1.33333"
  />
</svg>

  

  )
}

export default CalendarIcon