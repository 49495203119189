import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_ROOT } from '../../../axios/axiosRequest';
import {
  allEquipmentMapper,
  equipmentContentMapper,
} from '../../../../../helpers/equipmentApiHelpers';

export const equipmentApiSlice = createApi({
  reducerPath: 'equipmentSlice',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['AllEquipment'],
  endpoints: (builder) => ({
    getAllEquipment: builder.query({
      query: ({ params, body }) => ({
        url: '/api/all-equipments',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
      transformResponse: (response: any) => {
        const content = response?.content?.map((equipment: any) =>
          equipmentContentMapper(equipment),
        );
        return allEquipmentMapper(
          content,
          response?.totalPages,
          response?.number,
        );
      },
      providesTags: ['AllEquipment'],
    }),
    postEquipment: builder.mutation({
      query: (body) => ({
        url: `/api/equipments`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllEquipment'],
    }),
    updateEquipment: builder.mutation({
      query: ({ body, id }) => ({
        url: `api/equipments/${id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllEquipment'],
    }),
    deleteEquipment: builder.mutation({
      query: (id) => ({
        url: `api/equipments/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['AllEquipment'],
    }),
    getEquipmentTypes: builder.query({
      query: ({}) => ({
        url: `api/equipments/types`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllEquipmentQuery,
  usePostEquipmentMutation,
  useUpdateEquipmentMutation,
  useDeleteEquipmentMutation,
  useGetEquipmentTypesQuery,
} = equipmentApiSlice;
