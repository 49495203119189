import { createSlice } from '@reduxjs/toolkit';

export interface IEmployeesParams {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  vacationDays: number;
  birthDate: string;
  company: string;
  seniority: string;
  position: string;
}

export interface IEmployeesPageable {
  page: number;
  size: string | number;
  sort: string[];
}

interface IEmployeeInitialState {
  employeesParams: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    vacationDays: string;
    prevYearDays: string;
    birthDate: Date | null | undefined;
    company: string;
  };
  employeesPageable: {
    page: number;
    size: string;
    sort: string[];
  };
  addEmployeeField: boolean;
  assignMode: boolean;
  addEditEmployee: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    vacationDays: string;
    usedVacationDays: string;
    prevYearDays: string;
    sickDays: string;
    paidDays: string;
    birthDate: Date | null | undefined;
    company: string;
    seniority: string;
    position: string;
    technologyName: string;
    roleName: string;
    dateOfEmployment: Date | null | undefined;
    endOfEmploymentDate: Date | null | undefined;
    about: string;
    isRemote?: boolean;
    isRemoteTemp?: boolean;
    address: string;
    comment: string;
    tshirtSize: string;
    familySaintDay: string;
    familySaintDayDate: string;
    clientName: string;
    linkedin: string;
    techNames: string[];
    archived: boolean;
    environmentStatus: boolean;
    withWorklog: boolean;
    bonusDays: number | null | undefined;
  };
  inputSearch: string;
  employeeStatus: string;
  changeRoleUserId: number | string | undefined;
  isTableLook: boolean;
  currentKeyLocation: string;
  projectId: number | null;
  activeEditProject: {
    id: number | undefined;
    name: string;
    startDate: undefined | string | Date;
    endDate: undefined | string | Date;
  };
}

export const initialState: IEmployeeInitialState = {
  employeesParams: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    vacationDays: '',
    prevYearDays: '',
    birthDate: undefined,
    company: '',
  },
  employeesPageable: {
    page: 0,
    size: '',
    sort: ['firstName', 'asc'],
  },
  assignMode: true,
  addEmployeeField: false,
  addEditEmployee: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    vacationDays: '',
    usedVacationDays: '',
    prevYearDays: '',
    sickDays: '',
    paidDays: '',
    birthDate: undefined,
    company: '',
    seniority: '',
    position: '',
    technologyName: '',
    roleName: '',
    dateOfEmployment: undefined,
    endOfEmploymentDate: null,
    about: '',
    isRemote: false,
    isRemoteTemp: false,
    address: '',
    comment: '',
    familySaintDay: '',
    familySaintDayDate: '',
    tshirtSize: '',
    clientName: '',
    linkedin: '',
    techNames: [''],
    archived: false,
    environmentStatus: false,
    withWorklog: false,
    bonusDays: 0,
  },
  inputSearch: '',
  employeeStatus: 'active',
  changeRoleUserId: undefined,
  isTableLook: false,
  currentKeyLocation: '',
  projectId: null,
  activeEditProject: {
    id: undefined,
    name: '',
    startDate: undefined,
    endDate: undefined,
  },
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setCurrentKeyLocation: (state: IEmployeeInitialState, action: any) => {
      state.currentKeyLocation = action.payload;
    },
    clearCurrentKeyLocation: (state: IEmployeeInitialState) => {
      state.currentKeyLocation = '';
    },
    setInputSearch: (state, action) => {
      state.inputSearch = action.payload;
    },
    clearInputSearch: (state) => {
      state.inputSearch = '';
    },
    setLayout: (state) => {
      state.isTableLook = !state.isTableLook;
    },
    setEmployeeStatus: (state, action) => {
      state.employeeStatus = action.payload;
    },
    setChangeRoleId: (state, action) => {
      state.changeRoleUserId = action.payload;
    },
    setAssignMode: (state, action) => {
      state.assignMode = action.payload;
    },
    setEmployeesParams: (
      state: any,
      action: {
        payload: any;
        type: string;
      },
    ) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];
      if (key === 'fullName' && typeof value === 'string') {
        return {
          ...state,
          employeesParams: {
            ...state.employeesParams,
            firstName: value?.split(' ')[0],
            lastName: value?.split(' ')[1],
          },
        };
      }
      return {
        ...state,
        employeesParams: {
          ...state.employeesParams,
          [key]: value,
        },
      };
    },
    setEmployeesPageable: (
      state: any,
      action: {
        payload: number | string | string[];
        type: string;
      },
    ) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];
      state.employeesPageable = {
        ...state.employeesPageable,
        [key]: value,
      };
    },
    toggleAddEmployee: (
      state: any,
      action: {
        payload: any;
        type: any;
      },
    ) => {
      return {
        ...state,
        addEmployeeField: action.payload,
      };
    },
    setAddEditEmployee: (
      state: any,
      action: { payload: any; type: string },
    ) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];
      return {
        ...state,
        addEditEmployee: {
          ...state.addEditEmployee,
          [key]: value,
        },
      };
    },
    setEmployeeInput: (state: any, action: any) => {
      const { name, value } = action.payload;

      state.addEditEmployee = {
        ...state.addEditEmployee,
        [name]: value,
      };
    },
    setEditEmployeeInputs: (state: any, action: any) => {
      state.addEditEmployee = {
        ...state.addEditEmployee,
        ...action.payload,
      };
    },
    clearEmployeeInput: (state: any) => {
      state.addEditEmployee = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        vacationDays: '',
        usedVacationDays: '',
        birthDate: undefined,
        company: '',
        seniority: '',
        position: '',
        technologyName: '',
        roleName: '',
        dateOfEmployment: undefined,
        endOfEmploymentDate: null,
        about: '',
        isRemote: false,
        isRemoteTemp: false,
        address: '',
        comment: '',
        clientName: '',
        familySaintDay: '',
        familySaintDayDate: '',
        tshirtSize: '',
        linkedin: '',
        archived: '',
        environmentStatus: false,
        withWorklog: false,
        bonusDays: 0,
      };
    },
    clearEmployeePageable: (state: any) => {
      state.employeesPageable = {
        page: 0,
        size: '',
        sort: ['firstName', 'asc'],
      };
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    toggleCleanEmployee: (state: any) => {
      return {
        ...state,
        addEditEmployee: initialState.addEditEmployee,
      };
    },
    setEditEmployeeActiveProjectDate: (state, action) => {
      const { name, value } = action.payload;
      state.activeEditProject = {
        ...state.activeEditProject,
        [name]: value,
      };
    },
    setActiveEditProject: (state, action) => {
      const { id, name, startDate, endDate } = action.payload;
      state.activeEditProject = {
        id,
        name,
        startDate,
        endDate,
      };
    },
  },
});

export const {
  setInputSearch,
  clearInputSearch,
  setEmployeesParams,
  setCurrentKeyLocation,
  clearCurrentKeyLocation,
  setEmployeesPageable,
  toggleAddEmployee,
  setAddEditEmployee,
  setEmployeeInput,
  setAssignMode,
  toggleCleanEmployee,
  setEditEmployeeInputs,
  clearEmployeeInput,
  clearEmployeePageable,
  setEmployeeStatus,
  setLayout,
  setChangeRoleId,
  setProjectId,
  setEditEmployeeActiveProjectDate,
  setActiveEditProject,
} = employeesSlice.actions;

export default employeesSlice.reducer;
