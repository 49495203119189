import { createSlice } from '@reduxjs/toolkit';
import { IEquipment } from '../../../../helpers/equipmentApiHelpers';

interface EquipmentState {
  equipmentForm: {
    name?: string;
    type?: string;
    code?: string;
    id?: null | number;
    description?: string;
  };
  equipmentParams: {
    name?: string;
  };
  equipmnetPageable: {
    page?: number;
    size?: string | number;
    sort?: string[];
  };
}

const initialState: any = {
  equipmentForm: {
    name: '',
    type: '',
    code: '',
    id: null,
    description: '',
  },
  equipmentParams: {
    name: '',
  },
  equipmnetsPageable: {
    page: 0,
    size: '',
    sort: ['id', 'asc'],
  },
};

export const equipmentSlice: any = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    prefilledEquipmentData: (state, action) => {
      state.equipmentForm = action.payload;
    },
    setAddEditEquipment: (
      state: any,
      action: { payload: any; type: string },
    ) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];

      return {
        ...state,
        equipmentForm: {
          ...state.equipmentForm,
          [key]: value,
        },
      };
    },
    clearEquimpentData: (state) => {
      state.equipmentForm = {
        name: '',
        type: '',
        code: '',
        id: null,
        description: '',
      };
    },
    setEquipmentPageable: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.equipmnetsPageable = {
        ...state.equipmnetsPageable,
        [key]: value,
      };
    },
    clearEquipmentPageable: (state) => {
      state.equipmnetsPageable = {
        page: 0,
        size: '',
        sort: ['name', 'asc'],
      };
    },
    setEquipmentParams: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      if (key === 'name' && typeof value === 'string') {
        return {
          ...state,
          equipmentParams: {
            name: value,
          },
        };
      }

      return {
        ...state,
        equipmentParams: {
          ...state.equipmentParams,
          [key]: value,
        },
      };
    },
  },
});

export const {
  prefilledEquipmentData,
  setAddEditEquipment,
  clearEquimpentData,
  setEquipmentPageable,
  clearEquipmentPageable,
  setEquipmentParams,
} = equipmentSlice.actions;

export default equipmentSlice.reducer;
