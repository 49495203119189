import { URL_ROOT } from '../../../axios/axiosRequest';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { notificationsMapper } from '../../../../../helpers/notificationsApiHelper';

export const notificationsApiSlice = createApi({
  reducerPath: 'notification',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['Notifications'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params) => ({
        url: '/api/notifications',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['Notifications'],
      transformResponse: (data) => {
        return notificationsMapper(data);
      },
    }),
    getAllNotifications: builder.query({
      query: ({ params }) => ({
        url: '/api/all-notifications',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['Notifications'],
    }),
    getNotificationCount: builder.query({
      query: (userId) => ({
        url: `/api/notifications/count/${userId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['Notifications'],
    }),
    updateNotifications: builder.mutation({
      query: (params) => ({
        url: '/api/notifications',
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      invalidatesTags: ['Notifications'],
    }),
    clearNotifications: builder.mutation({
      query: (userId) => ({
        url: `/api/notifications/clear/${userId}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetAllNotificationsQuery,
  useGetNotificationCountQuery,
  useUpdateNotificationsMutation,
  useClearNotificationsMutation,
} = notificationsApiSlice;
