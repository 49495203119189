import { useEffect, useRef } from 'react';

export const useKeyPress = (key: string, callback: (e: any) => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handle = (e: any) => {
      if (e.code === key) {
        callbackRef.current(e);
      }
    };
    document.addEventListener('keydown', handle);
    return () => {
      document.removeEventListener('keydown', handle);
    };
  }, [key]);
};
