import { useDispatch } from 'react-redux';
import {
  IEmployee,
  IFetchedRole,
  IFetchedTechnology,
  IMappedUser,
} from './employeesApiInterfaces';

import { capitalizeFirstLetterOnly } from './formattingHelperFunctions';
// import {
//   setUserRemote,
//   setUserRemoteTemp,
// } from '../common/lib/redux/slices/userSlice';

export const allUsersContentMapper = (user: IMappedUser) => {
  let {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    birthDate,
    content,
    vacationDays,
    usedVacationDays,
    prevYearDays,
    sickDays,
    paidDays,
    linkedin,
    company,
    role,
    technology,
    seniority,
    position,
    message,
    employee,
    archived,
    isRemote,
    isRemoteTemp,
    about,
    address,
    comment,
    userTechnologies,
    clients,
    dateOfEmployment,
    tshirtSize,
    familySaintDay,
    familySaintDayDate,
    remainingDays,
    contentType,
    lastLogin,
  } = user;

  const positionName = position?.includes('_')
    ? position?.replace(/_/g, ' ')
    : position;
  const fullName = `${capitalizeFirstLetterOnly(
    firstName ?? '',
  )} ${capitalizeFirstLetterOnly(lastName ?? '')}`;
  const roleName = role?.name ? capitalizeFirstLetterOnly(role?.name) : '';
  const technologyName = technology?.name
    ? capitalizeFirstLetterOnly(
        technology?.name.includes('_')
          ? technology?.name.split('_').reverse().join('_')
          : technology?.name,
      )
    : '';
  const jobTitle = `${seniority ?? ''} ${positionName ?? ''}`;
  const mappedUserTechnologies = userTechnologies
    .map((t: any) => t.technology.name)
    .join(', ');

  const clientsJoined = clients
    ? clients?.map((c: any) => c.name)?.join(', ')
    : '';

  const uniqueClients = [...new Set(clientsJoined?.split(', '))]?.join(', ');

  return {
    id,
    fullName,
    jobTitle,
    email,
    phoneNumber,
    birthDate,
    vacationDays,
    usedVacationDays,
    prevYearDays,
    sickDays,
    paidDays,
    content,
    linkedin,
    company,
    role,
    roleName,
    technology,
    technologyName,
    seniority,
    position: positionName,
    message,
    employee,
    archived,
    isRemote,
    isRemoteTemp,
    about,
    address,
    comment,
    userTechnologies: mappedUserTechnologies,
    clients: clientsJoined,
    uniqueClients: uniqueClients,
    firstName,
    lastName,
    dateOfEmployment,
    tshirtSize,
    familySaintDay,
    familySaintDayDate,
    remainingDays,
    contentType,
    lastLogin,
  };
};

export const allUsersMapper = (
  content: IMappedUser[],
  totalPages: number,
  number?: number,
) => {
  return { content, totalPages, number };
};

export const allTechnologiesMapper = ({
  technologyId,
  technologyName,
}: IFetchedTechnology) => {
  return {
    id: technologyId,
    label: technologyName,
    name: technologyName?.toLowerCase(),
  };
};

export const allRolesMapper = ({ roleId, roleName }: IFetchedRole) => {
  return {
    id: roleId,
    label: capitalizeFirstLetterOnly(roleName),
    name: roleName?.toLowerCase(),
  };
};

export const postNewEmployeeMapper = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  vacationDays,
  prevYearDays,
  sickDays,
  paidDays,
  birthDate,
  company,
  seniority,
  position,
  technologies,
  linkedin,
  role,
  dateOfEmployment,
  about,
  address,
  comment,
  clientId,
  tshirtSize,
  familySaintDay,
  familySaintDayDate,
}: IEmployee) => {
  return {
    firstName,
    lastName,
    email,
    phoneNumber,
    vacationDays:
      typeof vacationDays === 'string' ? parseInt(vacationDays) : vacationDays,
    prevYearDays,
    sickDays,
    paidDays,
    birthDate,
    company,
    seniority,
    position,
    technologyId: technologies.id,
    linkedin,
    isEmployee: true,
    isArchived: false,
    roleId: role.id,
    password: '!ASDasd123',
    dateOfEmployment,
    about,
    address,
    comment,
    clientId,
    tshirtSize,
    familySaintDay,
    familySaintDayDate,
  };
};
