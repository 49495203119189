import { Box } from '@chakra-ui/react';
import React from 'react';

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
}

const LeaveIcon: React.FC<IconTypes> = ({ height, width, stroke, fill }) => {
  return (
    <svg
      width={width ?? 20}
      height={height ?? 20}
      viewBox="0 0 14 14"
      fill={fill ?? 'none'}
      stroke={stroke ?? 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.94336 1.39083C5.90344 1.39083 5.06062 3.47559 5.06062 5.03536V5.38036C5.06062 5.88309 4.65305 6.29044 4.15031 6.29044H2.205C1.7025 6.29044 1.29492 5.88309 1.29492 5.38036V5.03536C1.29492 3.47559 3.82383 1.39083 6.94336 1.39083Z"
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M12.5918 5.03536V5.38036C12.5918 5.88309 12.1842 6.29044 11.6817 6.29044H9.73641C9.23367 6.29044 8.82609 5.88309 8.82609 5.38036V5.03536C8.82609 3.47559 7.98328 1.39083 6.94336 1.39083C10.0629 1.39083 12.5918 3.47559 12.5918 5.03536Z"
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M8.82602 5.03536V5.38036C8.82602 5.88309 8.41867 6.29044 7.91594 6.29044H5.97063C5.46789 6.29044 5.06055 5.88309 5.06055 5.38036V5.03536C5.06055 3.47559 5.90336 1.39083 6.94328 1.39083C7.9832 1.39083 8.82602 3.47559 8.82602 5.03536Z"
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M6.94336 6.29051V12.6092"
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M5.53125 12.6092H8.35547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeaveIcon;
