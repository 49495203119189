import { ComponentStyleConfig } from '@chakra-ui/react';

export const InputStyles: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    outline: {
      field: {
        borderWidth: '1px',
        borderColor: 'secondary',
        borderRadius: 'xl',
      },
    },
  },
};
