import { FC } from 'react';
import { useLang } from './i18n';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';

import enMessages from './messages/en.json';
import srMessages from './messages/sr.json';

const allMessages: any = {
  en: enMessages,
  // sr: srMessages,
};

const I18nProvider: FC = ({ children }: any) => {
  let locale: any = useLang();
  if (!locale) locale = 'en';
  const messages = allMessages['en'];

  return (
    <IntlProvider locale={'en'} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
