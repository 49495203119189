import {
  Box,
  CloseButton,
  Flex,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  Image,
} from '@chakra-ui/react';
import { MobileNav } from '../MobileNav/MobileNav';
import { NavItem } from './components/NavItem';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardIcon from '../../../../assets/icons/DashboardIcon';
import EmployeeIcon from '../../../../assets/icons/EmployeeIcon';
import ClientsIcon from '../../../../assets/icons/ClientsIcon';
import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import HolidaysIcon from '../../../../assets/icons/HolidaysIcon';
import DaysOffIcon from '../../../../assets/icons/DaysOffIcon';
import PostsIcon from '../../../../assets/icons/PostsIcon';
import WorklogIcon from '../../../../assets/icons/WorklogIcon';
import EquipmentIcon from '../../../../assets/icons/EquipmentIcon';

import divider from '../../../../assets/brand/divider.png';
import ProjectsIcon from '../../../../assets/icons/ProjectsIcon';
import { FormattedMessage } from 'react-intl';

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
}

interface ILinkItemProps {
  name: string | React.ReactElement;
  icon: React.ReactElement<IconTypes>;
  path: string;
}

const LinkItems: Array<ILinkItemProps> = [
  {
    name: <FormattedMessage id={'MENU.DASHBOARD'} />,
    icon: <DashboardIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id={'MENU.EMPLOYEES'} />,
    icon: <EmployeeIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/employees',
  },
  {
    name: <FormattedMessage id={'MENU.CLIENTS'} />,
    icon: <ClientsIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/clients',
  },
  {
    name: <FormattedMessage id={'MENU.PROJECTS'} />,
    icon: <ProjectsIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/projects',
  },
  {
    name: <FormattedMessage id={'MENU.WORKLOG'} />,
    icon: <WorklogIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/worklog',
  },
  {
    name: <FormattedMessage id={'MENU.CALENDAR'} />,
    icon: <CalendarIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/calendar',
  },
  {
    name: <FormattedMessage id={'MENU.HOLIDAYS'} />,
    icon: <HolidaysIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/holidays',
  },
  {
    name: <FormattedMessage id={'MENU.DAYS_OFF'} />,
    icon: <DaysOffIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/days-off',
  },
  {
    name: <FormattedMessage id={'MENU.POSTS'} />,
    icon: <PostsIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/posts',
  },
  {
    name: <FormattedMessage id={'EQUIPMENT'} />,
    icon: <EquipmentIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/equipment',
  },
];

const LinkItemsEmployee: Array<ILinkItemProps> = [
  {
    name: <FormattedMessage id={'MENU.DASHBOARD'} />,
    icon: <DashboardIcon />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id={'MENU.EMPLOYEES'} />,
    icon: <EmployeeIcon />,
    path: '/employees',
  },
  {
    name: <FormattedMessage id={'MENU.CLIENTS'} />,
    icon: <ClientsIcon />,
    path: '/clients',
  },
  {
    name: <FormattedMessage id={'MENU.PROJECTS'} />,
    icon: <ProjectsIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/projects',
  },
  {
    name: <FormattedMessage id={'MENU.CALENDAR'} />,
    icon: <CalendarIcon />,
    path: '/calendar',
  },
  {
    name: <FormattedMessage id={'MENU.DAYS_OFF'} />,
    icon: <DaysOffIcon />,
    path: '/days-off',
  },
  {
    name: <FormattedMessage id={'MENU.WORKLOG'} />,
    icon: <WorklogIcon />,
    path: '/worklog',
  },
  {
    name: <FormattedMessage id={'MENU.POSTS'} />,
    icon: <PostsIcon stroke="var(--chakra-colors-blue-500)" />,
    path: '/posts',
  },
];

type Props = {};

const Sidebar = (props: any) => {
  const { logo, loggedUser } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <SidebarContent
        logo={logo}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <Box display={{ base: 'block', md: 'none' }}>
        <MobileNav onOpen={onOpen} logo={logo} loggedUser={loggedUser} />
      </Box>
    </Box>
  );
};

interface ISidebarProps extends BoxProps {
  onClose: () => void;
  logo?: string;
}

const SidebarContent = ({ onClose, ...rest }: ISidebarProps) => {
  const { role } = useSelector((state: any) => state.user);
  const { logo } = rest;
  const navigate = useNavigate();
  return (
    <Box
      blur="2px"
      transition="2s ease"
      bg="#FFFFFF"
      w={{ base: 'full', md: '17rem' }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="3rem"
        my="1.8"
        justifyContent="space-between"
      >
        <Image
          src={logo}
          onClick={() => navigate('/dashboard')}
          cursor="pointer"
        />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        width="13rem"
        marginLeft="30px"
        marginBottom="1.4rem"
      >
        <img src={divider} />
      </Flex>
      {role === 'EMPLOYEE' ? (
        <>
          {LinkItemsEmployee.map((link) => (
            <NavItem key={link.path} icon={link.icon} path={link.path}>
              {link.name}
            </NavItem>
          ))}
        </>
      ) : (
        <>
          {LinkItems.map((link) => (
            <NavItem key={link.path} icon={link.icon} path={link.path}>
              {link.name}
            </NavItem>
          ))}
        </>
      )}
    </Box>
  );
};

export { Sidebar };
