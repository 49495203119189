import { capitalizeFirstLetterOnly } from './formattingHelperFunctions';

export const clientsContentMapper = (client: any) => {
  return {
    active: client?.active,
    about: client?.about,
    content: client?.content,
    country: client?.country,
    dateOfJoining: client?.dateOfJoining,
    email: client?.email,
    id: client?.id,
    message: client?.message,
    name: client?.name,
    website: client?.website,
    project: client?.project,
    numberOfProjects: client?.project?.length,
    // website: client?.website?.split("//")?.[1],
  };
};
