import { URL_ROOT } from '../../../axios/axiosRequest';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { clientsContentMapper } from '../../../../../helpers/clientsApiHelpers';

export const clientsApiSlice = createApi({
  reducerPath: 'apiClients',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: [
    'AllClients',
    'GetClient',
    'ClientsList',
    'ClientInvoice',
    'DeleteInvoice',
  ],
  endpoints: (builder) => ({
    getAllClients: builder.query({
      query: ({ params, body }) => ({
        url: '/api/all-clients',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
        body,
      }),
      providesTags: ['AllClients'],
    }),
    getAllActiveClientsList: builder.query({
      query: () => ({
        url: '/api/all-active-clients',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      providesTags: ['ClientsList'],
    }),
    getClient: builder.query({
      query: (id) => ({
        url: `/api/clients/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      transformResponse: (response: any) => {
        const content = clientsContentMapper(response);
        return content;
      },
      providesTags: ['GetClient'],
    }),
    getClientDetails: builder.query({
      query: (clientId) => ({
        url: `/api/client/${clientId}/details`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
    }),
    createClient: builder.mutation({
      query: (body) => ({
        url: '/api/clients',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllClients'],
    }),
    updateActiveClient: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/clients/${id}/archived`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllClients'],
    }),
    updateClient: builder.mutation({
      query: ({ clientId, body }) => ({
        url: `/api/clients/${clientId}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllClients'],
    }),
    patchClientLogo: builder.mutation({
      query: ({ clientId, body }) => ({
        url: `/api/clients/${clientId}/logo`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllClients'],
    }),
    updateArchivedClient: builder.mutation({
      query: ({ body, id }) => ({
        url: `/api/clients/${id}/archived`,
        method: `PATCH`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        body,
      }),
      invalidatesTags: ['AllClients'],
    }),
    postClientInvoice: builder.mutation({
      query: ({ params }) => ({
        url: `/api/client/invoice`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      invalidatesTags: ['ClientInvoice'],
    }),
    deleteInvoice: builder.mutation({
      query: ({ id }) => ({
        url: `/api/client/invoice/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
      }),
      invalidatesTags: ['DeleteInvoice'],
    }),
  }),
});

export const {
  useGetAllClientsQuery,
  useGetAllActiveClientsListQuery,
  useGetClientQuery,
  useCreateClientMutation,
  useUpdateActiveClientMutation,
  usePatchClientLogoMutation,
  useUpdateClientMutation,
  useUpdateArchivedClientMutation,
  useGetClientDetailsQuery,
  usePostClientInvoiceMutation,
  useDeleteInvoiceMutation,
} = clientsApiSlice;
