import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

interface IAxiosRequest {
  method: string;
  url: string;
  data?: any;
  params?: any;
  withCredentials?: boolean;
}

export const URL_ROOT = `${process.env.REACT_APP_BASE_URL}${
  process.env.REACT_APP_BASE_PORT ? ":" + process.env.REACT_APP_BASE_PORT : ""
}`;

const api: AxiosInstance = axios.create({ baseURL: URL_ROOT });

export const axiosRequest = ({
  method,
  url,
  data,
  params,
  withCredentials,
}: AxiosRequestConfig<IAxiosRequest>) => {
  const token = localStorage.getItem("hrc_access_token");
  return api({
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: withCredentials ? `Bearer ${token}` : "",
    },
    data,
    params,
  });
};
