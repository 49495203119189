import { HStack, Link, Text, useMediaQuery } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type Props = {};

const Footer = (props: Props) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <HStack
      justifyContent={'space-between'}
      fontWeight={300}
      color="#54595F"
      position="fixed"
      bottom="0"
      bg="white"
      left={isLargerThan768 ? '272px' : 'none'}
      right="0"
      h="40px"
    >
      <Text fontSize="sm">
        &#64; {new Date().getFullYear()},{' '}
        <FormattedMessage id={'FOOTER.TITLE'} />{' '}
        <span style={{ color: 'red' }}>&#10084;</span>{' '}
        <FormattedMessage id={'BY'} />{' '}
        <Link href="https://atessoft.rs/" color="var(--chakra-colors-blue-500)">
          Ates Soft
        </Link>
      </Text>
      <HStack
        spacing={{ base: '5', md: '10' }}
        style={{ marginRight: isLargerThan768 ? '2rem' : '0.1rem' }}
      >
        <Text
          variant={'link'}
          as={'a'}
          href="mailto:office@atessoft.rs"
          fontSize="sm"
        >
          <FormattedMessage id={'FOOTER.CONTACT_US'} />
        </Text>
      </HStack>
    </HStack>
  );
};

export { Footer };
