import React, { useState } from 'react';
import {
  Avatar,
  Text,
  Box,
  Flex,
  Button,
  Textarea,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import moment from 'moment';
import CommentActions from './CommentActions';
import { setCommentDeleteId } from '../../../../common/lib/redux/slices/postsSlice';
import { useDispatch } from 'react-redux';
import { IntlShape } from 'react-intl';
import TextWithLinksAndLineBreaks from '../../../../components/TextWithLinksAndLineBreaks';
import EmojiPicker from 'emoji-picker-react';
import { FaRegSmile } from 'react-icons/fa';

export interface ICommentData {
  id: number;
  text: string;
  userName: string;
  role: {
    id: number;
    name: string;
  };
  userId: number;
  dateCreated: string;
  dateUpdated: string;
  content: string;
  contentType: string;
}

interface CommentItemProps {
  comment: ICommentData;
  onEditComment: (editedText: string) => void;
  onDeleteComment: () => void;
  intl: IntlShape;
  openedFromDashboard?: boolean | undefined;
}

const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  onEditComment,
  onDeleteComment,
  intl,
  openedFromDashboard,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState(comment.text);
  const [isHovered, setIsHovered] = useState(false);
  const [isOpenEmojiWindow, setIsOpenEmojiWindow] = React.useState(false);

  const dispatch = useDispatch();

  const handleSaveComment = () => {
    onEditComment(editedComment);
    setIsEditing(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleEmojiPicker = () => {
    setIsOpenEmojiWindow(!isOpenEmojiWindow);
  };

  const handleEmojiClick = (emoji: any) => {
    let newValue = editedComment + emoji.emoji;
    setEditedComment(newValue);
  };

  const calculateTimeDifference = (commentDate: any) => {
    const dateFormat = 'DD.MM.YYYY HH:mm';
    const parsedDate = moment(commentDate, dateFormat);

    if (!parsedDate.isValid()) {
      console.error('Invalid comment date format:', commentDate);
      return 'Invalid date';
    }

    const now = moment();
    const delta = now.diff(parsedDate);

    const MINUTE_IN_MS = 60 * 1000;
    const HOUR_IN_MS = MINUTE_IN_MS * 60;
    const DAY_IN_MS = HOUR_IN_MS * 24;
    const WEEK_IN_MS = DAY_IN_MS * 7;
    const MONTH_IN_MS = DAY_IN_MS * 30;
    const YEAR_IN_MS = MONTH_IN_MS * 12;

    if (delta < MINUTE_IN_MS) {
      return `A few seconds ago`;
    } else if (delta < HOUR_IN_MS) {
      return `${Math.floor(delta / MINUTE_IN_MS)} min ago`;
    } else if (delta < DAY_IN_MS) {
      return `${Math.floor(delta / HOUR_IN_MS)} h ago`;
    } else if (delta < WEEK_IN_MS) {
      return `${Math.floor(delta / DAY_IN_MS)} d ago`;
    } else if (delta < YEAR_IN_MS) {
      const months = Math.floor(delta / MONTH_IN_MS);
      if (months > 0) {
        return `${months} mth ago`;
      } else {
        return `${Math.floor(delta / WEEK_IN_MS)} w ago`;
      }
    } else {
      const years = Math.floor(delta / YEAR_IN_MS);
      return `${years} y ago`;
    }
  };

  return (
    <Box
      key={comment.id}
      my="4"
      onMouseEnter={!openedFromDashboard ? handleMouseEnter : undefined}
      onMouseLeave={!openedFromDashboard ? handleMouseLeave : undefined}
    >
      <Flex position="relative">
        <Avatar
          name={comment.userName}
          src={`data:${comment?.contentType};base64,${comment?.content}`}
          size="md"
          mr="2"
        />
        <Box w="full">
          {isEditing ? (
            <Box>
              <Box position="relative">
                <Textarea
                  value={editedComment}
                  onChange={(e) => setEditedComment(e.target.value)}
                  w="full"
                />
                <Box position="absolute" bottom="5px" right="5px" zIndex="999">
                  <IconButton
                    isRound={true}
                    aria-label="comment"
                    colorScheme="blue"
                    size="sm"
                    marginRight="5px"
                  >
                    <FaRegSmile onClick={toggleEmojiPicker} />
                  </IconButton>
                </Box>
              </Box>
              {isOpenEmojiWindow && (
                <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  open={isOpenEmojiWindow}
                  style={{
                    position: 'absolute',
                    top: '30px',
                    right: '5px',
                    zIndex: '999',
                  }}
                  lazyLoadEmojis
                />
              )}
              <Flex mt="2">
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={handleSaveComment}
                >
                  {intl.formatMessage({ id: 'SAVE' })}
                </Button>
                <Button
                  size="sm"
                  colorScheme="gray"
                  ml="2"
                  onClick={() => setIsEditing(false)}
                >
                  {intl.formatMessage({ id: 'CANCEL' })}
                </Button>
              </Flex>
            </Box>
          ) : (
            <>
              <Text fontSize="14px">{comment.userName}</Text>
              <TextWithLinksAndLineBreaks
                content={comment.text}
                maxLength={200}
              />
              <Flex gap={2}>
                <Tooltip
                  label={`${moment(comment.dateCreated).format(
                    'DD.MM.YYYY HH:mm',
                  )}`}
                >
                  <Text
                    fontSize="14px"
                    color="gray.500"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {`${calculateTimeDifference(
                      moment(comment.dateCreated).format('DD.MM.YYYY HH:mm'),
                    )}`}
                  </Text>
                </Tooltip>
                {isHovered && !openedFromDashboard && (
                  <CommentActions
                    userId={comment.userId}
                    onEdit={() => setIsEditing(true)}
                    onDelete={() => {
                      dispatch(setCommentDeleteId(comment.id));
                      onDeleteComment();
                    }}
                    intl={intl}
                    comment={comment}
                  />
                )}
              </Flex>
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default CommentItem;
