import { createSlice } from '@reduxjs/toolkit';

export interface IHolidayParams {
  holidayParams: {
    page: number;
    size: string | number;
    sort: string[];
  };
  holidayPageable: {
    page: number;
    size: string | number;
    sort: string[];
  };
  addHolidayForm: {
    name: string;
    date: Date | null | undefined;
    workingDay: string;
  };
}

export const initialState: IHolidayParams = {
  holidayParams: {
    page: 0,
    size: 1,
    sort: ['', ''],
  },
  holidayPageable: {
    page: 0,
    size: '',
    sort: ['date', 'desc'],
  },
  addHolidayForm: {
    name: '',
    date: undefined,
    workingDay: '',
  },
};

interface IHolidayPayload {
  [key: string]: any;
}

export const holidaySlice = createSlice({
  name: 'holiday',
  initialState,
  reducers: {
    setHolidayValues: (
      state: IHolidayParams,
      action: { payload: IHolidayPayload; type: string },
    ) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];
      return {
        ...state,
        addHolidayForm: {
          ...state.addHolidayForm,
          [key]: value,
        },
      };
    },
    setHolidayPageable: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      state.holidayPageable = {
        ...state.holidayPageable,
        [key]: value,
      };
    },
    clearHolidayPageable: (state) => {
      state.holidayPageable = {
        page: 0,
        size: '',
        sort: ['date', 'desc'],
      };
    },
    setHolidayForm: (state: any, action: any) => {
      const { name, value } = action.payload;

      state.addHolidayForm = {
        ...state.addHolidayForm,
        [name]: value,
      };
    },
    clearHolidayForm: (state: any) => {
      state.addHolidayForm = {
        name: '',
        date: '',
        workingDay: '',
      };
    },
    setEditHoliday: (state: any, action: any) => {
      const { name, date, workingDay } = action.payload;

      state.addHolidayForm = {
        name,
        date,
        workingDay,
      };
    },
    setHolidayEmpty: (state: IHolidayParams) => {
      return {
        ...state,
        addHoliday: {
          name: '',
          date: '',
          workingDay: true,
        },
      };
    },
  },
});

export const {
  setHolidayForm,
  clearHolidayForm,
  clearHolidayPageable,
  setHolidayPageable,
  setHolidayValues,
  setHolidayEmpty,
  setEditHoliday,
} = holidaySlice.actions;

export default holidaySlice.reducer;
