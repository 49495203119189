import { Box, BoxProps } from '@chakra-ui/react';
import { mapColorCodeToChakraVar } from '../../helpers/formattingHelperFunctions';

interface AvatarProps extends BoxProps {
  name: string;
  surname?: string;
  width?: any;
  height?: any;
  border?: string;
  fontSize?: string;
  color?: string;
  borderRadius?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  name,
  surname,
  width = '2rem',
  height = '2rem',
  border,
  fontSize,
  color,
  borderRadius,
  ...rest
}) => {
  return (
    <Box
      {...rest}
      w={width}
      h={height}
      borderRadius={borderRadius ?? '4px'}
      bg="transparent"
      border={border ?? '2px solid rgba(0, 157, 226, 0.5)'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontWeight="500"
      fontSize={fontSize ?? '22px'}
      color={color ?? mapColorCodeToChakraVar('blue-500')}
      p="4"
    >
      {name && name[0]}
      {surname && surname[0]}
    </Box>
  );
};

export default Avatar;
