export const capitalizeFirstLetterOnly = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const capitalizeWords = (str: string) => {
  // Check if the string has multiple words
  if (str.indexOf(' ') !== -1) {
    // Split the string into an array of words
    const words = str.split(' ');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the array back into a string with the capitalized words
    return capitalizedWords.join(' ');
  }

  // If the string has only one word, just capitalize the first letter and return it
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeFirstLetterOfWords = (str: string) => {
  // Check if the string has multiple words
  if (str.indexOf(' ') !== -1) {
    // Split the string into an array of words
    const words = str.split(' ');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the array back into a string with the capitalized words
    return capitalizedWords.join(' ');
  }

  // If the string has only one word, just capitalize the first letter and return it
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getBase64FileEncodedContent = (base64: string) =>
  (base64 + '')?.split(',')[1];

export const joinBase64EncodedContentWithType = (
  content: string,
  contentType: string,
) => `data:${contentType};base64,${content}`;

export const mapColorCodeToChakraVar = (color: string): string =>
  `var(--chakra-colors-${color})`;
