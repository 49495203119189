import React from 'react';

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
}

const BellIcon: React.FC<IconTypes> = ({ height, width, stroke, fill }) => {
  return (
    <svg
      width={width ?? 20}
      height={height ?? 20}
      viewBox="0 0 20 20"
      fill={fill ?? 'none'}
      stroke={stroke ?? 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0384 4.37555L6.5205 3.02075C6.14525 2.03907 6.90633 1 8.00067 1C9.09497 1 9.85609 2.03907 9.48084 3.02075L8.96294 4.37555"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00216 19.0001H7.9952C7.07155 19.0001 6.32275 18.2821 6.32275 17.3963V16.467H9.67457V17.3963C9.67461 18.2821 8.92586 19.0001 8.00216 19.0001Z"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.06909 12.842H10.3418"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.38 12.6531C13.3557 12.4156 13.3434 12.1382 13.3434 11.8978V9.4106C13.3434 6.59217 10.9509 4.30737 7.99955 4.30737C5.0482 4.30737 2.65567 6.59217 2.65567 9.4106V11.8978C2.65567 13.555 2.07259 15.164 1 16.4667H14.9991"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.62002 12.6531C2.64428 12.4156 2.65657 12.1382 2.65657 11.8978V9.4106C2.65657 6.59217 5.0491 4.30737 8.00045 4.30737C10.9518 4.30737 13.3443 6.59217 13.3443 9.4106V11.8978C13.3443 13.555 13.9274 15.164 15 16.4667H1.00086"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BellIcon;
