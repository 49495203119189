import React from 'react'

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
};

const ClientsIcon: React.FC<IconTypes> = ({height, width, stroke, fill}) => {
  return (
    <svg
    width={width ?? 20}
    height={height ?? 20}
    viewBox="0 0 20 20"
    fill={fill ?? "none"}
    stroke={stroke ?? 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
    
  <path
    d="M11.061 1.7317H3.19512C1.98279 1.7317 1 2.71449 1 3.92682V16.8049C1 18.0172 1.98279 19 3.19512 19H16.0732C17.2855 19 18.2683 18.0172 18.2683 16.8049V8.9756"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
  />
  <path
    d="M6.59625 9.5275C6.59625 7.83023 7.97212 6.45433 9.66943 6.45433C11.3667 6.45433 12.7426 7.83023 12.7426 9.5275C12.7426 11.2248 11.3667 12.6007 9.66943 12.6007C7.97212 12.6007 6.59625 11.2248 6.59625 9.5275Z"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M14.8759 19H4.46286C3.77242 19 3.26074 18.368 3.40144 17.6959C4.00664 14.8056 6.58295 12.6341 9.6694 12.6341"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M14.8054 19C15.4959 19 16.0076 18.368 15.8669 17.6959C15.2617 14.8056 12.6854 12.6341 9.59891 12.6341"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M18.5361 2.86584H17.1877C16.9871 2.86584 16.8093 2.78861 16.7473 2.59917L16.3307 1.31769C16.2614 1.1059 16.0758 0.999985 15.8903 0.999985C15.7047 0.999985 15.5192 1.1059 15.4499 1.31769L15.0332 2.59917C14.9712 2.78861 14.7934 2.86584 14.5928 2.86584H13.2445C12.7959 2.86584 12.6094 3.48684 12.9723 3.74865L14.0631 4.53556C14.2254 4.65263 14.2933 4.86018 14.2313 5.04962L13.7924 6.32287C13.7739 6.37957 13.7666 6.43496 13.7687 6.48779C13.7823 6.82976 14.2129 7.06368 14.5273 6.83693L15.6181 6.04979C15.6992 5.99126 15.7947 5.96199 15.8903 5.96199C15.9858 5.96199 16.0813 5.99126 16.1624 6.04979L17.2533 6.83693C17.5676 7.06368 17.9962 6.82976 18.0118 6.48779C18.0142 6.43496 18.007 6.37957 17.9885 6.32287L17.5492 5.04962C17.4872 4.86018 17.5551 4.65263 17.7174 4.53556L18.8082 3.74865C19.1711 3.48684 18.9847 2.86584 18.5361 2.86584Z"
  />
</svg>

  

  )
}

export default ClientsIcon