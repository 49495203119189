import { createSlice } from "@reduxjs/toolkit";

export interface IValues {
  login: any;
  forgot: any;
  create: any;
}

export interface IAuth {
  type: "login" | "forgot" | "create";
  showPassword: boolean;
  formValues: IValues;
}

export const initialState: IAuth = {
  type: "login",
  showPassword: false,
  formValues: {
    login: { email: "", password: "" },
    forgot: { email: "" },
    create: { password: "" },
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthType: (state: IAuth, action) => {
      state.type = action.payload;
    },
    togglePasswordVisibility: (state: IAuth) => {
      state.showPassword = !state.showPassword;
    },
    updateValues: (state: IAuth, action) => {
      const { inputName, inputValue } = action.payload;
      state.formValues = {
        ...state.formValues,
        [state.type]: {
          ...state.formValues[state.type],
          [inputName]: inputValue,
        },
      };
    },
  },
});

export const { setAuthType, togglePasswordVisibility, updateValues } = authSlice.actions;
export default authSlice.reducer;
