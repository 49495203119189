import React from 'react';

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
}

const PaidLeaveDayIcon: React.FC<IconTypes> = ({
  height,
  width,
  stroke,
  fill,
}) => {
  return (
    <svg
      width={width ?? 20}
      height={height ?? 20}
      viewBox="0 0 26 26"
      fill={fill ?? 'none'}
      stroke={stroke ?? 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 21.9492C25 23.6341 23.6341 25 21.9492 25H4.05085C2.36592 25 1 23.6341 1 21.9492V6.08475C1 4.39981 2.36592 3.0339 4.05085 3.0339H21.9492C23.6341 3.0339 25 4.39981 25 6.08475V16.8644"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33887 0.999998V5.06779"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6113 5.06779V0.999998"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9492 0.999998V5.06779"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6622 14.017H15.1322L12.1191 10.2535C11.9912 10.0938 11.7977 10.0008 11.5931 10.0006C11.096 10.0003 10.7694 10.5197 10.9851 10.9675L12.4537 14.017H9.98106L9.0839 13.0332C9.00697 12.9488 8.89816 12.9004 8.78396 12.9C8.55784 12.8991 8.37402 13.0821 8.37402 13.3082V16.7104C8.37402 16.9359 8.55682 17.1186 8.78228 17.1186H8.83048C8.94301 17.1186 9.0506 17.0722 9.12774 16.9902L10.0118 16.0509H12.4502L10.9838 19.1013C10.7686 19.549 11.0949 20.0678 11.5915 20.0678H11.5918C11.7971 20.0678 11.9913 19.9742 12.1193 19.8136L15.1159 16.0509H16.6622C17.2361 16.0509 17.8317 15.6073 17.8317 15.0333C17.8317 14.4594 17.2361 14.017 16.6622 14.017Z"
        fill="white"
      />
    </svg>
  );
};

export default PaidLeaveDayIcon;
