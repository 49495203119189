import { Box, Image, Text } from '@chakra-ui/react';
import pageNotFoundImg from '../../../../assets/images/page-not-found.png';

interface IErrorPage {
  noResult?: boolean;
  noSearchParams?: boolean;
}

const ErrorPage = ({ noResult, noSearchParams }: IErrorPage) => {
  return (
    <Box
      flexGrow="1"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={pageNotFoundImg}
        alt="Page not Found image"
        mx="auto"
        mb="4"
      />
      <Text fontSize="18px" mt="4" fontWeight="600" color="secondaryDarken">
        {noSearchParams
          ? 'Choose search parameters first!'
          : noResult
          ? 'Currently there are no results!'
          : 'Error 404: Page Not Found!'}
      </Text>
      <Text
        fontSize="14px"
        mt="2"
        color="secondaryDarken"
        fontFamily="Roboto"
        fontWeight="400"
      >
        {noSearchParams
          ? "We're sorry, but it seems that there are no results matching your search criteria"
          : noResult
          ? "We're sorry, but it seems that there are no results matching your search criteria"
          : 'The requested page or resource could not be found!'}
      </Text>
      <Text
        fontSize="14px"
        fontWeight="400"
        color="secondaryDarken"
        fontFamily="Roboto"
      >
        {noSearchParams
          ? 'Please change search criteria...'
          : noResult
          ? 'Please change search criteria...'
          : 'Please try again!'}
      </Text>
    </Box>
  );
};

export default ErrorPage;
