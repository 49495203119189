import { employeesApiSlice } from './slices/apiSlices/employeesApiSlice';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import dashboardReducer from './slices/dashboardSlice';
import employeesReducer from './slices/employeesSlice';
import daysOffReducer from './slices/daysOffSlice';
import profileReducer from './slices/profileSlice';
import postsReducer from './slices/postsSlice';
import userReducer from './slices/userSlice';
import clientsReducer from './slices/clientsSlice';
import holidayReducer from './slices/holidaySlice';
import worklogReducer from './slices/worklogSlice';
import projectsReducer from './slices/projectsSlice';
import notificationsReducer from './slices/notificationsSlice';
import obligationsReducer from './slices/obligationSlice';
import equipmentReducer from './slices/equipmentSlice';
import reviewsReducer from './slices/reviewsSlice';
import { profileApiSlice } from './slices/apiSlices/profileApiSlice';
import { dashboardApiSlice } from './slices/apiSlices/dashbordApiSlice';
import { daysOffApiSlice } from './slices/apiSlices/daysOffApiSlice';
import { postsApiSlice } from './slices/apiSlices/postsApiSlice';
import { postTypesApiSlice } from './slices/apiSlices/postTypesApiSlice';
import { clientsApiSlice } from './slices/apiSlices/clientsApiSlice';
import { holidayApiSlice } from './slices/apiSlices/holidayApiSlice';
import { worklogApiSlice } from './slices/apiSlices/worklogApiSlice';
import { projectsApiSlice } from './slices/apiSlices/projectsApiSlice';
import { notificationsApiSlice } from './slices/apiSlices/notificationsApiSlice';
import { assetsApiSlice } from './slices/apiSlices/assetsApiSlice';
import { equipmentApiSlice } from './slices/apiSlices/equipmentApiSlice';
import { obligationsApiSlice } from './slices/apiSlices/obligationsApiSlice';
import { rateApiSlice } from './slices/apiSlices/rateApiSlice';
import { reviewsApiSlice } from './slices/apiSlices/reviewsApiSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    profile: profileReducer,
    clients: clientsReducer,
    daysOff: daysOffReducer,
    holiday: holidayReducer,
    employees: employeesReducer,
    posts: postsReducer,
    user: userReducer,
    worklog: worklogReducer,
    projects: projectsReducer,
    notifications: notificationsReducer,
    obligations: obligationsReducer,
    equipment: equipmentReducer,
    reviews: reviewsReducer,
    [profileApiSlice.reducerPath]: profileApiSlice.reducer,
    [dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
    [employeesApiSlice.reducerPath]: employeesApiSlice.reducer,
    [daysOffApiSlice.reducerPath]: daysOffApiSlice.reducer,
    [postsApiSlice.reducerPath]: postsApiSlice.reducer,
    [postTypesApiSlice.reducerPath]: postTypesApiSlice.reducer,
    [holidayApiSlice.reducerPath]: holidayApiSlice.reducer,
    [clientsApiSlice.reducerPath]: clientsApiSlice.reducer,
    [worklogApiSlice.reducerPath]: worklogApiSlice.reducer,
    [projectsApiSlice.reducerPath]: projectsApiSlice.reducer,
    [notificationsApiSlice.reducerPath]: notificationsApiSlice.reducer,
    [assetsApiSlice.reducerPath]: assetsApiSlice.reducer,
    [equipmentApiSlice.reducerPath]: equipmentApiSlice.reducer,
    [obligationsApiSlice.reducerPath]: obligationsApiSlice.reducer,
    [rateApiSlice.reducerPath]: rateApiSlice.reducer,
    [reviewsApiSlice.reducerPath]: reviewsApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      notificationsApiSlice.middleware,
      daysOffApiSlice.middleware,
      postsApiSlice.middleware,
      employeesApiSlice.middleware,
      clientsApiSlice.middleware,
      profileApiSlice.middleware,
      projectsApiSlice.middleware,
      holidayApiSlice.middleware,
      dashboardApiSlice.middleware,
      worklogApiSlice.middleware,
      assetsApiSlice.middleware,
      equipmentApiSlice.middleware,
      obligationsApiSlice.middleware,
      rateApiSlice.middleware,
      reviewsApiSlice.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
