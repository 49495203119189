import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Auth = lazy(() =>
  import('../../pages/Auth/Auth').then(({ Auth }) => ({ default: Auth })),
);
const UserManagement = lazy(() =>
  import('../../pages/Admin/UserManagement/UserManagement').then(
    ({ UserManagement }) => ({
      default: UserManagement,
    }),
  ),
);
const Dashboard = lazy(() =>
  import('../../pages/User/Dashboard/Dashboard').then(({ Dashboard }) => ({
    default: Dashboard,
  })),
);
const Employees = lazy(() =>
  import('../../pages/User/Employees/Employees').then(({ Employees }) => ({
    default: Employees,
  })),
);
const Clients = lazy(() =>
  import('../../pages/User/Clients/Clients').then(({ Clients }) => ({
    default: Clients,
  })),
);
const Tools = lazy(() =>
  import('../../pages/User/Tools/Tools').then(({ Tools }) => ({
    default: Tools,
  })),
);
const DaysOff = lazy(() =>
  import('../../pages/User/DaysOff/DaysOff').then(({ DaysOff }) => ({
    default: DaysOff,
  })),
);
const Calendar = lazy(() =>
  import('../../pages/User/Calendar/Calendar').then(({ CalendarPage }) => ({
    default: CalendarPage,
  })),
);
const Posts = lazy(() =>
  import('../../pages/User/Posts/Posts').then(({ Posts }) => ({
    default: Posts,
  })),
);
const Holidays = lazy(() =>
  import('../../pages/User/Holidays/Holidays').then(({ Holidays }) => ({
    default: Holidays,
  })),
);
const Worklog = lazy(() =>
  import('../../pages/User/Worklog/index').then(({ Worklog }) => ({
    default: Worklog,
  })),
);
const Projects = lazy(() =>
  import('../../pages/User/Projects/Projects').then(({ Projects }) => ({
    default: Projects,
  })),
);
const EmployeeDetails = lazy(() =>
  import('../../pages/User/Details/EmployeeDetails').then(
    ({ EmployeeDetails }) => ({
      default: EmployeeDetails,
    }),
  ),
);
const ClientDetails = lazy(() =>
  import('../../pages/User/Details/ClientDetails').then(
    ({ ClientDetails }) => ({
      default: ClientDetails,
    }),
  ),
);
const ProjectDetails = lazy(() =>
  import('../../pages/User/Details/ProjectDetails').then(
    ({ ProjectDetails }) => ({
      default: ProjectDetails,
    }),
  ),
);
const Notifications = lazy(() =>
  import('../../pages/User/Notifications/Notifications').then(
    ({ Notifications }) => ({
      default: Notifications,
    }),
  ),
);
const Equipment = lazy(() =>
  import('../../pages/User/Equipments/Equipment').then(({ Equipment }) => ({
    default: Equipment,
  })),
);

const ProfileSettings = lazy(() =>
  import('../../pages/User/Profile/ProfileSettings').then(
    ({ ProfileSettings }) => ({
      default: ProfileSettings,
    }),
  ),
);

export const routes = (isAuth: boolean, role: string) => [
  {
    path: '/',
    element: isAuth ? <Navigate to="/dashboard" /> : <Navigate to="/auth" />,
  },
  { path: 'auth', element: isAuth ? <Navigate to="/dashboard" /> : <Auth /> },
  {
    path: 'user-management',
    element:
      isAuth && role === 'admin' ? <UserManagement /> : <Navigate to="/auth" />,
  },
  {
    path: 'dashboard',
    element: isAuth ? <Dashboard /> : <Navigate to="/auth" />,
  },
  {
    path: 'employees',
    element: isAuth ? <Employees /> : <Navigate to="/auth" />,
  },
  {
    path: 'days-off',
    element: isAuth ? <DaysOff /> : <Navigate to="/auth" />,
  },
  {
    path: 'posts',
    element: isAuth ? <Posts /> : <Navigate to="/auth" />,
  },
  {
    path: 'posts/:postId',
    element: isAuth ? <Posts /> : <Navigate to="/auth" />,
  },
  {
    path: 'profile-settings',
    element: isAuth ? <ProfileSettings /> : <Navigate to="/auth" />,
  },
  {
    path: 'calendar',
    element: isAuth ? <Calendar /> : <Navigate to="/auth" />,
  },
  {
    path: 'projects',
    element: isAuth ? <Projects /> : <Navigate to="/auth" />,
  },
  {
    path: 'holidays',
    element:
      (isAuth && role === 'ADMIN') || (isAuth && role === 'HR') ? (
        <Holidays />
      ) : (
        <Navigate to="/auth" />
      ),
  },
  {
    path: 'employees/:employeeId',
    element: isAuth ? <EmployeeDetails /> : <Navigate to="/auth" />,
  },
  {
    path: 'projects/:projectId',
    element: isAuth ? <ProjectDetails /> : <Navigate to="/auth" />,
  },
  {
    path: 'worklog',
    element: isAuth ? <Worklog /> : <Navigate to="/auth" />,
  },
  {
    path: 'clients',
    element: isAuth ? <Clients /> : <Navigate to="/auth" />,
  },
  {
    path: 'clients/:clientId',
    element: isAuth ? <ClientDetails /> : <Navigate to="/auth" />,
  },
  { path: 'tools', element: isAuth ? <Tools /> : <Navigate to="/auth" /> },
  {
    path: 'notifications',
    element: isAuth ? <Notifications /> : <Navigate to="/auth" />,
  },
  {
    path: 'equipment',
    element: isAuth ? <Equipment /> : <Navigate to="/auth" />,
  },
];
