import React, { useState } from 'react';
import {
  Radio,
  RadioGroup,
  Stack,
  Box,
  Flex,
  Text,
  Progress,
  Tooltip,
  Avatar,
  Image,
  Spinner,
  background,
} from '@chakra-ui/react';
import { useGetListOfUsersVotedByOptionIdQuery } from '../../common/lib/redux/slices/apiSlices/postsApiSlice';
import moment from 'moment';

interface VotingProps {
  options: PollOption[];
  userId: number | null;
  onSubmitVote: (userId: number, optionId: number) => void;
  onEditVote: (
    pollId: number,
    oldOptionId: number,
    newOptionId: number,
  ) => void;
  endDate: string;
}

interface PollOption {
  id: number;
  name: string;
  count: number;
  voted: boolean;
}

const PollVotingComponent = ({
  options,
  userId,
  onSubmitVote,
  onEditVote,
  endDate,
}: VotingProps) => {
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const isVotingAllowed =
    moment(endDate).format('YYYY-MM-DD') >=
    moment(new Date()).format('YYYY-MM-DD');

  const {
    data: voteList,
    isLoading: isLoadingVotesList,
    isError: isErrorVoteList,
  } = useGetListOfUsersVotedByOptionIdQuery(
    {
      optionId: Number(selectedOptionId),
    },
    {
      skip: !selectedOptionId,
    },
  );
  const votedId = options?.find((el) => el.voted);
  return (
    <Box
      borderRadius="15px"
      box-shadow="0px 1px 3px 0px #00000026"
      p="1rem"
      backgroundColor="var(--chakra-colors-grey-200)"
    >
      <RadioGroup
        onChange={(value) => {
          if (isVotingAllowed) {
            setSelectedOptionId(value);
            if (votedId?.id) {
              onEditVote(Number(userId), votedId.id, Number(value));
            } else {
              onSubmitVote(Number(userId), Number(value));
            }
          }
        }}
        value={votedId ? votedId.id.toString() : ''}
      >
        <Stack spacing={4}>
          {options?.map((option) => (
            <Tooltip
              backgroundColor="white"
              color={'black'}
              key={option.id}
              label={
                isLoadingVotesList ? (
                  <Spinner size="sm" color="blue.500" />
                ) : isErrorVoteList ? (
                  'Error'
                ) : (
                  <Stack>
                    {voteList?.map((user: any) => (
                      <Flex key={user.id} align="center">
                        {user?.content?.length ? (
                          <Image
                            w="24px"
                            h="24px"
                            borderRadius="15px"
                            src={`data:${user.contentType};base64,${user.content}`}
                            objectFit="cover"
                          />
                        ) : (
                          <Box w="24px" h="24px">
                            <Avatar
                              name={user.displayName}
                              h="24px"
                              w="24px"
                              size={'24px'}
                            />
                          </Box>
                        )}
                        <Box
                          mr={{
                            base: '20px !important',
                            md: '60px !important',
                            '2xl': '10px !important',
                          }}
                          ml="10px"
                        >
                          <Text>{user?.displayName}</Text>
                        </Box>
                      </Flex>
                    ))}
                  </Stack>
                )
              }
            >
              <Flex
                key={option.id}
                align="center"
                onMouseEnter={() => setSelectedOptionId(option.id.toString())}
              >
                <Radio
                  value={option.id.toString()}
                  isDisabled={option.voted || !isVotingAllowed}
                  _disabled={{
                    background: 'lightblue',
                  }}
                  _hover={{
                    border: '4px solid gray',
                    background: 'lightblue',
                  }}
                  border="2px solid gray"
                >
                  {option.name}
                </Radio>
                <Text ml={2}>({option.count} votes)</Text>
                <Progress
                  flex="1"
                  value={
                    (option.count /
                      options.reduce((acc, curr) => acc + curr.count, 0)) *
                    100
                  }
                  colorScheme="blue"
                  size="sm"
                  borderRadius="full"
                  ml={2}
                />
              </Flex>
            </Tooltip>
          ))}
        </Stack>
      </RadioGroup>
    </Box>
  );
};

export default PollVotingComponent;
