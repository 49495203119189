import { useEffect, useState } from 'react';
import { FaMoneyBillWave } from 'react-icons/fa';

const findFirstWorkingDayOfMonth = (date: any) => {
  const startOfMonthDate = new Date(date?.getFullYear(), date?.getMonth(), 1);
  let currentDay = startOfMonthDate?.getDay();
  let firstWorkingDay = new Date(date?.getFullYear(), date?.getMonth(), 1);

  while (currentDay === 0 || currentDay === 6) {
    firstWorkingDay?.setDate(firstWorkingDay?.getDate() + 1);

    if (firstWorkingDay?.getMonth() !== date?.getMonth()) {
      firstWorkingDay = new Date(date?.getFullYear(), date?.getMonth(), 1);
    }

    currentDay = firstWorkingDay?.getDay();
  }

  return firstWorkingDay;
};

const MoneyItem = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const firstWorkingDay = findFirstWorkingDayOfMonth(currentDate);

    if (
      currentDate.getDate() === firstWorkingDay?.getDate() &&
      currentDate.getMonth() === firstWorkingDay?.getMonth()
    ) {
      const delay = Math?.random() * 2000;

      const showMoney = () => {
        setIsVisible(true);

        setTimeout(() => {
          setIsVisible(false);
        }, 2000);
      };

      setTimeout(showMoney, delay);
    }
  }, []);

  return isVisible ? (
    <div
      style={{
        position: 'absolute',
        top: Math?.random() * window?.innerHeight,
        left: Math?.random() * window?.innerWidth,
        transition: 'opacity 2s ease-in-out',
        opacity: 1,
      }}
    >
      <FaMoneyBillWave color="green" style={{ fontSize: '2em' }} />
    </div>
  ) : null;
};

export const MoneyRainingApp = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: 1,
        pointerEvents: 'none',
      }}
    >
      {[...Array(30)]?.map((_, index) => (
        <MoneyItem key={index} />
      ))}
    </div>
  );
};
