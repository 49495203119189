import jwt from 'jwt-decode';

const checkTokenRole = () => {
  const token = localStorage.getItem('hrc_access_token');
  if (token) {
    try {
      const decodedToken: any = jwt(token);
      return decodedToken.role;
    } catch (error) {
      console.error(error);
    }
  }
  return false;
};

export { checkTokenRole };
