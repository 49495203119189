import { createSlice } from '@reduxjs/toolkit';
const userIdLS = localStorage.getItem('user_id');

export interface IDaysOffState {
  daysOffPageable: {
    page: number;
    size: number | string;
    sort: string[];
  };
  addEditDaysOff: {
    userId: string | null;
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
    reason: string;
    selectDaysOffType: string;
  };
  addEditDaysOffForm: {
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
    reason: string;
    selectedUser: string;
    selectDaysOffType: string;
  };
  selectedUserId: number | null;
  exportDaysOff: {
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
  };
}

export const initialState: IDaysOffState = {
  daysOffPageable: {
    page: 0,
    size: '',
    sort: ['', ''],
  },
  addEditDaysOff: {
    userId: userIdLS,
    startDate: undefined,
    endDate: undefined,
    reason: '',
    selectDaysOffType: '',
  },
  addEditDaysOffForm: {
    startDate: undefined,
    endDate: undefined,
    reason: '',
    selectedUser: '',
    selectDaysOffType: '',
  },
  selectedUserId: null,
  exportDaysOff: {
    startDate: undefined,
    endDate: undefined,
  },
};

export const daysOffSlice = createSlice({
  name: 'daysOff',
  initialState,
  reducers: {
    setAddEditDaysOff: (state: any, action: { payload: any; type: string }) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];
      return {
        ...state,
        addEditDaysOff: {
          ...state.addEditDaysOff,
          [key]: value,
        },
      };
    },
    setSelectedUserSlice: (
      state: any,
      action: { payload: any; type: string },
    ) => {
      return {
        ...state,
        addEditDaysOff: {
          ...state.addEditDaysOff,
          userId: action.payload,
        },
      };
    },
    setDeleteDaysOff: (state: any, action: { payload: any; type: string }) => {
      return { ...state, state: action.payload };
    },
    setExportDate: (state, action) => {
      const { name, value } = action.payload;
      return {
        ...state,
        exportDaysOff: {
          ...state.exportDaysOff,
          [name]: value,
        },
      };
    },
    clearAddEditDaysOffForm: (state) => {
      state.addEditDaysOff = {
        userId: userIdLS,
        startDate: undefined,
        endDate: undefined,
        reason: '',
        selectDaysOffType: '',
      };
    },
    setDaysOffForm: (state: any, action: any) => {
      const { name, value } = action.payload;
      state.addEditDaysOffForm = {
        ...state.addEditDaysOffForm,
        [name]: value,
      };
    },
    setUserId: (state: any, action: any) => {
      state.selectedUserId = action.payload;
    },
    clearSelectedUserId: (state: any) => {
      state.selectedUser = null;
    },
    setDaysOffPageable: (state, action: any) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.daysOffPageable = {
        ...state.daysOffPageable,
        [key]: value,
      };
    },
    clearDaysOffPageable: (state) => {
      state.daysOffPageable = {
        page: 0,
        size: '',
        sort: ['id', 'desc'],
      };
    },
    setEditDayOff: (state: any, action: any) => {
      state.addEditDaysOffForm = {
        ...action.payload,
      };
    },
    clearAddEditForm: (state: any) => {
      state.addEditDaysOffForm = {
        reason: '',
        startDate: undefined,
        endDate: undefined,
        selectedUser: '',
        selectDaysOffType: '',
      };
    },
    clearExportDates: (state) => {
      state.exportDaysOff = {
        startDate: undefined,
        endDate: undefined,
      };
    },
  },
});

export const {
  setAddEditDaysOff,
  setDeleteDaysOff,
  setSelectedUserSlice,
  setDaysOffPageable,
  clearDaysOffPageable,
  setExportDate,
  clearAddEditDaysOffForm,
  setDaysOffForm,
  setEditDayOff,
  setUserId,
  clearSelectedUserId,
  clearAddEditForm,
  clearExportDates,
} = daysOffSlice.actions;

export default daysOffSlice.reducer;
