import { Button, Link, Text, TextProps } from '@chakra-ui/react';
import * as React from 'react';

type Props = TextProps &
  React.HTMLProps<HTMLParagraphElement> & {
    content: string;
    maxLength?: number;
  };

const TextWithLinksAndLineBreaks = ({ content, maxLength, ...rest }: Props) => {
  const [showMore, setShowMore] = React.useState(false);

  const URL_REGEX = /\b((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gm;
  const parts = Boolean(maxLength)
    ? showMore
      ? content?.split(URL_REGEX)
      : content?.slice(0, maxLength).split(URL_REGEX)
    : content?.split(URL_REGEX);

  const toggleShowMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowMore(!showMore);
  };

  return (
    <Text whiteSpace="pre-line" textAlign="justify" {...rest}>
      {parts?.map((part, index) => {
        if (part.match(URL_REGEX)) {
          const cleanLink = part
            .replace(/(^\w+:|^)\/\//, '')
            .replace(/\/$/, '');
          return (
            <Link
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              color="blue.500"
              textDecoration="underline"
              _hover={{ textDecoration: 'none' }}
            >
              {cleanLink}
            </Link>
          );
        }
        return <React.Fragment key={index}>{part}</React.Fragment>;
      })}
      {maxLength && maxLength < content.length ? (
        <>
          {!showMore ? <span>..... </span> : ''}
          <Button
            bg="none"
            size="xs"
            px="1"
            py="0.5"
            onClick={toggleShowMore}
            _hover={{
              bg: 'none',
              cursor: 'pointer',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            {showMore ? 'Show less' : 'Show more'}
          </Button>
        </>
      ) : (
        ''
      )}
    </Text>
  );
};

export default TextWithLinksAndLineBreaks;
