import { createSlice } from '@reduxjs/toolkit';
import { Moment } from 'moment';

export interface IWorklogState {
  worklogForm: {
    task: string;
    description: string;
    dateStart: string;
    spentTime: string;
    userId: string | undefined;
    selectedProject: {
      id: undefined | number;
      value: string;
    };
    projectId: undefined | string;
    month: undefined | Date;
  };
  worklogPageable: {
    page: number;
    size: number;
    sort: string[];
  };
  worklogReportsPageable: {
    page: number;
    size: number;
    sort: string[];
  };
  worklogExport: {
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
    projectId: string;
  };
  selectedEmployee: {
    name: string;
    id: undefined | number;
  };
  reportsEmployee: number | undefined;
  worklogUploadForm: {
    description: string;
    file: File | undefined;
    projectId: number | undefined;
    month: Date | undefined;
  };
  isManualInput: boolean;
  uploadReportFilters: {
    'user-id'?: undefined | number;
    'project-id': undefined | number;
    period: string;
  };
  worklogStatus: string;
  worklogManualInputMonth: Date;
  worklogManualInputDate: Moment | string;
  taskId: number | undefined;
  hoursWorked: number;
  totalWorkingHours: number;
}

const initialState: IWorklogState = {
  worklogForm: {
    task: '',
    description: '',
    dateStart: '',
    spentTime: '',
    userId: '',
    selectedProject: {
      id: undefined,
      value: '',
    },
    projectId: undefined,
    month: undefined,
  },
  worklogPageable: {
    page: 0,
    size: 15,
    sort: ['name', 'desc'],
  },
  worklogReportsPageable: {
    page: 0,
    size: 1000,
    sort: ['firstName', 'asc'],
  },
  worklogExport: {
    startDate: undefined,
    endDate: undefined,
    projectId: '',
  },
  selectedEmployee: {
    name: 'all',
    id: undefined,
  },
  reportsEmployee: undefined,
  worklogUploadForm: {
    description: '',
    file: undefined,
    projectId: undefined,
    month: undefined,
  },
  isManualInput: true,
  uploadReportFilters: {
    'project-id': undefined,
    period: '',
  },
  worklogStatus: 'withWorklog',
  worklogManualInputMonth: new Date(),
  worklogManualInputDate: '',
  taskId: undefined,
  hoursWorked: 0,
  totalWorkingHours: 0,
};

export const worklogSlice = createSlice({
  name: 'worklog',
  initialState,
  reducers: {
    setWorklogForm: (state, action) => {
      const { name, value } = action.payload;
      state.worklogForm = {
        ...state.worklogForm,
        [name]: value,
      };
    },
    setSelectedProject: (state, action) => {
      const { value, findId } = action.payload;
      state.worklogForm = {
        ...state.worklogForm,
        selectedProject: {
          id: findId,
          value,
        },
      };
    },
    clearWorklogForm: (state) => {
      state.worklogForm = {
        task: '',
        description: '',
        dateStart: '',
        spentTime: '',
        userId: undefined,
        selectedProject: {
          id: undefined,
          value: '',
        },
        projectId: undefined,
        month: undefined,
      };
    },
    setEditWorklog: (state, action) => {
      const {
        dateStart,
        description,
        spentTime,
        task,
        selectedProject,
        userId,
        projectId,
        month,
      } = action.payload;
      state.worklogForm = {
        task,
        description,
        dateStart,
        spentTime,
        userId,
        selectedProject: {
          id: selectedProject.id,
          value: selectedProject.value,
        },
        projectId,
        month,
      };
    },
    setWorklogPageable: (state, action) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      state.worklogPageable = {
        ...state.worklogPageable,
        [key]: value,
      };
    },
    setWorklogReportsPageable: (state, action) => {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];

      state.worklogReportsPageable = {
        ...state.worklogReportsPageable,
        [key]: value,
      };
    },
    clearWorklogPageable: (state) => {
      state.worklogPageable = {
        page: 0,
        size: 15,
        sort: ['dateStart', 'desc'],
      };
    },
    setSelectedEmployee: (state, action) => {
      const { name, id } = action.payload;
      state.selectedEmployee = {
        name,
        id,
      };
    },
    setReportsEmployeeId: (state, action) => {
      state.reportsEmployee = action.payload;
    },
    clearSelectedEmployee: (state) => {
      state.selectedEmployee = {
        name: '',
        id: undefined,
      };
    },
    setWorklogExport: (state, action) => {
      const { name, value } = action.payload;
      state.worklogExport = {
        ...state.worklogExport,
        [name]: value,
      };
    },
    clearWorklogExportDates: (state) => {
      state.worklogExport = {
        startDate: undefined,
        endDate: undefined,
        projectId: '',
      };
    },
    setWorklogUploadForm: (state, action) => {
      const { name, value } = action.payload;
      state.worklogUploadForm = {
        ...state.worklogUploadForm,
        [name]: value,
      };
    },
    setUploadReportFilters: (state, action) => {
      state.uploadReportFilters = action.payload;
    },
    setToggle: (state) => {
      state.isManualInput = !state.isManualInput;
    },
    clearReportsFilters: (state) => {
      state.worklogForm = {
        ...state.worklogForm,
        userId: '',
        projectId: '',
        month: undefined,
      };
    },
    setWorklogManualInputMonth: (state, action) => {
      state.worklogManualInputMonth = action.payload;
    },
    setWorklogManualInputDate: (state, action) => {
      state.worklogManualInputDate = action.payload;
    },
    setTaskId: (state, action) => {
      state.taskId = action.payload;
    },
    setHoursWorked: (state, action) => {
      state.hoursWorked = action.payload;
    },
    setTotalWorkingHours: (state, action) => {
      state.totalWorkingHours = action.payload;
    },
    setWorklogStatus: (state, action) => {
      state.worklogStatus = action.payload;
    },
  },
});

export const {
  setWorklogForm,
  setSelectedProject,
  clearWorklogForm,
  setWorklogPageable,
  setWorklogReportsPageable,
  setEditWorklog,
  setSelectedEmployee,
  setReportsEmployeeId,
  clearWorklogPageable,
  setWorklogExport,
  clearWorklogExportDates,
  clearSelectedEmployee,
  setWorklogUploadForm,
  setToggle,
  setUploadReportFilters,
  clearReportsFilters,
  setWorklogManualInputMonth,
  setWorklogManualInputDate,
  setTaskId,
  setHoursWorked,
  setTotalWorkingHours,
  setWorklogStatus,
} = worklogSlice.actions;

export default worklogSlice.reducer;
