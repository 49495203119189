import { createSlice } from '@reduxjs/toolkit';
import { IProfileFormContent } from '../../../../types/profileTypes';

export interface IProfile {
  firstName: string;
  lastName: string;
  birthDate: Date | null | undefined;
  email: string;
  phoneNumber: string;
}

export interface IProfileImage {
  encodedContent?: string;
  contentType?: string;
  encodedContentCropped?: string;
  image?: any;
  id?: any;
  name: any;
  type?: any;
}

export interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeated: string;
}

export interface IProfileInitialState {
  profileInfo: IProfileFormContent;
  profileImage: IProfileImage;
  croppedProfileImage: IProfileImage;
  changePassword: IChangePassword;
  passwordChangeIsDisabled: boolean;
}

export const initialState: IProfileInitialState = {
  profileInfo: {
    about: '',
    address: '',
    birthDate: undefined,
    content: null,
    displayName: '',
    email: '',
    firstName: '',
    lastName: '',
    linkedin: '',
    phoneNumber: '',
    tshirtSize: '',
  },
  profileImage: {
    encodedContent: '',
    name: '',
    contentType: '',
    encodedContentCropped: '',
  },
  croppedProfileImage: {
    encodedContent: '',
    name: '',
    contentType: '',
    encodedContentCropped: '',
  },
  changePassword: {
    oldPassword: '',
    newPassword: '',
    newPasswordRepeated: '',
  },
  passwordChangeIsDisabled: true,
};

export type ChangePasswordPayloadName =
  | 'oldPassword'
  | 'newPassword'
  | 'newPasswordRepeated';

export const profileSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getProfileData: (state: any, action) => {
      state.type = action.payload;
    },
    updateValues: (state: any, action) => {
      const { inputName, inputValue } = action.payload;
      state.profileInfo = {
        ...state.profileInfo,
        [inputName]: inputValue,
      };
    },
    prefilledProfileData: (state: any, action: { payload: any }) => {
      state.profileInfo = {
        ...action.payload,
        birthDate:
          typeof action.payload.birthDate === 'string'
            ? new Date(action.payload.birthDate)
            : action.payload.birthDate,
      };
    },
    setEditProfile: (state: any, action: { payload: any; type: string }) => {
      const key = Object.entries(action.payload)[0][0];
      const value = Object.entries(action.payload)[0][1];
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          [key]: value,
        },
      };
    },
    setProfilePicture: (state: any, action) => {
      const { contentType, name, encodedContent } = action.payload;
      state.croppedProfileImage = { contentType, name, encodedContent };
    },
    setCropProfilePicture: (state: any, action) => {
      state.croppedProfileImage = {
        ...state.croppedProfileImage,
        encodedContentCropped: action.payload,
      };
    },
    removeProfilePicture: (state: any) => {
      state.croppedProfileImage = {
        contentType: '',
        name: '',
        encodedContent: '',
      };
    },
    setGlobalProfileImage: (state, action) => {
      state.profileImage.encodedContent = action.payload.encodedContent;
      state.profileImage.contentType = action.payload.type;
      state.profileImage.name = action.payload.name;
    },
    setChangePasswordFormItem: (
      state,
      action: {
        payload: {
          name: ChangePasswordPayloadName;
          value: string;
        };
      },
    ) => {
      const { name, value } = action.payload;
      state.changePassword = {
        ...state.changePassword,
        [name]: value,
      };
    },
    togglePasswordChange: (state) => {
      state.passwordChangeIsDisabled = !state.passwordChangeIsDisabled;
    },
    resetPasswordChange: (state) => {
      state.changePassword = {
        newPassword: '',
        newPasswordRepeated: '',
        oldPassword: '',
      };
    },
  },
});

export const {
  getProfileData,
  setProfilePicture,
  updateValues,
  setEditProfile,
  prefilledProfileData,
  setGlobalProfileImage,
  removeProfilePicture,
  setCropProfilePicture,
  setChangePasswordFormItem,
  togglePasswordChange,
  resetPasswordChange,
} = profileSlice.actions;
export default profileSlice.reducer;
