import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_ROOT } from '../../../axios/axiosRequest';

export const reviewsApiSlice = createApi({
  reducerPath: 'reviewsApi',
  baseQuery: fetchBaseQuery({ baseUrl: URL_ROOT }),
  tagTypes: ['getReviews'],
  endpoints: (builder) => ({
    getReviewsByUserId: builder.query({
      query: ({ params }) => ({
        url: `/api/reviews`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
        },
        params,
      }),
      providesTags: ['getReviews'],
    }),
    // updateRatePerHour: builder.mutation({
    //   query: ({ params }) => ({
    //     url: '/api/project/with-rate',
    //     method: 'PUT',
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem('hrc_access_token')}`,
    //     },
    //     params,
    //   }),
    //   invalidatesTags: ['ratePerHour'],
    // }),
  }),
});

export const { useGetReviewsByUserIdQuery } = reviewsApiSlice;
