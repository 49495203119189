import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRoutes } from 'react-router-dom';
import useAuth from './common/hooks/useAuth';
import { useUserId } from './common/hooks/useUserId';
import { useWhatRole } from './common/hooks/useWhatRole';
import { setUserId, setUserRole } from './common/lib/redux/slices/userSlice';
import { routes } from './common/routing/routes';
import { ProtectedPages } from './features/ProtectedPages';
import 'react-datepicker/dist/react-datepicker.css';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuth = useAuth();
  const role = useWhatRole();
  const userId = useUserId();
  dispatch(setUserRole(role));
  dispatch(setUserId(userId));

  let element = useRoutes(routes(isAuth, role));
  return location.pathname === '/auth' || !isAuth ? (
    <>{element}</>
  ) : (
    <ProtectedPages element={element} />
  );
};

export { App };
