import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import CommentItem, { ICommentData } from './CommentItem';
import { FormattedMessage, IntlShape } from 'react-intl';
import { ErrorBoundary } from '../../../../components/ErrorBoundary/ErrorBoundary';

interface CommentListProps {
  comments: ICommentData[];
  onEditComment: (index: number, editedText: string) => void;
  onDeleteComment: () => void;
  intl: IntlShape;
  openedFromDashboard?: boolean | undefined;
}

const CommentList: React.FC<CommentListProps> = ({
  comments,
  onEditComment,
  onDeleteComment,
  intl,
  openedFromDashboard,
}) => {
  return (
    <ErrorBoundary>
      {comments?.length > 0 ? (
        <Box w="full" flex="1" overflowY="auto" pr="4">
          {comments.map((comment, index) => (
            <CommentItem
              key={comment.id}
              comment={comment}
              onEditComment={(editedText) => onEditComment(index, editedText)}
              onDeleteComment={() => onDeleteComment()}
              intl={intl}
              openedFromDashboard={openedFromDashboard}
            />
          ))}
        </Box>
      ) : (
        <Flex h="full" justifyContent="center" alignItems="center">
          <Text color="gray.500" fontSize={20}>
            <FormattedMessage id={'COMMENTS.NO_COMMENTS_YET'} />
          </Text>
        </Flex>
      )}
    </ErrorBoundary>
  );
};

export default CommentList;
