import { StyleProps } from '@chakra-ui/react';

interface IImageGalleyStyles {
  imageGalleryWrapper: StyleProps;
  smallImageContainer: StyleProps;
  arrowButton: StyleProps;
  displayedImageContainer: StyleProps;
  displayedImage: StyleProps;
  leftArrowButton: StyleProps;
  rightArrowButton: StyleProps;
  smallImage: StyleProps;
}

export const ImageGalleryStyles: IImageGalleyStyles = {
  imageGalleryWrapper: {
    position: 'relative',
    boxSize: '100%',
  },
  displayedImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    boxSize: '85%',
  },
  displayedImage: {
    objectFit: 'contain',
    boxSize: '100%',
  },
  smallImageContainer: {
    mt: '5px',
    cursor: 'pointer',
    width: '85px',
    height: '55px',
    backgroundPosition: 'center',
    borderRadius: '12px',
  },
  arrowButton: {
    position: 'absolute',
    opacity: '0.7',
    top: '50%',
    transform: 'translate(0, -50%)',
    rounded: '100%',
  },
  leftArrowButton: {
    left: '135px',
  },
  rightArrowButton: {
    right: '135px',
  },
  smallImage: {
    rounded: '12px',
    objectFit: 'contain',
  },
};
