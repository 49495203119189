import React from 'react';

interface IconTypes {
  height?: number | string;
  width?: number | string;
  stroke?: string;
  fill?: string;
}

const PostsIcon: React.FC<IconTypes> = ({ height, width, stroke, fill }) => {
  return (
    <svg
      width={width ?? 20}
      height={height ?? 20}
      viewBox="0 0 20 20"
      fill={fill ?? 'none'}
      stroke={stroke ?? 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Monitor Screen */}
      <rect
        x="2"
        y="2"
        width="16"
        height="10"
        rx="1.5"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* Keyboard Base */}
      <rect
        x="5"
        y="12"
        width="10"
        height="4"
        rx="1"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* Keyboard Keys */}
      <rect x="5.5" y="13" width="9" height="2" rx="0.5" fill="#333" />
    </svg>
  );
};

export default PostsIcon;
