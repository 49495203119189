interface ErrorInfo {
  type: 'error' | 'info' | 'warning' | 'success' | 'loading';
  messages: string[];
}

export const generateErrorInfo = (addNewPostForm: any): ErrorInfo | null => {
  const requiredFields = [
    { key: 'title', labelId: 'TITLE' },
    { key: 'text', labelId: 'TEXT' },
    { key: 'selectType.value', labelId: 'SELECT_TYPE' },
  ];

  const missingFields = requiredFields
    .filter((field) => {
      if (field.key.includes('.')) {
        const keys = field.key.split('.');
        return !addNewPostForm[keys[0]] || !addNewPostForm[keys[0]][keys[1]];
      } else {
        return !addNewPostForm[field.key];
      }
    })
    .map((field) => `ERRORS.REQUIRE.${field.labelId}`);

  if (missingFields.length === requiredFields.length) {
    return {
      type: 'error',
      messages: ['ERRORS.REQUIRE.ALL'],
    };
  } else if (missingFields.length > 0) {
    return {
      type: 'error',
      messages: missingFields,
    };
  }

  if (addNewPostForm?.title?.length >= 60) {
    return {
      type: 'warning',
      messages: ['REASON.LENGTH'],
    };
  }

  if (
    (addNewPostForm?.selectType.value === 'EVENT' ||
      addNewPostForm?.selectType.value === 'POLL') &&
    !addNewPostForm?.startDate
  ) {
    return {
      type: 'error',
      messages: ['POSTS.ERROR_MISSING_START_DATE'],
    };
  }
  if (addNewPostForm?.selectType.value === 'POLL' && !addNewPostForm?.endDate) {
    return {
      type: 'error',
      messages: ['POSTS.ERROR_MISSING_END_DATE'],
    };
  }
  if (
    addNewPostForm?.selectType.value === 'POLL' &&
    (!addNewPostForm?.pollOptionOne.name || !addNewPostForm?.pollOptionTwo.name)
  ) {
    return {
      type: 'error',
      messages: ['POSTS.ERROR_MISSING_OPTIONS'],
    };
  }
  if (
    (addNewPostForm?.title && addNewPostForm?.title?.trim() === '') ||
    (addNewPostForm?.text && addNewPostForm?.text?.trim() === '')
  ) {
    return {
      type: 'error',
      messages: ['VALIDATION.TITLE_TEXT_CHARACTERS_ONLY'],
    };
  }

  const startDateTimestamp = new Date(addNewPostForm?.startDate).getTime();
  const endDateTimestamp = new Date(addNewPostForm?.endDate).getTime();

  if (endDateTimestamp && startDateTimestamp > endDateTimestamp) {
    return {
      type: 'error',
      messages: ['VALIDATION.DATES_4'],
    };
  }

  return null;
};
